/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateCWS: OnCreateCWSSubscription;
  onUpdateCWS: OnUpdateCWSSubscription;
  onDeleteCWS: OnDeleteCWSSubscription;
  onCreateClient: OnCreateClientSubscription;
  onUpdateClient: OnUpdateClientSubscription;
  onDeleteClient: OnDeleteClientSubscription;
  onCreateUserGroup: OnCreateUserGroupSubscription;
  onUpdateUserGroup: OnUpdateUserGroupSubscription;
  onDeleteUserGroup: OnDeleteUserGroupSubscription;
  onCreateGroupPermission: OnCreateGroupPermissionSubscription;
  onUpdateGroupPermission: OnUpdateGroupPermissionSubscription;
  onDeleteGroupPermission: OnDeleteGroupPermissionSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateCustomer: OnCreateCustomerSubscription;
  onUpdateCustomer: OnUpdateCustomerSubscription;
  onDeleteCustomer: OnDeleteCustomerSubscription;
  onCreateCustomerTerms: OnCreateCustomerTermsSubscription;
  onUpdateCustomerTerms: OnUpdateCustomerTermsSubscription;
  onDeleteCustomerTerms: OnDeleteCustomerTermsSubscription;
  onCreateCustomerGroup: OnCreateCustomerGroupSubscription;
  onUpdateCustomerGroup: OnUpdateCustomerGroupSubscription;
  onDeleteCustomerGroup: OnDeleteCustomerGroupSubscription;
  onCreateAddress: OnCreateAddressSubscription;
  onUpdateAddress: OnUpdateAddressSubscription;
  onDeleteAddress: OnDeleteAddressSubscription;
  onCreateCampaign: OnCreateCampaignSubscription;
  onUpdateCampaign: OnUpdateCampaignSubscription;
  onDeleteCampaign: OnDeleteCampaignSubscription;
  onCreateCampaignEmails: OnCreateCampaignEmailsSubscription;
  onUpdateCampaignEmails: OnUpdateCampaignEmailsSubscription;
  onDeleteCampaignEmails: OnDeleteCampaignEmailsSubscription;
  onCreateOrder: OnCreateOrderSubscription;
  onUpdateOrder: OnUpdateOrderSubscription;
  onDeleteOrder: OnDeleteOrderSubscription;
  onCreatePayment: OnCreatePaymentSubscription;
  onUpdatePayment: OnUpdatePaymentSubscription;
  onDeletePayment: OnDeletePaymentSubscription;
  onCreatePaymentType: OnCreatePaymentTypeSubscription;
  onUpdatePaymentType: OnUpdatePaymentTypeSubscription;
  onDeletePaymentType: OnDeletePaymentTypeSubscription;
  onCreateOrderItem: OnCreateOrderItemSubscription;
  onUpdateOrderItem: OnUpdateOrderItemSubscription;
  onDeleteOrderItem: OnDeleteOrderItemSubscription;
  onCreateProduct: OnCreateProductSubscription;
  onUpdateProduct: OnUpdateProductSubscription;
  onDeleteProduct: OnDeleteProductSubscription;
  onCreateProductGroup: OnCreateProductGroupSubscription;
  onUpdateProductGroup: OnUpdateProductGroupSubscription;
  onDeleteProductGroup: OnDeleteProductGroupSubscription;
  onCreateAttributes: OnCreateAttributesSubscription;
  onUpdateAttributes: OnUpdateAttributesSubscription;
  onDeleteAttributes: OnDeleteAttributesSubscription;
  onCreateInputAttribute: OnCreateInputAttributeSubscription;
  onUpdateInputAttribute: OnUpdateInputAttributeSubscription;
  onDeleteInputAttribute: OnDeleteInputAttributeSubscription;
  onCreatePriceAttribute: OnCreatePriceAttributeSubscription;
  onUpdatePriceAttribute: OnUpdatePriceAttributeSubscription;
  onDeletePriceAttribute: OnDeletePriceAttributeSubscription;
  onCreateTag: OnCreateTagSubscription;
  onUpdateTag: OnUpdateTagSubscription;
  onDeleteTag: OnDeleteTagSubscription;
  onCreateOffer: OnCreateOfferSubscription;
  onUpdateOffer: OnUpdateOfferSubscription;
  onDeleteOffer: OnDeleteOfferSubscription;
  onCreateReview: OnCreateReviewSubscription;
  onUpdateReview: OnUpdateReviewSubscription;
  onDeleteReview: OnDeleteReviewSubscription;
  onCreateDocument: OnCreateDocumentSubscription;
  onUpdateDocument: OnUpdateDocumentSubscription;
  onDeleteDocument: OnDeleteDocumentSubscription;
  onCreateSupplier: OnCreateSupplierSubscription;
  onUpdateSupplier: OnUpdateSupplierSubscription;
  onDeleteSupplier: OnDeleteSupplierSubscription;
  onCreateProductTags: OnCreateProductTagsSubscription;
  onUpdateProductTags: OnUpdateProductTagsSubscription;
  onDeleteProductTags: OnDeleteProductTagsSubscription;
  onCreateProductOffers: OnCreateProductOffersSubscription;
  onUpdateProductOffers: OnUpdateProductOffersSubscription;
  onDeleteProductOffers: OnDeleteProductOffersSubscription;
  onCreateWebsite: OnCreateWebsiteSubscription;
  onUpdateWebsite: OnUpdateWebsiteSubscription;
  onDeleteWebsite: OnDeleteWebsiteSubscription;
};

export type CreateCWSInput = {
  id?: string | null;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
};

export type ModelCWSConditionInput = {
  name?: ModelStringInput | null;
  defaultFrontEndConfig?: ModelStringInput | null;
  schemaFrontEndConfig?: ModelStringInput | null;
  defaultBlogConfig?: ModelStringInput | null;
  schemaBlogConfig?: ModelStringInput | null;
  defaultThemeConfig?: ModelStringInput | null;
  schemaThemeConfig?: ModelStringInput | null;
  defaultCustomerInputConfig?: ModelStringInput | null;
  schemaCustomerInputConfig?: ModelStringInput | null;
  defaultWorkFlow?: ModelStringInput | null;
  schemaWorkFlow?: ModelStringInput | null;
  defaultSiteData?: ModelStringInput | null;
  schemaSiteData?: ModelStringInput | null;
  defaultReportConfig?: ModelStringInput | null;
  schemaReportConfig?: ModelStringInput | null;
  and?: Array<ModelCWSConditionInput | null> | null;
  or?: Array<ModelCWSConditionInput | null> | null;
  not?: ModelCWSConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type CWS = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCWSInput = {
  id: string;
  name?: string | null;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
};

export type DeleteCWSInput = {
  id: string;
};

export type CreateClientInput = {
  id?: string | null;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
};

export enum ClientStatus {
  quoting = "quoting",
  active = "active",
  suspended = "suspended",
  closed = "closed"
}

export type ModelClientConditionInput = {
  name?: ModelStringInput | null;
  contact?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  clientStatus?: ModelClientStatusInput | null;
  and?: Array<ModelClientConditionInput | null> | null;
  or?: Array<ModelClientConditionInput | null> | null;
  not?: ModelClientConditionInput | null;
};

export type ModelClientStatusInput = {
  eq?: ClientStatus | null;
  ne?: ClientStatus | null;
};

export type Client = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: ModelWebsiteConnection | null;
  users?: ModelUserConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelWebsiteConnection = {
  __typename: "ModelWebsiteConnection";
  items: Array<Website | null>;
  nextToken?: string | null;
};

export type Website = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: Client | null;
  products?: ModelProductConnection | null;
  customers?: ModelCustomerConnection | null;
  emailCampaigns?: ModelCampaignConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum WebsiteStatus {
  design = "design",
  test = "test",
  deployed = "deployed",
  suspended = "suspended",
  closed = "closed"
}

export type ModelProductConnection = {
  __typename: "ModelProductConnection";
  items: Array<Product | null>;
  nextToken?: string | null;
};

export type Product = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: Website | null;
  offerID: string;
  offer?: Offer | null;
  supplierID: string;
  supplier?: Supplier | null;
  productGroups?: ModelProductGroupConnection | null;
  reviews?: ModelReviewConnection | null;
  attributes?: ModelAttributesConnection | null;
  documents?: ModelDocumentConnection | null;
  tags?: ModelProductTagsConnection | null;
  offers?: ModelProductOffersConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum TaxCode {
  standard = "standard",
  reduced = "reduced",
  zero = "zero",
  exempt = "exempt"
}

export type Offer = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: ModelProductOffersConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum OfferType {
  discount = "discount",
  amount = "amount"
}

export enum OfferStatus {
  open = "open",
  suspended = "suspended",
  closed = "closed"
}

export type ModelProductOffersConnection = {
  __typename: "ModelProductOffersConnection";
  items: Array<ProductOffers | null>;
  nextToken?: string | null;
};

export type ProductOffers = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: Product;
  offer: Offer;
  createdAt: string;
  updatedAt: string;
};

export type Supplier = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: ModelProductConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelProductGroupConnection = {
  __typename: "ModelProductGroupConnection";
  items: Array<ProductGroup | null>;
  nextToken?: string | null;
};

export type ProductGroup = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: Product | null;
  tagID: string;
  tag?: Tag | null;
  createdAt: string;
  updatedAt: string;
};

export type Tag = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: ModelProductTagsConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum Area {
  product = "product",
  bundle = "bundle",
  blog = "blog"
}

export type ModelProductTagsConnection = {
  __typename: "ModelProductTagsConnection";
  items: Array<ProductTags | null>;
  nextToken?: string | null;
};

export type ProductTags = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: Product;
  tag: Tag;
  createdAt: string;
  updatedAt: string;
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection";
  items: Array<Review | null>;
  nextToken?: string | null;
};

export type Review = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: Product | null;
  customerID: string;
  customer?: Customer | null;
  createdAt: string;
  updatedAt: string;
};

export type Customer = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: CustomerTerms | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: Website | null;
  addresses?: ModelAddressConnection | null;
  orders?: ModelOrderConnection | null;
  customerGroups?: ModelCustomerGroupConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum CustomerStatus {
  created = "created",
  invited = "invited",
  confirmed = "confirmed",
  suspended = "suspended",
  disabled = "disabled",
  stopped = "stopped"
}

export enum CustomerType {
  public = "public",
  private = "private",
  business = "business",
  retailer = "retailer",
  retailerTrack = "retailerTrack",
  retailerRail = "retailerRail"
}

export type CustomerTerms = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelAddressConnection = {
  __typename: "ModelAddressConnection";
  items: Array<Address | null>;
  nextToken?: string | null;
};

export type Address = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: Customer | null;
  createdAt: string;
  updatedAt: string;
};

export enum AddressType {
  invoice = "invoice",
  delivery = "delivery",
  work = "work",
  home = "home"
}

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection";
  items: Array<Order | null>;
  nextToken?: string | null;
};

export type Order = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: Customer | null;
  addressID?: string | null;
  address?: Address | null;
  offerID?: string | null;
  offer?: Offer | null;
  orderItems?: ModelOrderItemConnection | null;
  invoiceNumber?: string | null;
  payments?: ModelPaymentConnection | null;
  createdAt: string;
  updatedAt: string;
};

export enum OrderStatus {
  quote = "quote",
  cart = "cart",
  ordered = "ordered",
  cancelled = "cancelled",
  fault = "fault",
  paid = "paid",
  progress = "progress",
  ready = "ready",
  dispatched = "dispatched",
  delivered = "delivered",
  returned = "returned",
  refunded = "refunded"
}

export type ModelOrderItemConnection = {
  __typename: "ModelOrderItemConnection";
  items: Array<OrderItem | null>;
  nextToken?: string | null;
};

export type OrderItem = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: Order | null;
  productID: string;
  product?: Product | null;
  offerID: string;
  offer?: Offer | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection";
  items: Array<Payment | null>;
  nextToken?: string | null;
};

export type Payment = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: Order | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: PaymentType | null;
  createdAt: string;
  updatedAt: string;
};

export type PaymentType = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelCustomerGroupConnection = {
  __typename: "ModelCustomerGroupConnection";
  items: Array<CustomerGroup | null>;
  nextToken?: string | null;
};

export type CustomerGroup = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: Customer | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelAttributesConnection = {
  __typename: "ModelAttributesConnection";
  items: Array<Attributes | null>;
  nextToken?: string | null;
};

export type Attributes = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: Product | null;
  createdAt: string;
  updatedAt: string;
};

export enum AttributeType {
  Input = "Input",
  Price = "Price",
  Details = "Details",
  Technical = "Technical",
  InTheBox = "InTheBox",
  Delivery = "Delivery",
  Terms = "Terms",
  Conditions = "Conditions",
  Disclaimer = "Disclaimer"
}

export enum InputType {
  number = "number",
  string = "string",
  dropdown = "dropdown"
}

export enum ValidationType {
  number = "number",
  string = "string",
  email = "email",
  phone = "phone"
}

export enum PriceCalculationType {
  free = "free",
  oneOff = "oneOff",
  percentage = "percentage",
  perMetre = "perMetre",
  perMetreAB = "perMetreAB",
  perSqrMetre = "perSqrMetre"
}

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection";
  items: Array<Document | null>;
  nextToken?: string | null;
};

export type Document = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: Product | null;
  createdAt: string;
  updatedAt: string;
};

export enum DocumentType {
  image = "image",
  video = "video",
  pdf = "pdf",
  text = "text"
}

export enum MimeType {
  jpg = "jpg",
  png = "png",
  pdf = "pdf",
  doc = "doc",
  word = "word"
}

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection";
  items: Array<Customer | null>;
  nextToken?: string | null;
};

export type ModelCampaignConnection = {
  __typename: "ModelCampaignConnection";
  items: Array<Campaign | null>;
  nextToken?: string | null;
};

export type Campaign = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: Website | null;
  createdAt: string;
  updatedAt: string;
};

export enum EmailCampaignStatus {
  created = "created",
  scheduled = "scheduled",
  sent = "sent",
  cancelled = "cancelled"
}

export enum EmailCampaignType {
  newsletter = "newsletter",
  offer = "offer",
  promotion = "promotion",
  event = "event",
  other = "other"
}

export enum EmailCampaignSendType {
  now = "now",
  schedule = "schedule"
}

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type User = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: Client | null;
  createdAt: string;
  updatedAt: string;
};

export enum PermissionStatus {
  administrator = "administrator",
  manager = "manager",
  office = "office",
  readonly = "readonly",
  none = "none"
}

export enum UserStatus {
  created = "created",
  invited = "invited",
  confirmed = "confirmed",
  active = "active",
  suspended = "suspended",
  closed = "closed"
}

export type UpdateClientInput = {
  id: string;
  name?: string | null;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
};

export type DeleteClientInput = {
  id: string;
};

export type CreateWebsiteInput = {
  id?: string | null;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
};

export type ModelWebsiteConditionInput = {
  name?: ModelStringInput | null;
  url?: ModelStringInput | null;
  email?: ModelStringInput | null;
  urlRegister?: ModelStringInput | null;
  websiteStatus?: ModelWebsiteStatusInput | null;
  dateLive?: ModelStringInput | null;
  frontEndConfig?: ModelStringInput | null;
  blogConfig?: ModelStringInput | null;
  themeConfig?: ModelStringInput | null;
  customerInputConfig?: ModelStringInput | null;
  workFlow?: ModelStringInput | null;
  siteData?: ModelStringInput | null;
  reportConfig?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelWebsiteConditionInput | null> | null;
  or?: Array<ModelWebsiteConditionInput | null> | null;
  not?: ModelWebsiteConditionInput | null;
};

export type ModelWebsiteStatusInput = {
  eq?: WebsiteStatus | null;
  ne?: WebsiteStatus | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateWebsiteInput = {
  id: string;
  name?: string | null;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID?: string | null;
};

export type DeleteWebsiteInput = {
  id: string;
};

export type CreateUserGroupInput = {
  id?: string | null;
  groupName: string;
  description?: string | null;
};

export type ModelUserGroupConditionInput = {
  groupName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelUserGroupConditionInput | null> | null;
  or?: Array<ModelUserGroupConditionInput | null> | null;
  not?: ModelUserGroupConditionInput | null;
};

export type UserGroup = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<GroupPermission | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type GroupPermission = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserGroupInput = {
  id: string;
  groupName?: string | null;
  description?: string | null;
};

export type DeleteUserGroupInput = {
  id: string;
};

export type CreateGroupPermissionInput = {
  id?: string | null;
  name: string;
  description?: string | null;
};

export type ModelGroupPermissionConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelGroupPermissionConditionInput | null> | null;
  or?: Array<ModelGroupPermissionConditionInput | null> | null;
  not?: ModelGroupPermissionConditionInput | null;
};

export type UpdateGroupPermissionInput = {
  id: string;
  name?: string | null;
  description?: string | null;
};

export type DeleteGroupPermissionInput = {
  id: string;
};

export type CreateUserInput = {
  id?: string | null;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null;
  permissionStatus?: ModelPermissionStatusInput | null;
  title?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  userStatus?: ModelUserStatusInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelPermissionStatusInput = {
  eq?: PermissionStatus | null;
  ne?: PermissionStatus | null;
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null;
  ne?: UserStatus | null;
};

export type UpdateUserInput = {
  id: string;
  email?: string | null;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID?: string | null;
};

export type DeleteUserInput = {
  id: string;
};

export type CreateCustomerInput = {
  id?: string | null;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
};

export type ModelCustomerConditionInput = {
  email?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  phoneOther?: ModelStringInput | null;
  accountNumber?: ModelStringInput | null;
  customerStatus?: ModelCustomerStatusInput | null;
  customerType?: ModelCustomerTypeInput | null;
  madeToMeasureDiscount?: ModelFloatInput | null;
  standardItemsDiscount?: ModelFloatInput | null;
  deliveryDiscount?: ModelFloatInput | null;
  orderDiscount?: ModelFloatInput | null;
  customerTermsID?: ModelIDInput | null;
  emailSubscribed?: ModelBooleanInput | null;
  websiteID?: ModelIDInput | null;
  and?: Array<ModelCustomerConditionInput | null> | null;
  or?: Array<ModelCustomerConditionInput | null> | null;
  not?: ModelCustomerConditionInput | null;
};

export type ModelCustomerStatusInput = {
  eq?: CustomerStatus | null;
  ne?: CustomerStatus | null;
};

export type ModelCustomerTypeInput = {
  eq?: CustomerType | null;
  ne?: CustomerType | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCustomerInput = {
  id: string;
  email?: string | null;
  userName?: string | null;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  emailSubscribed?: boolean | null;
  websiteID?: string | null;
};

export type DeleteCustomerInput = {
  id: string;
};

export type CreateCustomerTermsInput = {
  id?: string | null;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
};

export type ModelCustomerTermsConditionInput = {
  terms?: ModelStringInput | null;
  days?: ModelIntInput | null;
  waitForPayment?: ModelBooleanInput | null;
  and?: Array<ModelCustomerTermsConditionInput | null> | null;
  or?: Array<ModelCustomerTermsConditionInput | null> | null;
  not?: ModelCustomerTermsConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCustomerTermsInput = {
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
};

export type DeleteCustomerTermsInput = {
  id: string;
};

export type CreateCustomerGroupInput = {
  id?: string | null;
  name: string;
  description?: string | null;
  customerID: string;
};

export type ModelCustomerGroupConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  customerID?: ModelIDInput | null;
  and?: Array<ModelCustomerGroupConditionInput | null> | null;
  or?: Array<ModelCustomerGroupConditionInput | null> | null;
  not?: ModelCustomerGroupConditionInput | null;
};

export type UpdateCustomerGroupInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  customerID?: string | null;
};

export type DeleteCustomerGroupInput = {
  id: string;
};

export type CreateAddressInput = {
  id?: string | null;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
};

export type ModelAddressConditionInput = {
  addressType?: ModelAddressTypeInput | null;
  addr1?: ModelStringInput | null;
  addr2?: ModelStringInput | null;
  addr3?: ModelStringInput | null;
  county?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  Country?: ModelStringInput | null;
  driverInstructions?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  group?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  customerID?: ModelIDInput | null;
  and?: Array<ModelAddressConditionInput | null> | null;
  or?: Array<ModelAddressConditionInput | null> | null;
  not?: ModelAddressConditionInput | null;
};

export type ModelAddressTypeInput = {
  eq?: AddressType | null;
  ne?: AddressType | null;
};

export type UpdateAddressInput = {
  id: string;
  addressType?: AddressType | null;
  addr1?: string | null;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID?: string | null;
};

export type DeleteAddressInput = {
  id: string;
};

export type CreateCampaignInput = {
  id?: string | null;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
};

export type ModelCampaignConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  emailCampaignStatus?: ModelEmailCampaignStatusInput | null;
  emailCampaignType?: ModelEmailCampaignTypeInput | null;
  emailCampaignSendType?: ModelEmailCampaignSendTypeInput | null;
  emailCampaignSendDate?: ModelStringInput | null;
  emailCampaignSendTime?: ModelStringInput | null;
  emailCampaignSendFrom?: ModelStringInput | null;
  emailCampaignTemplate?: ModelStringInput | null;
  emailCampaignSendAttachments?: ModelStringInput | null;
  emailCampaignSendNotes?: ModelStringInput | null;
  websiteID?: ModelIDInput | null;
  and?: Array<ModelCampaignConditionInput | null> | null;
  or?: Array<ModelCampaignConditionInput | null> | null;
  not?: ModelCampaignConditionInput | null;
};

export type ModelEmailCampaignStatusInput = {
  eq?: EmailCampaignStatus | null;
  ne?: EmailCampaignStatus | null;
};

export type ModelEmailCampaignTypeInput = {
  eq?: EmailCampaignType | null;
  ne?: EmailCampaignType | null;
};

export type ModelEmailCampaignSendTypeInput = {
  eq?: EmailCampaignSendType | null;
  ne?: EmailCampaignSendType | null;
};

export type UpdateCampaignInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID?: string | null;
};

export type DeleteCampaignInput = {
  id: string;
};

export type CreateCampaignEmailsInput = {
  id?: string | null;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
};

export enum EmailStatus {
  created = "created",
  scheduled = "scheduled",
  sent = "sent",
  cancelled = "cancelled"
}

export type ModelCampaignEmailsConditionInput = {
  campaignId?: ModelIDInput | null;
  customerID?: ModelIDInput | null;
  emailTo?: ModelStringInput | null;
  template?: ModelStringInput | null;
  templateData?: ModelStringInput | null;
  emailStatus?: ModelEmailStatusInput | null;
  emailSentDate?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelCampaignEmailsConditionInput | null> | null;
  or?: Array<ModelCampaignEmailsConditionInput | null> | null;
  not?: ModelCampaignEmailsConditionInput | null;
};

export type ModelEmailStatusInput = {
  eq?: EmailStatus | null;
  ne?: EmailStatus | null;
};

export type CampaignEmails = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCampaignEmailsInput = {
  id: string;
  campaignId?: string | null;
  customerID?: string | null;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
};

export type DeleteCampaignEmailsInput = {
  id: string;
};

export type CreateOrderInput = {
  id?: string | null;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  addressID?: string | null;
  offerID?: string | null;
  invoiceNumber?: string | null;
};

export type ModelOrderConditionInput = {
  orderNo?: ModelStringInput | null;
  customerReference?: ModelStringInput | null;
  itemsTotal?: ModelFloatInput | null;
  offerDiscount?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  discount?: ModelFloatInput | null;
  delivery?: ModelFloatInput | null;
  tax?: ModelFloatInput | null;
  orderTotal?: ModelFloatInput | null;
  orderNotes?: ModelStringInput | null;
  dateOrdered?: ModelStringInput | null;
  datePaid?: ModelStringInput | null;
  dateDelivered?: ModelStringInput | null;
  orderStatus?: ModelOrderStatusInput | null;
  group?: ModelStringInput | null;
  customerID?: ModelIDInput | null;
  addressID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  invoiceNumber?: ModelStringInput | null;
  and?: Array<ModelOrderConditionInput | null> | null;
  or?: Array<ModelOrderConditionInput | null> | null;
  not?: ModelOrderConditionInput | null;
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null;
  ne?: OrderStatus | null;
};

export type UpdateOrderInput = {
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID?: string | null;
  addressID?: string | null;
  offerID?: string | null;
  invoiceNumber?: string | null;
};

export type DeleteOrderInput = {
  id: string;
};

export type CreatePaymentInput = {
  id?: string | null;
  orderID: string;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
};

export type ModelPaymentConditionInput = {
  orderID?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  notes?: ModelStringInput | null;
  paymentTypeID?: ModelIDInput | null;
  and?: Array<ModelPaymentConditionInput | null> | null;
  or?: Array<ModelPaymentConditionInput | null> | null;
  not?: ModelPaymentConditionInput | null;
};

export type UpdatePaymentInput = {
  id: string;
  orderID?: string | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID?: string | null;
};

export type DeletePaymentInput = {
  id: string;
};

export type CreatePaymentTypeInput = {
  id?: string | null;
  name: string;
};

export type ModelPaymentTypeConditionInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelPaymentTypeConditionInput | null> | null;
  or?: Array<ModelPaymentTypeConditionInput | null> | null;
  not?: ModelPaymentTypeConditionInput | null;
};

export type UpdatePaymentTypeInput = {
  id: string;
  name?: string | null;
};

export type DeletePaymentTypeInput = {
  id: string;
};

export type CreateOrderItemInput = {
  id?: string | null;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  productID: string;
  offerID: string;
};

export type ModelOrderItemConditionInput = {
  name?: ModelStringInput | null;
  fullPrice?: ModelFloatInput | null;
  discount?: ModelFloatInput | null;
  tax?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  quantity?: ModelIntInput | null;
  customerInputs?: ModelStringInput | null;
  orderItemNotes?: ModelStringInput | null;
  group?: ModelStringInput | null;
  orderID?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  and?: Array<ModelOrderItemConditionInput | null> | null;
  or?: Array<ModelOrderItemConditionInput | null> | null;
  not?: ModelOrderItemConditionInput | null;
};

export type UpdateOrderItemInput = {
  id: string;
  name?: string | null;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID?: string | null;
  productID?: string | null;
  offerID?: string | null;
};

export type DeleteOrderItemInput = {
  id: string;
};

export type CreateProductInput = {
  id?: string | null;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  offerID: string;
  supplierID: string;
};

export type ModelProductConditionInput = {
  sku?: ModelStringInput | null;
  name?: ModelStringInput | null;
  title?: ModelStringInput | null;
  shortDescription?: ModelStringInput | null;
  description?: ModelStringInput | null;
  delivery?: ModelFloatInput | null;
  taxCode?: ModelTaxCodeInput | null;
  tax?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  size?: ModelStringInput | null;
  colour?: ModelStringInput | null;
  weight?: ModelStringInput | null;
  productServiceName?: ModelStringInput | null;
  productNotes?: ModelStringInput | null;
  websiteID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  supplierID?: ModelIDInput | null;
  and?: Array<ModelProductConditionInput | null> | null;
  or?: Array<ModelProductConditionInput | null> | null;
  not?: ModelProductConditionInput | null;
};

export type ModelTaxCodeInput = {
  eq?: TaxCode | null;
  ne?: TaxCode | null;
};

export type UpdateProductInput = {
  id: string;
  sku?: string | null;
  name?: string | null;
  title?: string | null;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID?: string | null;
  offerID?: string | null;
  supplierID?: string | null;
};

export type DeleteProductInput = {
  id: string;
};

export type CreateProductGroupInput = {
  id?: string | null;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  tagID: string;
};

export type ModelProductGroupConditionInput = {
  products?: ModelIDInput | null;
  tags?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  tagID?: ModelIDInput | null;
  and?: Array<ModelProductGroupConditionInput | null> | null;
  or?: Array<ModelProductGroupConditionInput | null> | null;
  not?: ModelProductGroupConditionInput | null;
};

export type UpdateProductGroupInput = {
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID?: string | null;
  tagID?: string | null;
};

export type DeleteProductGroupInput = {
  id: string;
};

export type CreateAttributesInput = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
};

export type ModelAttributesConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  type?: ModelAttributeTypeInput | null;
  inputType?: ModelInputTypeInput | null;
  validationTypes?: ModelValidationTypeListInput | null;
  priceType?: ModelPriceCalculationTypeInput | null;
  associatedAttribute?: ModelIDInput | null;
  secondaryAssociatedAttribute?: ModelIDInput | null;
  basePrice?: ModelIntInput | null;
  notes?: ModelStringInput | null;
  productID?: ModelIDInput | null;
  and?: Array<ModelAttributesConditionInput | null> | null;
  or?: Array<ModelAttributesConditionInput | null> | null;
  not?: ModelAttributesConditionInput | null;
};

export type ModelAttributeTypeInput = {
  eq?: AttributeType | null;
  ne?: AttributeType | null;
};

export type ModelInputTypeInput = {
  eq?: InputType | null;
  ne?: InputType | null;
};

export type ModelValidationTypeListInput = {
  eq?: Array<ValidationType | null> | null;
  ne?: Array<ValidationType | null> | null;
  contains?: ValidationType | null;
  notContains?: ValidationType | null;
};

export type ModelPriceCalculationTypeInput = {
  eq?: PriceCalculationType | null;
  ne?: PriceCalculationType | null;
};

export type UpdateAttributesInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID?: string | null;
};

export type DeleteAttributesInput = {
  id: string;
};

export type CreateInputAttributeInput = {
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id?: string | null;
};

export type ModelInputAttributeConditionInput = {
  inputType?: ModelInputTypeInput | null;
  validationTypes?: ModelValidationTypeListInput | null;
  and?: Array<ModelInputAttributeConditionInput | null> | null;
  or?: Array<ModelInputAttributeConditionInput | null> | null;
  not?: ModelInputAttributeConditionInput | null;
};

export type InputAttribute = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateInputAttributeInput = {
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
};

export type DeleteInputAttributeInput = {
  id: string;
};

export type CreatePriceAttributeInput = {
  id?: string | null;
};

export type ModelPriceAttributeConditionInput = {
  and?: Array<ModelPriceAttributeConditionInput | null> | null;
  or?: Array<ModelPriceAttributeConditionInput | null> | null;
  not?: ModelPriceAttributeConditionInput | null;
};

export type PriceAttribute = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePriceAttributeInput = {
  id: string;
};

export type DeletePriceAttributeInput = {
  id: string;
};

export type CreateTagInput = {
  id?: string | null;
  name: string;
  area: Area;
};

export type ModelTagConditionInput = {
  name?: ModelStringInput | null;
  area?: ModelAreaInput | null;
  and?: Array<ModelTagConditionInput | null> | null;
  or?: Array<ModelTagConditionInput | null> | null;
  not?: ModelTagConditionInput | null;
};

export type ModelAreaInput = {
  eq?: Area | null;
  ne?: Area | null;
};

export type UpdateTagInput = {
  id: string;
  name?: string | null;
  area?: Area | null;
};

export type DeleteTagInput = {
  id: string;
};

export type CreateOfferInput = {
  id?: string | null;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
};

export type ModelOfferConditionInput = {
  websiteID?: ModelIDInput | null;
  dateValidFrom?: ModelStringInput | null;
  dateValidTo?: ModelStringInput | null;
  offerType?: ModelOfferTypeInput | null;
  offerStatus?: ModelOfferStatusInput | null;
  discountPercentage?: ModelFloatInput | null;
  discountAmount?: ModelFloatInput | null;
  and?: Array<ModelOfferConditionInput | null> | null;
  or?: Array<ModelOfferConditionInput | null> | null;
  not?: ModelOfferConditionInput | null;
};

export type ModelOfferTypeInput = {
  eq?: OfferType | null;
  ne?: OfferType | null;
};

export type ModelOfferStatusInput = {
  eq?: OfferStatus | null;
  ne?: OfferStatus | null;
};

export type UpdateOfferInput = {
  id: string;
  websiteID?: string | null;
  dateValidFrom?: string | null;
  dateValidTo?: string | null;
  offerType?: OfferType | null;
  offerStatus?: OfferStatus | null;
  discountPercentage?: number | null;
  discountAmount?: number | null;
};

export type DeleteOfferInput = {
  id: string;
};

export type CreateReviewInput = {
  id?: string | null;
  rating: number;
  reviewComment: string;
  productID: string;
  customerID: string;
};

export type ModelReviewConditionInput = {
  rating?: ModelIntInput | null;
  reviewComment?: ModelStringInput | null;
  productID?: ModelIDInput | null;
  customerID?: ModelIDInput | null;
  and?: Array<ModelReviewConditionInput | null> | null;
  or?: Array<ModelReviewConditionInput | null> | null;
  not?: ModelReviewConditionInput | null;
};

export type UpdateReviewInput = {
  id: string;
  rating?: number | null;
  reviewComment?: string | null;
  productID?: string | null;
  customerID?: string | null;
};

export type DeleteReviewInput = {
  id: string;
};

export type CreateDocumentInput = {
  id?: string | null;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
};

export type ModelDocumentConditionInput = {
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  documentType?: ModelDocumentTypeInput | null;
  mimeType?: ModelMimeTypeInput | null;
  location?: ModelStringInput | null;
  productID?: ModelIDInput | null;
  and?: Array<ModelDocumentConditionInput | null> | null;
  or?: Array<ModelDocumentConditionInput | null> | null;
  not?: ModelDocumentConditionInput | null;
};

export type ModelDocumentTypeInput = {
  eq?: DocumentType | null;
  ne?: DocumentType | null;
};

export type ModelMimeTypeInput = {
  eq?: MimeType | null;
  ne?: MimeType | null;
};

export type UpdateDocumentInput = {
  id: string;
  title?: string | null;
  description?: string | null;
  documentType?: DocumentType | null;
  mimeType?: MimeType | null;
  location?: string | null;
  productID?: string | null;
};

export type DeleteDocumentInput = {
  id: string;
};

export type CreateSupplierInput = {
  id?: string | null;
  name: string;
};

export type ModelSupplierConditionInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelSupplierConditionInput | null> | null;
  or?: Array<ModelSupplierConditionInput | null> | null;
  not?: ModelSupplierConditionInput | null;
};

export type UpdateSupplierInput = {
  id: string;
  name?: string | null;
};

export type DeleteSupplierInput = {
  id: string;
};

export type CreateProductTagsInput = {
  id?: string | null;
  productID: string;
  tagID: string;
};

export type ModelProductTagsConditionInput = {
  productID?: ModelIDInput | null;
  tagID?: ModelIDInput | null;
  and?: Array<ModelProductTagsConditionInput | null> | null;
  or?: Array<ModelProductTagsConditionInput | null> | null;
  not?: ModelProductTagsConditionInput | null;
};

export type UpdateProductTagsInput = {
  id: string;
  productID?: string | null;
  tagID?: string | null;
};

export type DeleteProductTagsInput = {
  id: string;
};

export type CreateProductOffersInput = {
  id?: string | null;
  productID: string;
  offerID: string;
};

export type ModelProductOffersConditionInput = {
  productID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  and?: Array<ModelProductOffersConditionInput | null> | null;
  or?: Array<ModelProductOffersConditionInput | null> | null;
  not?: ModelProductOffersConditionInput | null;
};

export type UpdateProductOffersInput = {
  id: string;
  productID?: string | null;
  offerID?: string | null;
};

export type DeleteProductOffersInput = {
  id: string;
};

export type ModelCWSFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  defaultFrontEndConfig?: ModelStringInput | null;
  schemaFrontEndConfig?: ModelStringInput | null;
  defaultBlogConfig?: ModelStringInput | null;
  schemaBlogConfig?: ModelStringInput | null;
  defaultThemeConfig?: ModelStringInput | null;
  schemaThemeConfig?: ModelStringInput | null;
  defaultCustomerInputConfig?: ModelStringInput | null;
  schemaCustomerInputConfig?: ModelStringInput | null;
  defaultWorkFlow?: ModelStringInput | null;
  schemaWorkFlow?: ModelStringInput | null;
  defaultSiteData?: ModelStringInput | null;
  schemaSiteData?: ModelStringInput | null;
  defaultReportConfig?: ModelStringInput | null;
  schemaReportConfig?: ModelStringInput | null;
  and?: Array<ModelCWSFilterInput | null> | null;
  or?: Array<ModelCWSFilterInput | null> | null;
  not?: ModelCWSFilterInput | null;
};

export type ModelCWSConnection = {
  __typename: "ModelCWSConnection";
  items: Array<CWS | null>;
  nextToken?: string | null;
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  contact?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  clientStatus?: ModelClientStatusInput | null;
  and?: Array<ModelClientFilterInput | null> | null;
  or?: Array<ModelClientFilterInput | null> | null;
  not?: ModelClientFilterInput | null;
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection";
  items: Array<Client | null>;
  nextToken?: string | null;
};

export type ModelUserGroupFilterInput = {
  id?: ModelIDInput | null;
  groupName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelUserGroupFilterInput | null> | null;
  or?: Array<ModelUserGroupFilterInput | null> | null;
  not?: ModelUserGroupFilterInput | null;
};

export type ModelUserGroupConnection = {
  __typename: "ModelUserGroupConnection";
  items: Array<UserGroup | null>;
  nextToken?: string | null;
};

export type ModelGroupPermissionFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelGroupPermissionFilterInput | null> | null;
  or?: Array<ModelGroupPermissionFilterInput | null> | null;
  not?: ModelGroupPermissionFilterInput | null;
};

export type ModelGroupPermissionConnection = {
  __typename: "ModelGroupPermissionConnection";
  items: Array<GroupPermission | null>;
  nextToken?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  permissionStatus?: ModelPermissionStatusInput | null;
  title?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  userStatus?: ModelUserStatusInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  phoneOther?: ModelStringInput | null;
  accountNumber?: ModelStringInput | null;
  customerStatus?: ModelCustomerStatusInput | null;
  customerType?: ModelCustomerTypeInput | null;
  madeToMeasureDiscount?: ModelFloatInput | null;
  standardItemsDiscount?: ModelFloatInput | null;
  deliveryDiscount?: ModelFloatInput | null;
  orderDiscount?: ModelFloatInput | null;
  customerTermsID?: ModelIDInput | null;
  emailSubscribed?: ModelBooleanInput | null;
  websiteID?: ModelIDInput | null;
  and?: Array<ModelCustomerFilterInput | null> | null;
  or?: Array<ModelCustomerFilterInput | null> | null;
  not?: ModelCustomerFilterInput | null;
};

export type ModelCustomerTermsFilterInput = {
  id?: ModelIDInput | null;
  terms?: ModelStringInput | null;
  days?: ModelIntInput | null;
  waitForPayment?: ModelBooleanInput | null;
  and?: Array<ModelCustomerTermsFilterInput | null> | null;
  or?: Array<ModelCustomerTermsFilterInput | null> | null;
  not?: ModelCustomerTermsFilterInput | null;
};

export type ModelCustomerTermsConnection = {
  __typename: "ModelCustomerTermsConnection";
  items: Array<CustomerTerms | null>;
  nextToken?: string | null;
};

export type ModelCustomerGroupFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  customerID?: ModelIDInput | null;
  and?: Array<ModelCustomerGroupFilterInput | null> | null;
  or?: Array<ModelCustomerGroupFilterInput | null> | null;
  not?: ModelCustomerGroupFilterInput | null;
};

export type ModelAddressFilterInput = {
  id?: ModelIDInput | null;
  addressType?: ModelAddressTypeInput | null;
  addr1?: ModelStringInput | null;
  addr2?: ModelStringInput | null;
  addr3?: ModelStringInput | null;
  county?: ModelStringInput | null;
  postCode?: ModelStringInput | null;
  Country?: ModelStringInput | null;
  driverInstructions?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  group?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  customerID?: ModelIDInput | null;
  and?: Array<ModelAddressFilterInput | null> | null;
  or?: Array<ModelAddressFilterInput | null> | null;
  not?: ModelAddressFilterInput | null;
};

export type ModelCampaignFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  emailCampaignStatus?: ModelEmailCampaignStatusInput | null;
  emailCampaignType?: ModelEmailCampaignTypeInput | null;
  emailCampaignSendType?: ModelEmailCampaignSendTypeInput | null;
  emailCampaignSendDate?: ModelStringInput | null;
  emailCampaignSendTime?: ModelStringInput | null;
  emailCampaignSendFrom?: ModelStringInput | null;
  emailCampaignTemplate?: ModelStringInput | null;
  emailCampaignSendAttachments?: ModelStringInput | null;
  emailCampaignSendNotes?: ModelStringInput | null;
  websiteID?: ModelIDInput | null;
  and?: Array<ModelCampaignFilterInput | null> | null;
  or?: Array<ModelCampaignFilterInput | null> | null;
  not?: ModelCampaignFilterInput | null;
};

export type ModelCampaignEmailsFilterInput = {
  id?: ModelIDInput | null;
  campaignId?: ModelIDInput | null;
  customerID?: ModelIDInput | null;
  emailTo?: ModelStringInput | null;
  template?: ModelStringInput | null;
  templateData?: ModelStringInput | null;
  emailStatus?: ModelEmailStatusInput | null;
  emailSentDate?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelCampaignEmailsFilterInput | null> | null;
  or?: Array<ModelCampaignEmailsFilterInput | null> | null;
  not?: ModelCampaignEmailsFilterInput | null;
};

export type ModelCampaignEmailsConnection = {
  __typename: "ModelCampaignEmailsConnection";
  items: Array<CampaignEmails | null>;
  nextToken?: string | null;
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null;
  orderNo?: ModelStringInput | null;
  customerReference?: ModelStringInput | null;
  itemsTotal?: ModelFloatInput | null;
  offerDiscount?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  discount?: ModelFloatInput | null;
  delivery?: ModelFloatInput | null;
  tax?: ModelFloatInput | null;
  orderTotal?: ModelFloatInput | null;
  orderNotes?: ModelStringInput | null;
  dateOrdered?: ModelStringInput | null;
  datePaid?: ModelStringInput | null;
  dateDelivered?: ModelStringInput | null;
  orderStatus?: ModelOrderStatusInput | null;
  group?: ModelStringInput | null;
  customerID?: ModelIDInput | null;
  addressID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  invoiceNumber?: ModelStringInput | null;
  and?: Array<ModelOrderFilterInput | null> | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  not?: ModelOrderFilterInput | null;
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null;
  orderID?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  notes?: ModelStringInput | null;
  paymentTypeID?: ModelIDInput | null;
  and?: Array<ModelPaymentFilterInput | null> | null;
  or?: Array<ModelPaymentFilterInput | null> | null;
  not?: ModelPaymentFilterInput | null;
};

export type ModelPaymentTypeFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelPaymentTypeFilterInput | null> | null;
  or?: Array<ModelPaymentTypeFilterInput | null> | null;
  not?: ModelPaymentTypeFilterInput | null;
};

export type ModelPaymentTypeConnection = {
  __typename: "ModelPaymentTypeConnection";
  items: Array<PaymentType | null>;
  nextToken?: string | null;
};

export type ModelOrderItemFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  fullPrice?: ModelFloatInput | null;
  discount?: ModelFloatInput | null;
  tax?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  quantity?: ModelIntInput | null;
  customerInputs?: ModelStringInput | null;
  orderItemNotes?: ModelStringInput | null;
  group?: ModelStringInput | null;
  orderID?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  and?: Array<ModelOrderItemFilterInput | null> | null;
  or?: Array<ModelOrderItemFilterInput | null> | null;
  not?: ModelOrderItemFilterInput | null;
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null;
  sku?: ModelStringInput | null;
  name?: ModelStringInput | null;
  title?: ModelStringInput | null;
  shortDescription?: ModelStringInput | null;
  description?: ModelStringInput | null;
  delivery?: ModelFloatInput | null;
  taxCode?: ModelTaxCodeInput | null;
  tax?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  size?: ModelStringInput | null;
  colour?: ModelStringInput | null;
  weight?: ModelStringInput | null;
  productServiceName?: ModelStringInput | null;
  productNotes?: ModelStringInput | null;
  websiteID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  supplierID?: ModelIDInput | null;
  and?: Array<ModelProductFilterInput | null> | null;
  or?: Array<ModelProductFilterInput | null> | null;
  not?: ModelProductFilterInput | null;
};

export type ModelProductGroupFilterInput = {
  id?: ModelIDInput | null;
  products?: ModelIDInput | null;
  tags?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  tagID?: ModelIDInput | null;
  and?: Array<ModelProductGroupFilterInput | null> | null;
  or?: Array<ModelProductGroupFilterInput | null> | null;
  not?: ModelProductGroupFilterInput | null;
};

export type ModelAttributesFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  type?: ModelAttributeTypeInput | null;
  inputType?: ModelInputTypeInput | null;
  validationTypes?: ModelValidationTypeListInput | null;
  priceType?: ModelPriceCalculationTypeInput | null;
  associatedAttribute?: ModelIDInput | null;
  secondaryAssociatedAttribute?: ModelIDInput | null;
  basePrice?: ModelIntInput | null;
  notes?: ModelStringInput | null;
  productID?: ModelIDInput | null;
  and?: Array<ModelAttributesFilterInput | null> | null;
  or?: Array<ModelAttributesFilterInput | null> | null;
  not?: ModelAttributesFilterInput | null;
};

export type ModelInputAttributeFilterInput = {
  inputType?: ModelInputTypeInput | null;
  validationTypes?: ModelValidationTypeListInput | null;
  and?: Array<ModelInputAttributeFilterInput | null> | null;
  or?: Array<ModelInputAttributeFilterInput | null> | null;
  not?: ModelInputAttributeFilterInput | null;
};

export type ModelInputAttributeConnection = {
  __typename: "ModelInputAttributeConnection";
  items: Array<InputAttribute | null>;
  nextToken?: string | null;
};

export type ModelPriceAttributeFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelPriceAttributeFilterInput | null> | null;
  or?: Array<ModelPriceAttributeFilterInput | null> | null;
  not?: ModelPriceAttributeFilterInput | null;
};

export type ModelPriceAttributeConnection = {
  __typename: "ModelPriceAttributeConnection";
  items: Array<PriceAttribute | null>;
  nextToken?: string | null;
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  area?: ModelAreaInput | null;
  and?: Array<ModelTagFilterInput | null> | null;
  or?: Array<ModelTagFilterInput | null> | null;
  not?: ModelTagFilterInput | null;
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection";
  items: Array<Tag | null>;
  nextToken?: string | null;
};

export type ModelOfferFilterInput = {
  id?: ModelIDInput | null;
  websiteID?: ModelIDInput | null;
  dateValidFrom?: ModelStringInput | null;
  dateValidTo?: ModelStringInput | null;
  offerType?: ModelOfferTypeInput | null;
  offerStatus?: ModelOfferStatusInput | null;
  discountPercentage?: ModelFloatInput | null;
  discountAmount?: ModelFloatInput | null;
  and?: Array<ModelOfferFilterInput | null> | null;
  or?: Array<ModelOfferFilterInput | null> | null;
  not?: ModelOfferFilterInput | null;
};

export type ModelOfferConnection = {
  __typename: "ModelOfferConnection";
  items: Array<Offer | null>;
  nextToken?: string | null;
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null;
  rating?: ModelIntInput | null;
  reviewComment?: ModelStringInput | null;
  productID?: ModelIDInput | null;
  customerID?: ModelIDInput | null;
  and?: Array<ModelReviewFilterInput | null> | null;
  or?: Array<ModelReviewFilterInput | null> | null;
  not?: ModelReviewFilterInput | null;
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  documentType?: ModelDocumentTypeInput | null;
  mimeType?: ModelMimeTypeInput | null;
  location?: ModelStringInput | null;
  productID?: ModelIDInput | null;
  and?: Array<ModelDocumentFilterInput | null> | null;
  or?: Array<ModelDocumentFilterInput | null> | null;
  not?: ModelDocumentFilterInput | null;
};

export type ModelSupplierFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelSupplierFilterInput | null> | null;
  or?: Array<ModelSupplierFilterInput | null> | null;
  not?: ModelSupplierFilterInput | null;
};

export type ModelSupplierConnection = {
  __typename: "ModelSupplierConnection";
  items: Array<Supplier | null>;
  nextToken?: string | null;
};

export type ModelProductTagsFilterInput = {
  id?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  tagID?: ModelIDInput | null;
  and?: Array<ModelProductTagsFilterInput | null> | null;
  or?: Array<ModelProductTagsFilterInput | null> | null;
  not?: ModelProductTagsFilterInput | null;
};

export type ModelProductOffersFilterInput = {
  id?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  offerID?: ModelIDInput | null;
  and?: Array<ModelProductOffersFilterInput | null> | null;
  or?: Array<ModelProductOffersFilterInput | null> | null;
  not?: ModelProductOffersFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelOrderOrdersByCustomerByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null;
  le?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null;
  lt?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null;
  ge?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null;
  gt?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null;
  between?: Array<ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null> | null;
  beginsWith?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput | null;
};

export type ModelOrderOrdersByCustomerByStatusByDateCompositeKeyInput = {
  orderStatus?: OrderStatus | null;
  dateOrdered?: string | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyConditionInput = {
  eq?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null;
  le?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null;
  lt?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null;
  ge?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null;
  gt?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null;
  between?: Array<ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null> | null;
  beginsWith?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput | null;
};

export type ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyInput = {
  offerStatus?: OfferStatus | null;
  offerType?: OfferType | null;
};

export type ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyConditionInput = {
  eq?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null;
  le?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null;
  lt?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null;
  ge?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null;
  gt?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null;
  between?: Array<ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null> | null;
  beginsWith?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput | null;
};

export type ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyInput = {
  offerStatus?: OfferStatus | null;
  dateValidFrom?: string | null;
  dateValidTo?: string | null;
};

export type ModelWebsiteFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  url?: ModelStringInput | null;
  email?: ModelStringInput | null;
  urlRegister?: ModelStringInput | null;
  websiteStatus?: ModelWebsiteStatusInput | null;
  dateLive?: ModelStringInput | null;
  frontEndConfig?: ModelStringInput | null;
  blogConfig?: ModelStringInput | null;
  themeConfig?: ModelStringInput | null;
  customerInputConfig?: ModelStringInput | null;
  workFlow?: ModelStringInput | null;
  siteData?: ModelStringInput | null;
  reportConfig?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelWebsiteFilterInput | null> | null;
  or?: Array<ModelWebsiteFilterInput | null> | null;
  not?: ModelWebsiteFilterInput | null;
};

export type ModelSubscriptionCWSFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  defaultFrontEndConfig?: ModelSubscriptionStringInput | null;
  schemaFrontEndConfig?: ModelSubscriptionStringInput | null;
  defaultBlogConfig?: ModelSubscriptionStringInput | null;
  schemaBlogConfig?: ModelSubscriptionStringInput | null;
  defaultThemeConfig?: ModelSubscriptionStringInput | null;
  schemaThemeConfig?: ModelSubscriptionStringInput | null;
  defaultCustomerInputConfig?: ModelSubscriptionStringInput | null;
  schemaCustomerInputConfig?: ModelSubscriptionStringInput | null;
  defaultWorkFlow?: ModelSubscriptionStringInput | null;
  schemaWorkFlow?: ModelSubscriptionStringInput | null;
  defaultSiteData?: ModelSubscriptionStringInput | null;
  schemaSiteData?: ModelSubscriptionStringInput | null;
  defaultReportConfig?: ModelSubscriptionStringInput | null;
  schemaReportConfig?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCWSFilterInput | null> | null;
  or?: Array<ModelSubscriptionCWSFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  contact?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  clientStatus?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionClientFilterInput | null> | null;
  or?: Array<ModelSubscriptionClientFilterInput | null> | null;
};

export type ModelSubscriptionUserGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  groupName?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserGroupFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserGroupFilterInput | null> | null;
};

export type ModelSubscriptionGroupPermissionFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionGroupPermissionFilterInput | null> | null;
  or?: Array<ModelSubscriptionGroupPermissionFilterInput | null> | null;
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  email?: ModelSubscriptionStringInput | null;
  permissionStatus?: ModelSubscriptionStringInput | null;
  title?: ModelSubscriptionStringInput | null;
  userName?: ModelSubscriptionStringInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  userStatus?: ModelSubscriptionStringInput | null;
  clientID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionUserFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserFilterInput | null> | null;
};

export type ModelSubscriptionCustomerFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  email?: ModelSubscriptionStringInput | null;
  userName?: ModelSubscriptionStringInput | null;
  companyName?: ModelSubscriptionStringInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  phoneOther?: ModelSubscriptionStringInput | null;
  accountNumber?: ModelSubscriptionStringInput | null;
  customerStatus?: ModelSubscriptionStringInput | null;
  customerType?: ModelSubscriptionStringInput | null;
  madeToMeasureDiscount?: ModelSubscriptionFloatInput | null;
  standardItemsDiscount?: ModelSubscriptionFloatInput | null;
  deliveryDiscount?: ModelSubscriptionFloatInput | null;
  orderDiscount?: ModelSubscriptionFloatInput | null;
  customerTermsID?: ModelSubscriptionIDInput | null;
  emailSubscribed?: ModelSubscriptionBooleanInput | null;
  websiteID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionCustomerFilterInput | null> | null;
  or?: Array<ModelSubscriptionCustomerFilterInput | null> | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionCustomerTermsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  terms?: ModelSubscriptionStringInput | null;
  days?: ModelSubscriptionIntInput | null;
  waitForPayment?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionCustomerTermsFilterInput | null> | null;
  or?: Array<ModelSubscriptionCustomerTermsFilterInput | null> | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionCustomerGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  customerID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionCustomerGroupFilterInput | null> | null;
  or?: Array<ModelSubscriptionCustomerGroupFilterInput | null> | null;
};

export type ModelSubscriptionAddressFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  addressType?: ModelSubscriptionStringInput | null;
  addr1?: ModelSubscriptionStringInput | null;
  addr2?: ModelSubscriptionStringInput | null;
  addr3?: ModelSubscriptionStringInput | null;
  county?: ModelSubscriptionStringInput | null;
  postCode?: ModelSubscriptionStringInput | null;
  Country?: ModelSubscriptionStringInput | null;
  driverInstructions?: ModelSubscriptionStringInput | null;
  notes?: ModelSubscriptionStringInput | null;
  enabled?: ModelSubscriptionBooleanInput | null;
  customerID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionAddressFilterInput | null> | null;
  or?: Array<ModelSubscriptionAddressFilterInput | null> | null;
};

export type ModelSubscriptionCampaignFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  emailCampaignStatus?: ModelSubscriptionStringInput | null;
  emailCampaignType?: ModelSubscriptionStringInput | null;
  emailCampaignSendType?: ModelSubscriptionStringInput | null;
  emailCampaignSendDate?: ModelSubscriptionStringInput | null;
  emailCampaignSendTime?: ModelSubscriptionStringInput | null;
  emailCampaignSendFrom?: ModelSubscriptionStringInput | null;
  emailCampaignTemplate?: ModelSubscriptionStringInput | null;
  emailCampaignSendAttachments?: ModelSubscriptionStringInput | null;
  emailCampaignSendNotes?: ModelSubscriptionStringInput | null;
  websiteID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionCampaignFilterInput | null> | null;
  or?: Array<ModelSubscriptionCampaignFilterInput | null> | null;
};

export type ModelSubscriptionCampaignEmailsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  campaignId?: ModelSubscriptionIDInput | null;
  customerID?: ModelSubscriptionIDInput | null;
  emailTo?: ModelSubscriptionStringInput | null;
  template?: ModelSubscriptionStringInput | null;
  templateData?: ModelSubscriptionStringInput | null;
  emailStatus?: ModelSubscriptionStringInput | null;
  emailSentDate?: ModelSubscriptionStringInput | null;
  notes?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCampaignEmailsFilterInput | null> | null;
  or?: Array<ModelSubscriptionCampaignEmailsFilterInput | null> | null;
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  orderNo?: ModelSubscriptionStringInput | null;
  customerReference?: ModelSubscriptionStringInput | null;
  itemsTotal?: ModelSubscriptionFloatInput | null;
  offerDiscount?: ModelSubscriptionFloatInput | null;
  price?: ModelSubscriptionFloatInput | null;
  discount?: ModelSubscriptionFloatInput | null;
  delivery?: ModelSubscriptionFloatInput | null;
  tax?: ModelSubscriptionFloatInput | null;
  orderTotal?: ModelSubscriptionFloatInput | null;
  orderNotes?: ModelSubscriptionStringInput | null;
  dateOrdered?: ModelSubscriptionStringInput | null;
  datePaid?: ModelSubscriptionStringInput | null;
  dateDelivered?: ModelSubscriptionStringInput | null;
  orderStatus?: ModelSubscriptionStringInput | null;
  customerID?: ModelSubscriptionIDInput | null;
  addressID?: ModelSubscriptionIDInput | null;
  offerID?: ModelSubscriptionIDInput | null;
  invoiceNumber?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionOrderFilterInput | null> | null;
  or?: Array<ModelSubscriptionOrderFilterInput | null> | null;
};

export type ModelSubscriptionPaymentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  orderID?: ModelSubscriptionIDInput | null;
  amount?: ModelSubscriptionFloatInput | null;
  notes?: ModelSubscriptionStringInput | null;
  paymentTypeID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionPaymentFilterInput | null> | null;
  or?: Array<ModelSubscriptionPaymentFilterInput | null> | null;
};

export type ModelSubscriptionPaymentTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionPaymentTypeFilterInput | null> | null;
  or?: Array<ModelSubscriptionPaymentTypeFilterInput | null> | null;
};

export type ModelSubscriptionOrderItemFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  fullPrice?: ModelSubscriptionFloatInput | null;
  discount?: ModelSubscriptionFloatInput | null;
  tax?: ModelSubscriptionFloatInput | null;
  price?: ModelSubscriptionFloatInput | null;
  quantity?: ModelSubscriptionIntInput | null;
  customerInputs?: ModelSubscriptionStringInput | null;
  orderItemNotes?: ModelSubscriptionStringInput | null;
  orderID?: ModelSubscriptionIDInput | null;
  productID?: ModelSubscriptionIDInput | null;
  offerID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionOrderItemFilterInput | null> | null;
  or?: Array<ModelSubscriptionOrderItemFilterInput | null> | null;
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  sku?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  title?: ModelSubscriptionStringInput | null;
  shortDescription?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  delivery?: ModelSubscriptionFloatInput | null;
  taxCode?: ModelSubscriptionStringInput | null;
  tax?: ModelSubscriptionFloatInput | null;
  price?: ModelSubscriptionFloatInput | null;
  size?: ModelSubscriptionStringInput | null;
  colour?: ModelSubscriptionStringInput | null;
  weight?: ModelSubscriptionStringInput | null;
  productServiceName?: ModelSubscriptionStringInput | null;
  productNotes?: ModelSubscriptionStringInput | null;
  websiteID?: ModelSubscriptionIDInput | null;
  offerID?: ModelSubscriptionIDInput | null;
  supplierID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionProductFilterInput | null> | null;
  or?: Array<ModelSubscriptionProductFilterInput | null> | null;
};

export type ModelSubscriptionProductGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  products?: ModelSubscriptionIDInput | null;
  tags?: ModelSubscriptionIDInput | null;
  productID?: ModelSubscriptionIDInput | null;
  tagID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionProductGroupFilterInput | null> | null;
  or?: Array<ModelSubscriptionProductGroupFilterInput | null> | null;
};

export type ModelSubscriptionAttributesFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  inputType?: ModelSubscriptionStringInput | null;
  validationTypes?: ModelSubscriptionStringInput | null;
  priceType?: ModelSubscriptionStringInput | null;
  associatedAttribute?: ModelSubscriptionIDInput | null;
  secondaryAssociatedAttribute?: ModelSubscriptionIDInput | null;
  basePrice?: ModelSubscriptionIntInput | null;
  notes?: ModelSubscriptionStringInput | null;
  productID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionAttributesFilterInput | null> | null;
  or?: Array<ModelSubscriptionAttributesFilterInput | null> | null;
};

export type ModelSubscriptionInputAttributeFilterInput = {
  inputType?: ModelSubscriptionStringInput | null;
  validationTypes?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionInputAttributeFilterInput | null> | null;
  or?: Array<ModelSubscriptionInputAttributeFilterInput | null> | null;
};

export type ModelSubscriptionPriceAttributeFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionPriceAttributeFilterInput | null> | null;
  or?: Array<ModelSubscriptionPriceAttributeFilterInput | null> | null;
};

export type ModelSubscriptionTagFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  area?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionTagFilterInput | null> | null;
  or?: Array<ModelSubscriptionTagFilterInput | null> | null;
};

export type ModelSubscriptionOfferFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  websiteID?: ModelSubscriptionIDInput | null;
  dateValidFrom?: ModelSubscriptionStringInput | null;
  dateValidTo?: ModelSubscriptionStringInput | null;
  offerType?: ModelSubscriptionStringInput | null;
  offerStatus?: ModelSubscriptionStringInput | null;
  discountPercentage?: ModelSubscriptionFloatInput | null;
  discountAmount?: ModelSubscriptionFloatInput | null;
  and?: Array<ModelSubscriptionOfferFilterInput | null> | null;
  or?: Array<ModelSubscriptionOfferFilterInput | null> | null;
};

export type ModelSubscriptionReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  rating?: ModelSubscriptionIntInput | null;
  reviewComment?: ModelSubscriptionStringInput | null;
  productID?: ModelSubscriptionIDInput | null;
  customerID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionReviewFilterInput | null> | null;
  or?: Array<ModelSubscriptionReviewFilterInput | null> | null;
};

export type ModelSubscriptionDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  title?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  documentType?: ModelSubscriptionStringInput | null;
  mimeType?: ModelSubscriptionStringInput | null;
  location?: ModelSubscriptionStringInput | null;
  productID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionDocumentFilterInput | null> | null;
  or?: Array<ModelSubscriptionDocumentFilterInput | null> | null;
};

export type ModelSubscriptionSupplierFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSupplierFilterInput | null> | null;
  or?: Array<ModelSubscriptionSupplierFilterInput | null> | null;
};

export type ModelSubscriptionProductTagsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  productID?: ModelSubscriptionIDInput | null;
  tagID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionProductTagsFilterInput | null> | null;
  or?: Array<ModelSubscriptionProductTagsFilterInput | null> | null;
};

export type ModelSubscriptionProductOffersFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  productID?: ModelSubscriptionIDInput | null;
  offerID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionProductOffersFilterInput | null> | null;
  or?: Array<ModelSubscriptionProductOffersFilterInput | null> | null;
};

export type ModelSubscriptionWebsiteFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  url?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  urlRegister?: ModelSubscriptionStringInput | null;
  websiteStatus?: ModelSubscriptionStringInput | null;
  dateLive?: ModelSubscriptionStringInput | null;
  frontEndConfig?: ModelSubscriptionStringInput | null;
  blogConfig?: ModelSubscriptionStringInput | null;
  themeConfig?: ModelSubscriptionStringInput | null;
  customerInputConfig?: ModelSubscriptionStringInput | null;
  workFlow?: ModelSubscriptionStringInput | null;
  siteData?: ModelSubscriptionStringInput | null;
  reportConfig?: ModelSubscriptionStringInput | null;
  clientID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionWebsiteFilterInput | null> | null;
  or?: Array<ModelSubscriptionWebsiteFilterInput | null> | null;
};

export type CreateCWSMutation = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCWSMutation = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCWSMutation = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateClientMutation = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateClientMutation = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteClientMutation = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateWebsiteMutation = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWebsiteMutation = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteWebsiteMutation = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserGroupMutation = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserGroupMutation = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserGroupMutation = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateGroupPermissionMutation = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateGroupPermissionMutation = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteGroupPermissionMutation = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomerMutation = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomerMutation = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCustomerMutation = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomerTermsMutation = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomerTermsMutation = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCustomerTermsMutation = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomerGroupMutation = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomerGroupMutation = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCustomerGroupMutation = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAddressMutation = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAddressMutation = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAddressMutation = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCampaignMutation = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCampaignMutation = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCampaignMutation = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCampaignEmailsMutation = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCampaignEmailsMutation = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCampaignEmailsMutation = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateOrderMutation = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOrderMutation = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOrderMutation = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreatePaymentMutation = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePaymentMutation = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeletePaymentMutation = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreatePaymentTypeMutation = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePaymentTypeMutation = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type DeletePaymentTypeMutation = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateOrderItemMutation = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOrderItemMutation = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOrderItemMutation = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateProductMutation = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProductMutation = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteProductMutation = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateProductGroupMutation = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProductGroupMutation = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteProductGroupMutation = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAttributesMutation = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAttributesMutation = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAttributesMutation = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateInputAttributeMutation = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateInputAttributeMutation = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteInputAttributeMutation = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreatePriceAttributeMutation = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePriceAttributeMutation = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeletePriceAttributeMutation = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateTagMutation = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTagMutation = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTagMutation = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateOfferMutation = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOfferMutation = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOfferMutation = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateReviewMutation = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateReviewMutation = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteReviewMutation = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateDocumentMutation = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDocumentMutation = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDocumentMutation = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSupplierMutation = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSupplierMutation = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSupplierMutation = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateProductTagsMutation = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type UpdateProductTagsMutation = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type DeleteProductTagsMutation = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type CreateProductOffersMutation = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type UpdateProductOffersMutation = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type DeleteProductOffersMutation = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type GetCWSQuery = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCWSQuery = {
  __typename: "ModelCWSConnection";
  items: Array<{
    __typename: "CWS";
    id: string;
    name: string;
    defaultFrontEndConfig?: string | null;
    schemaFrontEndConfig?: string | null;
    defaultBlogConfig?: string | null;
    schemaBlogConfig?: string | null;
    defaultThemeConfig?: string | null;
    schemaThemeConfig?: string | null;
    defaultCustomerInputConfig?: string | null;
    schemaCustomerInputConfig?: string | null;
    defaultWorkFlow?: string | null;
    schemaWorkFlow?: string | null;
    defaultSiteData?: string | null;
    schemaSiteData?: string | null;
    defaultReportConfig?: string | null;
    schemaReportConfig?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetClientQuery = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListClientsQuery = {
  __typename: "ModelClientConnection";
  items: Array<{
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserGroupQuery = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUserGroupsQuery = {
  __typename: "ModelUserGroupConnection";
  items: Array<{
    __typename: "UserGroup";
    id: string;
    groupName: string;
    description?: string | null;
    permissions?: Array<{
      __typename: "GroupPermission";
      id: string;
      name: string;
      description?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetGroupPermissionQuery = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListGroupPermissionsQuery = {
  __typename: "ModelGroupPermissionConnection";
  items: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    email: string;
    permissionStatus?: PermissionStatus | null;
    title?: string | null;
    userName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    userStatus?: UserStatus | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomerQuery = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCustomersQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomerTermsQuery = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCustomerTermsQuery = {
  __typename: "ModelCustomerTermsConnection";
  items: Array<{
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomerGroupQuery = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCustomerGroupsQuery = {
  __typename: "ModelCustomerGroupConnection";
  items: Array<{
    __typename: "CustomerGroup";
    id: string;
    name: string;
    description?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAddressQuery = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAddressesQuery = {
  __typename: "ModelAddressConnection";
  items: Array<{
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCampaignQuery = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCampaignsQuery = {
  __typename: "ModelCampaignConnection";
  items: Array<{
    __typename: "Campaign";
    id: string;
    name: string;
    description?: string | null;
    emailCampaignStatus?: EmailCampaignStatus | null;
    emailCampaignType?: EmailCampaignType | null;
    emailCampaignSendType?: EmailCampaignSendType | null;
    emailCampaignSendDate?: string | null;
    emailCampaignSendTime?: string | null;
    emailCampaignSendFrom?: string | null;
    emailCampaignTemplate?: string | null;
    emailCampaignSendAttachments?: string | null;
    emailCampaignSendNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCampaignEmailsQuery = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCampaignEmailsQuery = {
  __typename: "ModelCampaignEmailsConnection";
  items: Array<{
    __typename: "CampaignEmails";
    id: string;
    campaignId: string;
    customerID: string;
    emailTo?: string | null;
    template?: string | null;
    templateData?: string | null;
    emailStatus?: EmailStatus | null;
    emailSentDate?: string | null;
    notes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetOrderQuery = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListOrdersQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPaymentQuery = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListPaymentsQuery = {
  __typename: "ModelPaymentConnection";
  items: Array<{
    __typename: "Payment";
    id: string;
    orderID: string;
    order?: {
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    amount?: number | null;
    notes?: string | null;
    paymentTypeID: string;
    paymentType?: {
      __typename: "PaymentType";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPaymentTypeQuery = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type ListPaymentTypesQuery = {
  __typename: "ModelPaymentTypeConnection";
  items: Array<{
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetOrderItemQuery = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListOrderItemsQuery = {
  __typename: "ModelOrderItemConnection";
  items: Array<{
    __typename: "OrderItem";
    id: string;
    name: string;
    fullPrice?: number | null;
    discount?: number | null;
    tax?: number | null;
    price?: number | null;
    quantity?: number | null;
    customerInputs?: string | null;
    orderItemNotes?: string | null;
    group?: string | null;
    orderID: string;
    order?: {
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetProductQuery = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListProductsQuery = {
  __typename: "ModelProductConnection";
  items: Array<{
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetProductGroupQuery = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListProductGroupsQuery = {
  __typename: "ModelProductGroupConnection";
  items: Array<{
    __typename: "ProductGroup";
    id: string;
    products?: Array<string> | null;
    tags?: Array<string | null> | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    tagID: string;
    tag?: {
      __typename: "Tag";
      id: string;
      name: string;
      area: Area;
      product?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAttributesQuery = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAttributesQuery = {
  __typename: "ModelAttributesConnection";
  items: Array<{
    __typename: "Attributes";
    id: string;
    name?: string | null;
    description?: string | null;
    type?: AttributeType | null;
    inputType?: InputType | null;
    validationTypes?: Array<ValidationType | null> | null;
    priceType?: PriceCalculationType | null;
    associatedAttribute?: string | null;
    secondaryAssociatedAttribute?: string | null;
    basePrice?: number | null;
    notes?: string | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetInputAttributeQuery = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListInputAttributesQuery = {
  __typename: "ModelInputAttributeConnection";
  items: Array<{
    __typename: "InputAttribute";
    inputType?: InputType | null;
    validationTypes?: Array<ValidationType | null> | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPriceAttributeQuery = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListPriceAttributesQuery = {
  __typename: "ModelPriceAttributeConnection";
  items: Array<{
    __typename: "PriceAttribute";
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTagQuery = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTagsQuery = {
  __typename: "ModelTagConnection";
  items: Array<{
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetOfferQuery = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListOffersQuery = {
  __typename: "ModelOfferConnection";
  items: Array<{
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetReviewQuery = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListReviewsQuery = {
  __typename: "ModelReviewConnection";
  items: Array<{
    __typename: "Review";
    id: string;
    rating: number;
    reviewComment: string;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDocumentQuery = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListDocumentsQuery = {
  __typename: "ModelDocumentConnection";
  items: Array<{
    __typename: "Document";
    id: string;
    title: string;
    description: string;
    documentType: DocumentType;
    mimeType: MimeType;
    location: string;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSupplierQuery = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSuppliersQuery = {
  __typename: "ModelSupplierConnection";
  items: Array<{
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetProductTagsQuery = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ListProductTagsQuery = {
  __typename: "ModelProductTagsConnection";
  items: Array<{
    __typename: "ProductTags";
    id: string;
    productID: string;
    tagID: string;
    product: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    tag: {
      __typename: "Tag";
      id: string;
      name: string;
      area: Area;
      product?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetProductOffersQuery = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ListProductOffersQuery = {
  __typename: "ModelProductOffersConnection";
  items: Array<{
    __typename: "ProductOffers";
    id: string;
    productID: string;
    offerID: string;
    product: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    offer: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UsersByClientQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    email: string;
    permissionStatus?: PermissionStatus | null;
    title?: string | null;
    userName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    userStatus?: UserStatus | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CustomersByWebsiteQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CustomerGroupsByCustomerQuery = {
  __typename: "ModelCustomerGroupConnection";
  items: Array<{
    __typename: "CustomerGroup";
    id: string;
    name: string;
    description?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AddressesByCustomerQuery = {
  __typename: "ModelAddressConnection";
  items: Array<{
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EmailCampaignsByWebsiteQuery = {
  __typename: "ModelCampaignConnection";
  items: Array<{
    __typename: "Campaign";
    id: string;
    name: string;
    description?: string | null;
    emailCampaignStatus?: EmailCampaignStatus | null;
    emailCampaignType?: EmailCampaignType | null;
    emailCampaignSendType?: EmailCampaignSendType | null;
    emailCampaignSendDate?: string | null;
    emailCampaignSendTime?: string | null;
    emailCampaignSendFrom?: string | null;
    emailCampaignTemplate?: string | null;
    emailCampaignSendAttachments?: string | null;
    emailCampaignSendNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CampaignEmailsByCampaignQuery = {
  __typename: "ModelCampaignEmailsConnection";
  items: Array<{
    __typename: "CampaignEmails";
    id: string;
    campaignId: string;
    customerID: string;
    emailTo?: string | null;
    template?: string | null;
    templateData?: string | null;
    emailStatus?: EmailStatus | null;
    emailSentDate?: string | null;
    notes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CampaignEmailsByCustomerQuery = {
  __typename: "ModelCampaignEmailsConnection";
  items: Array<{
    __typename: "CampaignEmails";
    id: string;
    campaignId: string;
    customerID: string;
    emailTo?: string | null;
    template?: string | null;
    templateData?: string | null;
    emailStatus?: EmailStatus | null;
    emailSentDate?: string | null;
    notes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OrdersByCustomerByStatusQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OrdersByCustomerQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OrdersByCustomerByStatusByDateQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OrdersByCustomerByDateQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PaymentsByOrderQuery = {
  __typename: "ModelPaymentConnection";
  items: Array<{
    __typename: "Payment";
    id: string;
    orderID: string;
    order?: {
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    amount?: number | null;
    notes?: string | null;
    paymentTypeID: string;
    paymentType?: {
      __typename: "PaymentType";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PaymentsByOrderByTypeQuery = {
  __typename: "ModelPaymentConnection";
  items: Array<{
    __typename: "Payment";
    id: string;
    orderID: string;
    order?: {
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    amount?: number | null;
    notes?: string | null;
    paymentTypeID: string;
    paymentType?: {
      __typename: "PaymentType";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OrderItemsByOrderQuery = {
  __typename: "ModelOrderItemConnection";
  items: Array<{
    __typename: "OrderItem";
    id: string;
    name: string;
    fullPrice?: number | null;
    discount?: number | null;
    tax?: number | null;
    price?: number | null;
    quantity?: number | null;
    customerInputs?: string | null;
    orderItemNotes?: string | null;
    group?: string | null;
    orderID: string;
    order?: {
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OrderItemsByOfferQuery = {
  __typename: "ModelOrderItemConnection";
  items: Array<{
    __typename: "OrderItem";
    id: string;
    name: string;
    fullPrice?: number | null;
    discount?: number | null;
    tax?: number | null;
    price?: number | null;
    quantity?: number | null;
    customerInputs?: string | null;
    orderItemNotes?: string | null;
    group?: string | null;
    orderID: string;
    order?: {
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProductsByWebsiteQuery = {
  __typename: "ModelProductConnection";
  items: Array<{
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProductsByOfferQuery = {
  __typename: "ModelProductConnection";
  items: Array<{
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProductsBySupplierQuery = {
  __typename: "ModelProductConnection";
  items: Array<{
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProductGroupsByProductQuery = {
  __typename: "ModelProductGroupConnection";
  items: Array<{
    __typename: "ProductGroup";
    id: string;
    products?: Array<string> | null;
    tags?: Array<string | null> | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    tagID: string;
    tag?: {
      __typename: "Tag";
      id: string;
      name: string;
      area: Area;
      product?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AttributesByProductQuery = {
  __typename: "ModelAttributesConnection";
  items: Array<{
    __typename: "Attributes";
    id: string;
    name?: string | null;
    description?: string | null;
    type?: AttributeType | null;
    inputType?: InputType | null;
    validationTypes?: Array<ValidationType | null> | null;
    priceType?: PriceCalculationType | null;
    associatedAttribute?: string | null;
    secondaryAssociatedAttribute?: string | null;
    basePrice?: number | null;
    notes?: string | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AttributesBbyProductByTypeQuery = {
  __typename: "ModelAttributesConnection";
  items: Array<{
    __typename: "Attributes";
    id: string;
    name?: string | null;
    description?: string | null;
    type?: AttributeType | null;
    inputType?: InputType | null;
    validationTypes?: Array<ValidationType | null> | null;
    priceType?: PriceCalculationType | null;
    associatedAttribute?: string | null;
    secondaryAssociatedAttribute?: string | null;
    basePrice?: number | null;
    notes?: string | null;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OffersByWebsiteQuery = {
  __typename: "ModelOfferConnection";
  items: Array<{
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OffersByWebsiteByStatusQuery = {
  __typename: "ModelOfferConnection";
  items: Array<{
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OffersByWebsiteByTypeQuery = {
  __typename: "ModelOfferConnection";
  items: Array<{
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OffersByWebsiteByStatusByTypeQuery = {
  __typename: "ModelOfferConnection";
  items: Array<{
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OffersByWebsiteByStatusByValidDateQuery = {
  __typename: "ModelOfferConnection";
  items: Array<{
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ReviewsByProductQuery = {
  __typename: "ModelReviewConnection";
  items: Array<{
    __typename: "Review";
    id: string;
    rating: number;
    reviewComment: string;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type DocumentsByProductQuery = {
  __typename: "ModelDocumentConnection";
  items: Array<{
    __typename: "Document";
    id: string;
    title: string;
    description: string;
    documentType: DocumentType;
    mimeType: MimeType;
    location: string;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type DocumentsByProductByDocTypeQuery = {
  __typename: "ModelDocumentConnection";
  items: Array<{
    __typename: "Document";
    id: string;
    title: string;
    description: string;
    documentType: DocumentType;
    mimeType: MimeType;
    location: string;
    productID: string;
    product?: {
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetWebsiteQuery = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListWebsitesQuery = {
  __typename: "ModelWebsiteConnection";
  items: Array<{
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type WebsitesByClientQuery = {
  __typename: "ModelWebsiteConnection";
  items: Array<{
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateCWSSubscription = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCWSSubscription = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCWSSubscription = {
  __typename: "CWS";
  id: string;
  name: string;
  defaultFrontEndConfig?: string | null;
  schemaFrontEndConfig?: string | null;
  defaultBlogConfig?: string | null;
  schemaBlogConfig?: string | null;
  defaultThemeConfig?: string | null;
  schemaThemeConfig?: string | null;
  defaultCustomerInputConfig?: string | null;
  schemaCustomerInputConfig?: string | null;
  defaultWorkFlow?: string | null;
  schemaWorkFlow?: string | null;
  defaultSiteData?: string | null;
  schemaSiteData?: string | null;
  defaultReportConfig?: string | null;
  schemaReportConfig?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateClientSubscription = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateClientSubscription = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteClientSubscription = {
  __typename: "Client";
  id: string;
  name: string;
  contact?: string | null;
  phone?: string | null;
  email?: string | null;
  clientStatus?: ClientStatus | null;
  websites?: {
    __typename: "ModelWebsiteConnection";
    items: Array<{
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  users?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      email: string;
      permissionStatus?: PermissionStatus | null;
      title?: string | null;
      userName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userStatus?: UserStatus | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserGroupSubscription = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserGroupSubscription = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserGroupSubscription = {
  __typename: "UserGroup";
  id: string;
  groupName: string;
  description?: string | null;
  permissions?: Array<{
    __typename: "GroupPermission";
    id: string;
    name: string;
    description?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateGroupPermissionSubscription = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateGroupPermissionSubscription = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteGroupPermissionSubscription = {
  __typename: "GroupPermission";
  id: string;
  name: string;
  description?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  email: string;
  permissionStatus?: PermissionStatus | null;
  title?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: UserStatus | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomerSubscription = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCustomerSubscription = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCustomerSubscription = {
  __typename: "Customer";
  id: string;
  email: string;
  userName: string;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  phoneOther?: string | null;
  accountNumber?: string | null;
  customerStatus?: CustomerStatus | null;
  customerType?: CustomerType | null;
  madeToMeasureDiscount?: number | null;
  standardItemsDiscount?: number | null;
  deliveryDiscount?: number | null;
  orderDiscount?: number | null;
  customerTermsID?: string | null;
  customerTerms?: {
    __typename: "CustomerTerms";
    id: string;
    terms?: string | null;
    days?: number | null;
    waitForPayment?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  emailSubscribed?: boolean | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addresses?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orders?: {
    __typename: "ModelOrderConnection";
    items: Array<{
      __typename: "Order";
      id: string;
      orderNo?: string | null;
      customerReference?: string | null;
      itemsTotal?: number | null;
      offerDiscount?: number | null;
      price?: number | null;
      discount?: number | null;
      delivery?: number | null;
      tax?: number | null;
      orderTotal?: number | null;
      orderNotes?: string | null;
      dateOrdered?: string | null;
      datePaid?: string | null;
      dateDelivered?: string | null;
      orderStatus?: OrderStatus | null;
      group?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addressID?: string | null;
      address?: {
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID?: string | null;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItems?: {
        __typename: "ModelOrderItemConnection";
        items: Array<{
          __typename: "OrderItem";
          id: string;
          name: string;
          fullPrice?: number | null;
          discount?: number | null;
          tax?: number | null;
          price?: number | null;
          quantity?: number | null;
          customerInputs?: string | null;
          orderItemNotes?: string | null;
          group?: string | null;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      invoiceNumber?: string | null;
      payments?: {
        __typename: "ModelPaymentConnection";
        items: Array<{
          __typename: "Payment";
          id: string;
          orderID: string;
          order?: {
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          amount?: number | null;
          notes?: string | null;
          paymentTypeID: string;
          paymentType?: {
            __typename: "PaymentType";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customerGroups?: {
    __typename: "ModelCustomerGroupConnection";
    items: Array<{
      __typename: "CustomerGroup";
      id: string;
      name: string;
      description?: string | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomerTermsSubscription = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCustomerTermsSubscription = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCustomerTermsSubscription = {
  __typename: "CustomerTerms";
  id: string;
  terms?: string | null;
  days?: number | null;
  waitForPayment?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomerGroupSubscription = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCustomerGroupSubscription = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCustomerGroupSubscription = {
  __typename: "CustomerGroup";
  id: string;
  name: string;
  description?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAddressSubscription = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAddressSubscription = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAddressSubscription = {
  __typename: "Address";
  id: string;
  addressType: AddressType;
  addr1: string;
  addr2?: string | null;
  addr3?: string | null;
  county?: string | null;
  postCode?: string | null;
  Country?: string | null;
  driverInstructions?: string | null;
  notes?: string | null;
  group?: string | null;
  enabled?: boolean | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCampaignSubscription = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCampaignSubscription = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCampaignSubscription = {
  __typename: "Campaign";
  id: string;
  name: string;
  description?: string | null;
  emailCampaignStatus?: EmailCampaignStatus | null;
  emailCampaignType?: EmailCampaignType | null;
  emailCampaignSendType?: EmailCampaignSendType | null;
  emailCampaignSendDate?: string | null;
  emailCampaignSendTime?: string | null;
  emailCampaignSendFrom?: string | null;
  emailCampaignTemplate?: string | null;
  emailCampaignSendAttachments?: string | null;
  emailCampaignSendNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCampaignEmailsSubscription = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCampaignEmailsSubscription = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCampaignEmailsSubscription = {
  __typename: "CampaignEmails";
  id: string;
  campaignId: string;
  customerID: string;
  emailTo?: string | null;
  template?: string | null;
  templateData?: string | null;
  emailStatus?: EmailStatus | null;
  emailSentDate?: string | null;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateOrderSubscription = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOrderSubscription = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOrderSubscription = {
  __typename: "Order";
  id: string;
  orderNo?: string | null;
  customerReference?: string | null;
  itemsTotal?: number | null;
  offerDiscount?: number | null;
  price?: number | null;
  discount?: number | null;
  delivery?: number | null;
  tax?: number | null;
  orderTotal?: number | null;
  orderNotes?: string | null;
  dateOrdered?: string | null;
  datePaid?: string | null;
  dateDelivered?: string | null;
  orderStatus?: OrderStatus | null;
  group?: string | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  addressID?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    addressType: AddressType;
    addr1: string;
    addr2?: string | null;
    addr3?: string | null;
    county?: string | null;
    postCode?: string | null;
    Country?: string | null;
    driverInstructions?: string | null;
    notes?: string | null;
    group?: string | null;
    enabled?: boolean | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID?: string | null;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  orderItems?: {
    __typename: "ModelOrderItemConnection";
    items: Array<{
      __typename: "OrderItem";
      id: string;
      name: string;
      fullPrice?: number | null;
      discount?: number | null;
      tax?: number | null;
      price?: number | null;
      quantity?: number | null;
      customerInputs?: string | null;
      orderItemNotes?: string | null;
      group?: string | null;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  invoiceNumber?: string | null;
  payments?: {
    __typename: "ModelPaymentConnection";
    items: Array<{
      __typename: "Payment";
      id: string;
      orderID: string;
      order?: {
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      amount?: number | null;
      notes?: string | null;
      paymentTypeID: string;
      paymentType?: {
        __typename: "PaymentType";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePaymentSubscription = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePaymentSubscription = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePaymentSubscription = {
  __typename: "Payment";
  id: string;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  amount?: number | null;
  notes?: string | null;
  paymentTypeID: string;
  paymentType?: {
    __typename: "PaymentType";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePaymentTypeSubscription = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePaymentTypeSubscription = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePaymentTypeSubscription = {
  __typename: "PaymentType";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateOrderItemSubscription = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOrderItemSubscription = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOrderItemSubscription = {
  __typename: "OrderItem";
  id: string;
  name: string;
  fullPrice?: number | null;
  discount?: number | null;
  tax?: number | null;
  price?: number | null;
  quantity?: number | null;
  customerInputs?: string | null;
  orderItemNotes?: string | null;
  group?: string | null;
  orderID: string;
  order?: {
    __typename: "Order";
    id: string;
    orderNo?: string | null;
    customerReference?: string | null;
    itemsTotal?: number | null;
    offerDiscount?: number | null;
    price?: number | null;
    discount?: number | null;
    delivery?: number | null;
    tax?: number | null;
    orderTotal?: number | null;
    orderNotes?: string | null;
    dateOrdered?: string | null;
    datePaid?: string | null;
    dateDelivered?: string | null;
    orderStatus?: OrderStatus | null;
    group?: string | null;
    customerID: string;
    customer?: {
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addressID?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      addressType: AddressType;
      addr1: string;
      addr2?: string | null;
      addr3?: string | null;
      county?: string | null;
      postCode?: string | null;
      Country?: string | null;
      driverInstructions?: string | null;
      notes?: string | null;
      group?: string | null;
      enabled?: boolean | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID?: string | null;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderItems?: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        id: string;
        name: string;
        fullPrice?: number | null;
        discount?: number | null;
        tax?: number | null;
        price?: number | null;
        quantity?: number | null;
        customerInputs?: string | null;
        orderItemNotes?: string | null;
        group?: string | null;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    invoiceNumber?: string | null;
    payments?: {
      __typename: "ModelPaymentConnection";
      items: Array<{
        __typename: "Payment";
        id: string;
        orderID: string;
        order?: {
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        amount?: number | null;
        notes?: string | null;
        paymentTypeID: string;
        paymentType?: {
          __typename: "PaymentType";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProductSubscription = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProductSubscription = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProductSubscription = {
  __typename: "Product";
  id: string;
  sku: string;
  name: string;
  title: string;
  shortDescription?: string | null;
  description?: string | null;
  delivery?: number | null;
  taxCode?: TaxCode | null;
  tax?: number | null;
  price?: number | null;
  size?: string | null;
  colour?: string | null;
  weight?: string | null;
  productServiceName?: string | null;
  productNotes?: string | null;
  websiteID: string;
  website?: {
    __typename: "Website";
    id: string;
    name: string;
    url?: string | null;
    email?: string | null;
    urlRegister?: string | null;
    websiteStatus?: WebsiteStatus | null;
    dateLive?: string | null;
    frontEndConfig?: string | null;
    blogConfig?: string | null;
    themeConfig?: string | null;
    customerInputConfig?: string | null;
    workFlow?: string | null;
    siteData?: string | null;
    reportConfig?: string | null;
    clientID: string;
    client?: {
      __typename: "Client";
      id: string;
      name: string;
      contact?: string | null;
      phone?: string | null;
      email?: string | null;
      clientStatus?: ClientStatus | null;
      websites?: {
        __typename: "ModelWebsiteConnection";
        items: Array<{
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      users?: {
        __typename: "ModelUserConnection";
        items: Array<{
          __typename: "User";
          id: string;
          email: string;
          permissionStatus?: PermissionStatus | null;
          title?: string | null;
          userName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          userStatus?: UserStatus | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customers?: {
      __typename: "ModelCustomerConnection";
      items: Array<{
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    emailCampaigns?: {
      __typename: "ModelCampaignConnection";
      items: Array<{
        __typename: "Campaign";
        id: string;
        name: string;
        description?: string | null;
        emailCampaignStatus?: EmailCampaignStatus | null;
        emailCampaignType?: EmailCampaignType | null;
        emailCampaignSendType?: EmailCampaignSendType | null;
        emailCampaignSendDate?: string | null;
        emailCampaignSendTime?: string | null;
        emailCampaignSendFrom?: string | null;
        emailCampaignTemplate?: string | null;
        emailCampaignSendAttachments?: string | null;
        emailCampaignSendNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  offerID: string;
  offer?: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  supplierID: string;
  supplier?: {
    __typename: "Supplier";
    id: string;
    name: string;
    products?: {
      __typename: "ModelProductConnection";
      items: Array<{
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  productGroups?: {
    __typename: "ModelProductGroupConnection";
    items: Array<{
      __typename: "ProductGroup";
      id: string;
      products?: Array<string> | null;
      tags?: Array<string | null> | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tagID: string;
      tag?: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  reviews?: {
    __typename: "ModelReviewConnection";
    items: Array<{
      __typename: "Review";
      id: string;
      rating: number;
      reviewComment: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      customerID: string;
      customer?: {
        __typename: "Customer";
        id: string;
        email: string;
        userName: string;
        companyName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        phoneOther?: string | null;
        accountNumber?: string | null;
        customerStatus?: CustomerStatus | null;
        customerType?: CustomerType | null;
        madeToMeasureDiscount?: number | null;
        standardItemsDiscount?: number | null;
        deliveryDiscount?: number | null;
        orderDiscount?: number | null;
        customerTermsID?: string | null;
        customerTerms?: {
          __typename: "CustomerTerms";
          id: string;
          terms?: string | null;
          days?: number | null;
          waitForPayment?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        emailSubscribed?: boolean | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addresses?: {
          __typename: "ModelAddressConnection";
          items: Array<{
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        orders?: {
          __typename: "ModelOrderConnection";
          items: Array<{
            __typename: "Order";
            id: string;
            orderNo?: string | null;
            customerReference?: string | null;
            itemsTotal?: number | null;
            offerDiscount?: number | null;
            price?: number | null;
            discount?: number | null;
            delivery?: number | null;
            tax?: number | null;
            orderTotal?: number | null;
            orderNotes?: string | null;
            dateOrdered?: string | null;
            datePaid?: string | null;
            dateDelivered?: string | null;
            orderStatus?: OrderStatus | null;
            group?: string | null;
            customerID: string;
            addressID?: string | null;
            offerID?: string | null;
            invoiceNumber?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customerGroups?: {
          __typename: "ModelCustomerGroupConnection";
          items: Array<{
            __typename: "CustomerGroup";
            id: string;
            name: string;
            description?: string | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  attributes?: {
    __typename: "ModelAttributesConnection";
    items: Array<{
      __typename: "Attributes";
      id: string;
      name?: string | null;
      description?: string | null;
      type?: AttributeType | null;
      inputType?: InputType | null;
      validationTypes?: Array<ValidationType | null> | null;
      priceType?: PriceCalculationType | null;
      associatedAttribute?: string | null;
      secondaryAssociatedAttribute?: string | null;
      basePrice?: number | null;
      notes?: string | null;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  documents?: {
    __typename: "ModelDocumentConnection";
    items: Array<{
      __typename: "Document";
      id: string;
      title: string;
      description: string;
      documentType: DocumentType;
      mimeType: MimeType;
      location: string;
      productID: string;
      product?: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  offers?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProductGroupSubscription = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProductGroupSubscription = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProductGroupSubscription = {
  __typename: "ProductGroup";
  id: string;
  products?: Array<string> | null;
  tags?: Array<string | null> | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  tagID: string;
  tag?: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAttributesSubscription = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAttributesSubscription = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAttributesSubscription = {
  __typename: "Attributes";
  id: string;
  name?: string | null;
  description?: string | null;
  type?: AttributeType | null;
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  priceType?: PriceCalculationType | null;
  associatedAttribute?: string | null;
  secondaryAssociatedAttribute?: string | null;
  basePrice?: number | null;
  notes?: string | null;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateInputAttributeSubscription = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateInputAttributeSubscription = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteInputAttributeSubscription = {
  __typename: "InputAttribute";
  inputType?: InputType | null;
  validationTypes?: Array<ValidationType | null> | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePriceAttributeSubscription = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePriceAttributeSubscription = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePriceAttributeSubscription = {
  __typename: "PriceAttribute";
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTagSubscription = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTagSubscription = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTagSubscription = {
  __typename: "Tag";
  id: string;
  name: string;
  area: Area;
  product?: {
    __typename: "ModelProductTagsConnection";
    items: Array<{
      __typename: "ProductTags";
      id: string;
      productID: string;
      tagID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      tag: {
        __typename: "Tag";
        id: string;
        name: string;
        area: Area;
        product?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateOfferSubscription = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOfferSubscription = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOfferSubscription = {
  __typename: "Offer";
  id: string;
  websiteID: string;
  dateValidFrom: string;
  dateValidTo: string;
  offerType: OfferType;
  offerStatus: OfferStatus;
  discountPercentage?: number | null;
  discountAmount?: number | null;
  products?: {
    __typename: "ModelProductOffersConnection";
    items: Array<{
      __typename: "ProductOffers";
      id: string;
      productID: string;
      offerID: string;
      product: {
        __typename: "Product";
        id: string;
        sku: string;
        name: string;
        title: string;
        shortDescription?: string | null;
        description?: string | null;
        delivery?: number | null;
        taxCode?: TaxCode | null;
        tax?: number | null;
        price?: number | null;
        size?: string | null;
        colour?: string | null;
        weight?: string | null;
        productServiceName?: string | null;
        productNotes?: string | null;
        websiteID: string;
        website?: {
          __typename: "Website";
          id: string;
          name: string;
          url?: string | null;
          email?: string | null;
          urlRegister?: string | null;
          websiteStatus?: WebsiteStatus | null;
          dateLive?: string | null;
          frontEndConfig?: string | null;
          blogConfig?: string | null;
          themeConfig?: string | null;
          customerInputConfig?: string | null;
          workFlow?: string | null;
          siteData?: string | null;
          reportConfig?: string | null;
          clientID: string;
          client?: {
            __typename: "Client";
            id: string;
            name: string;
            contact?: string | null;
            phone?: string | null;
            email?: string | null;
            clientStatus?: ClientStatus | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          customers?: {
            __typename: "ModelCustomerConnection";
            nextToken?: string | null;
          } | null;
          emailCampaigns?: {
            __typename: "ModelCampaignConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID: string;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        supplierID: string;
        supplier?: {
          __typename: "Supplier";
          id: string;
          name: string;
          products?: {
            __typename: "ModelProductConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        productGroups?: {
          __typename: "ModelProductGroupConnection";
          items: Array<{
            __typename: "ProductGroup";
            id: string;
            products?: Array<string> | null;
            tags?: Array<string | null> | null;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        reviews?: {
          __typename: "ModelReviewConnection";
          items: Array<{
            __typename: "Review";
            id: string;
            rating: number;
            reviewComment: string;
            productID: string;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        attributes?: {
          __typename: "ModelAttributesConnection";
          items: Array<{
            __typename: "Attributes";
            id: string;
            name?: string | null;
            description?: string | null;
            type?: AttributeType | null;
            inputType?: InputType | null;
            validationTypes?: Array<ValidationType | null> | null;
            priceType?: PriceCalculationType | null;
            associatedAttribute?: string | null;
            secondaryAssociatedAttribute?: string | null;
            basePrice?: number | null;
            notes?: string | null;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        documents?: {
          __typename: "ModelDocumentConnection";
          items: Array<{
            __typename: "Document";
            id: string;
            title: string;
            description: string;
            documentType: DocumentType;
            mimeType: MimeType;
            location: string;
            productID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        tags?: {
          __typename: "ModelProductTagsConnection";
          items: Array<{
            __typename: "ProductTags";
            id: string;
            productID: string;
            tagID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        offers?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      offer: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      };
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateReviewSubscription = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateReviewSubscription = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteReviewSubscription = {
  __typename: "Review";
  id: string;
  rating: number;
  reviewComment: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  customerID: string;
  customer?: {
    __typename: "Customer";
    id: string;
    email: string;
    userName: string;
    companyName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    phoneOther?: string | null;
    accountNumber?: string | null;
    customerStatus?: CustomerStatus | null;
    customerType?: CustomerType | null;
    madeToMeasureDiscount?: number | null;
    standardItemsDiscount?: number | null;
    deliveryDiscount?: number | null;
    orderDiscount?: number | null;
    customerTermsID?: string | null;
    customerTerms?: {
      __typename: "CustomerTerms";
      id: string;
      terms?: string | null;
      days?: number | null;
      waitForPayment?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    emailSubscribed?: boolean | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    addresses?: {
      __typename: "ModelAddressConnection";
      items: Array<{
        __typename: "Address";
        id: string;
        addressType: AddressType;
        addr1: string;
        addr2?: string | null;
        addr3?: string | null;
        county?: string | null;
        postCode?: string | null;
        Country?: string | null;
        driverInstructions?: string | null;
        notes?: string | null;
        group?: string | null;
        enabled?: boolean | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orders?: {
      __typename: "ModelOrderConnection";
      items: Array<{
        __typename: "Order";
        id: string;
        orderNo?: string | null;
        customerReference?: string | null;
        itemsTotal?: number | null;
        offerDiscount?: number | null;
        price?: number | null;
        discount?: number | null;
        delivery?: number | null;
        tax?: number | null;
        orderTotal?: number | null;
        orderNotes?: string | null;
        dateOrdered?: string | null;
        datePaid?: string | null;
        dateDelivered?: string | null;
        orderStatus?: OrderStatus | null;
        group?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        addressID?: string | null;
        address?: {
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        offerID?: string | null;
        offer?: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        orderItems?: {
          __typename: "ModelOrderItemConnection";
          items: Array<{
            __typename: "OrderItem";
            id: string;
            name: string;
            fullPrice?: number | null;
            discount?: number | null;
            tax?: number | null;
            price?: number | null;
            quantity?: number | null;
            customerInputs?: string | null;
            orderItemNotes?: string | null;
            group?: string | null;
            orderID: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        invoiceNumber?: string | null;
        payments?: {
          __typename: "ModelPaymentConnection";
          items: Array<{
            __typename: "Payment";
            id: string;
            orderID: string;
            amount?: number | null;
            notes?: string | null;
            paymentTypeID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    customerGroups?: {
      __typename: "ModelCustomerGroupConnection";
      items: Array<{
        __typename: "CustomerGroup";
        id: string;
        name: string;
        description?: string | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDocumentSubscription = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDocumentSubscription = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDocumentSubscription = {
  __typename: "Document";
  id: string;
  title: string;
  description: string;
  documentType: DocumentType;
  mimeType: MimeType;
  location: string;
  productID: string;
  product?: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSupplierSubscription = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSupplierSubscription = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSupplierSubscription = {
  __typename: "Supplier";
  id: string;
  name: string;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProductTagsSubscription = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProductTagsSubscription = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProductTagsSubscription = {
  __typename: "ProductTags";
  id: string;
  productID: string;
  tagID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  tag: {
    __typename: "Tag";
    id: string;
    name: string;
    area: Area;
    product?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProductOffersSubscription = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProductOffersSubscription = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProductOffersSubscription = {
  __typename: "ProductOffers";
  id: string;
  productID: string;
  offerID: string;
  product: {
    __typename: "Product";
    id: string;
    sku: string;
    name: string;
    title: string;
    shortDescription?: string | null;
    description?: string | null;
    delivery?: number | null;
    taxCode?: TaxCode | null;
    tax?: number | null;
    price?: number | null;
    size?: string | null;
    colour?: string | null;
    weight?: string | null;
    productServiceName?: string | null;
    productNotes?: string | null;
    websiteID: string;
    website?: {
      __typename: "Website";
      id: string;
      name: string;
      url?: string | null;
      email?: string | null;
      urlRegister?: string | null;
      websiteStatus?: WebsiteStatus | null;
      dateLive?: string | null;
      frontEndConfig?: string | null;
      blogConfig?: string | null;
      themeConfig?: string | null;
      customerInputConfig?: string | null;
      workFlow?: string | null;
      siteData?: string | null;
      reportConfig?: string | null;
      clientID: string;
      client?: {
        __typename: "Client";
        id: string;
        name: string;
        contact?: string | null;
        phone?: string | null;
        email?: string | null;
        clientStatus?: ClientStatus | null;
        websites?: {
          __typename: "ModelWebsiteConnection";
          items: Array<{
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        users?: {
          __typename: "ModelUserConnection";
          items: Array<{
            __typename: "User";
            id: string;
            email: string;
            permissionStatus?: PermissionStatus | null;
            title?: string | null;
            userName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            userStatus?: UserStatus | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customers?: {
        __typename: "ModelCustomerConnection";
        items: Array<{
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      emailCampaigns?: {
        __typename: "ModelCampaignConnection";
        items: Array<{
          __typename: "Campaign";
          id: string;
          name: string;
          description?: string | null;
          emailCampaignStatus?: EmailCampaignStatus | null;
          emailCampaignType?: EmailCampaignType | null;
          emailCampaignSendType?: EmailCampaignSendType | null;
          emailCampaignSendDate?: string | null;
          emailCampaignSendTime?: string | null;
          emailCampaignSendFrom?: string | null;
          emailCampaignTemplate?: string | null;
          emailCampaignSendAttachments?: string | null;
          emailCampaignSendNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    offerID: string;
    offer?: {
      __typename: "Offer";
      id: string;
      websiteID: string;
      dateValidFrom: string;
      dateValidTo: string;
      offerType: OfferType;
      offerStatus: OfferStatus;
      discountPercentage?: number | null;
      discountAmount?: number | null;
      products?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    supplierID: string;
    supplier?: {
      __typename: "Supplier";
      id: string;
      name: string;
      products?: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    productGroups?: {
      __typename: "ModelProductGroupConnection";
      items: Array<{
        __typename: "ProductGroup";
        id: string;
        products?: Array<string> | null;
        tags?: Array<string | null> | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tagID: string;
        tag?: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    reviews?: {
      __typename: "ModelReviewConnection";
      items: Array<{
        __typename: "Review";
        id: string;
        rating: number;
        reviewComment: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        customerID: string;
        customer?: {
          __typename: "Customer";
          id: string;
          email: string;
          userName: string;
          companyName?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          phoneOther?: string | null;
          accountNumber?: string | null;
          customerStatus?: CustomerStatus | null;
          customerType?: CustomerType | null;
          madeToMeasureDiscount?: number | null;
          standardItemsDiscount?: number | null;
          deliveryDiscount?: number | null;
          orderDiscount?: number | null;
          customerTermsID?: string | null;
          customerTerms?: {
            __typename: "CustomerTerms";
            id: string;
            terms?: string | null;
            days?: number | null;
            waitForPayment?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          emailSubscribed?: boolean | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addresses?: {
            __typename: "ModelAddressConnection";
            nextToken?: string | null;
          } | null;
          orders?: {
            __typename: "ModelOrderConnection";
            nextToken?: string | null;
          } | null;
          customerGroups?: {
            __typename: "ModelCustomerGroupConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    attributes?: {
      __typename: "ModelAttributesConnection";
      items: Array<{
        __typename: "Attributes";
        id: string;
        name?: string | null;
        description?: string | null;
        type?: AttributeType | null;
        inputType?: InputType | null;
        validationTypes?: Array<ValidationType | null> | null;
        priceType?: PriceCalculationType | null;
        associatedAttribute?: string | null;
        secondaryAssociatedAttribute?: string | null;
        basePrice?: number | null;
        notes?: string | null;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    documents?: {
      __typename: "ModelDocumentConnection";
      items: Array<{
        __typename: "Document";
        id: string;
        title: string;
        description: string;
        documentType: DocumentType;
        mimeType: MimeType;
        location: string;
        productID: string;
        product?: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelProductTagsConnection";
      items: Array<{
        __typename: "ProductTags";
        id: string;
        productID: string;
        tagID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        tag: {
          __typename: "Tag";
          id: string;
          name: string;
          area: Area;
          product?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    offers?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  offer: {
    __typename: "Offer";
    id: string;
    websiteID: string;
    dateValidFrom: string;
    dateValidTo: string;
    offerType: OfferType;
    offerStatus: OfferStatus;
    discountPercentage?: number | null;
    discountAmount?: number | null;
    products?: {
      __typename: "ModelProductOffersConnection";
      items: Array<{
        __typename: "ProductOffers";
        id: string;
        productID: string;
        offerID: string;
        product: {
          __typename: "Product";
          id: string;
          sku: string;
          name: string;
          title: string;
          shortDescription?: string | null;
          description?: string | null;
          delivery?: number | null;
          taxCode?: TaxCode | null;
          tax?: number | null;
          price?: number | null;
          size?: string | null;
          colour?: string | null;
          weight?: string | null;
          productServiceName?: string | null;
          productNotes?: string | null;
          websiteID: string;
          website?: {
            __typename: "Website";
            id: string;
            name: string;
            url?: string | null;
            email?: string | null;
            urlRegister?: string | null;
            websiteStatus?: WebsiteStatus | null;
            dateLive?: string | null;
            frontEndConfig?: string | null;
            blogConfig?: string | null;
            themeConfig?: string | null;
            customerInputConfig?: string | null;
            workFlow?: string | null;
            siteData?: string | null;
            reportConfig?: string | null;
            clientID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID: string;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          supplierID: string;
          supplier?: {
            __typename: "Supplier";
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          productGroups?: {
            __typename: "ModelProductGroupConnection";
            nextToken?: string | null;
          } | null;
          reviews?: {
            __typename: "ModelReviewConnection";
            nextToken?: string | null;
          } | null;
          attributes?: {
            __typename: "ModelAttributesConnection";
            nextToken?: string | null;
          } | null;
          documents?: {
            __typename: "ModelDocumentConnection";
            nextToken?: string | null;
          } | null;
          tags?: {
            __typename: "ModelProductTagsConnection";
            nextToken?: string | null;
          } | null;
          offers?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        offer: {
          __typename: "Offer";
          id: string;
          websiteID: string;
          dateValidFrom: string;
          dateValidTo: string;
          offerType: OfferType;
          offerStatus: OfferStatus;
          discountPercentage?: number | null;
          discountAmount?: number | null;
          products?: {
            __typename: "ModelProductOffersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        };
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type OnCreateWebsiteSubscription = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateWebsiteSubscription = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteWebsiteSubscription = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  frontEndConfig?: string | null;
  blogConfig?: string | null;
  themeConfig?: string | null;
  customerInputConfig?: string | null;
  workFlow?: string | null;
  siteData?: string | null;
  reportConfig?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      items: Array<{
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        email: string;
        permissionStatus?: PermissionStatus | null;
        title?: string | null;
        userName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        userStatus?: UserStatus | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      offerID: string;
      offer?: {
        __typename: "Offer";
        id: string;
        websiteID: string;
        dateValidFrom: string;
        dateValidTo: string;
        offerType: OfferType;
        offerStatus: OfferStatus;
        discountPercentage?: number | null;
        discountAmount?: number | null;
        products?: {
          __typename: "ModelProductOffersConnection";
          items: Array<{
            __typename: "ProductOffers";
            id: string;
            productID: string;
            offerID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      supplierID: string;
      supplier?: {
        __typename: "Supplier";
        id: string;
        name: string;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      productGroups?: {
        __typename: "ModelProductGroupConnection";
        items: Array<{
          __typename: "ProductGroup";
          id: string;
          products?: Array<string> | null;
          tags?: Array<string | null> | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          tagID: string;
          tag?: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      reviews?: {
        __typename: "ModelReviewConnection";
        items: Array<{
          __typename: "Review";
          id: string;
          rating: number;
          reviewComment: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      attributes?: {
        __typename: "ModelAttributesConnection";
        items: Array<{
          __typename: "Attributes";
          id: string;
          name?: string | null;
          description?: string | null;
          type?: AttributeType | null;
          inputType?: InputType | null;
          validationTypes?: Array<ValidationType | null> | null;
          priceType?: PriceCalculationType | null;
          associatedAttribute?: string | null;
          secondaryAssociatedAttribute?: string | null;
          basePrice?: number | null;
          notes?: string | null;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      documents?: {
        __typename: "ModelDocumentConnection";
        items: Array<{
          __typename: "Document";
          id: string;
          title: string;
          description: string;
          documentType: DocumentType;
          mimeType: MimeType;
          location: string;
          productID: string;
          product?: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          tag: {
            __typename: "Tag";
            id: string;
            name: string;
            area: Area;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      offers?: {
        __typename: "ModelProductOffersConnection";
        items: Array<{
          __typename: "ProductOffers";
          id: string;
          productID: string;
          offerID: string;
          product: {
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          };
          offer: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          };
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  customers?: {
    __typename: "ModelCustomerConnection";
    items: Array<{
      __typename: "Customer";
      id: string;
      email: string;
      userName: string;
      companyName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      phoneOther?: string | null;
      accountNumber?: string | null;
      customerStatus?: CustomerStatus | null;
      customerType?: CustomerType | null;
      madeToMeasureDiscount?: number | null;
      standardItemsDiscount?: number | null;
      deliveryDiscount?: number | null;
      orderDiscount?: number | null;
      customerTermsID?: string | null;
      customerTerms?: {
        __typename: "CustomerTerms";
        id: string;
        terms?: string | null;
        days?: number | null;
        waitForPayment?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      emailSubscribed?: boolean | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      addresses?: {
        __typename: "ModelAddressConnection";
        items: Array<{
          __typename: "Address";
          id: string;
          addressType: AddressType;
          addr1: string;
          addr2?: string | null;
          addr3?: string | null;
          county?: string | null;
          postCode?: string | null;
          Country?: string | null;
          driverInstructions?: string | null;
          notes?: string | null;
          group?: string | null;
          enabled?: boolean | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      orders?: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          orderNo?: string | null;
          customerReference?: string | null;
          itemsTotal?: number | null;
          offerDiscount?: number | null;
          price?: number | null;
          discount?: number | null;
          delivery?: number | null;
          tax?: number | null;
          orderTotal?: number | null;
          orderNotes?: string | null;
          dateOrdered?: string | null;
          datePaid?: string | null;
          dateDelivered?: string | null;
          orderStatus?: OrderStatus | null;
          group?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          addressID?: string | null;
          address?: {
            __typename: "Address";
            id: string;
            addressType: AddressType;
            addr1: string;
            addr2?: string | null;
            addr3?: string | null;
            county?: string | null;
            postCode?: string | null;
            Country?: string | null;
            driverInstructions?: string | null;
            notes?: string | null;
            group?: string | null;
            enabled?: boolean | null;
            customerID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          offerID?: string | null;
          offer?: {
            __typename: "Offer";
            id: string;
            websiteID: string;
            dateValidFrom: string;
            dateValidTo: string;
            offerType: OfferType;
            offerStatus: OfferStatus;
            discountPercentage?: number | null;
            discountAmount?: number | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          orderItems?: {
            __typename: "ModelOrderItemConnection";
            nextToken?: string | null;
          } | null;
          invoiceNumber?: string | null;
          payments?: {
            __typename: "ModelPaymentConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      customerGroups?: {
        __typename: "ModelCustomerGroupConnection";
        items: Array<{
          __typename: "CustomerGroup";
          id: string;
          name: string;
          description?: string | null;
          customerID: string;
          customer?: {
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  emailCampaigns?: {
    __typename: "ModelCampaignConnection";
    items: Array<{
      __typename: "Campaign";
      id: string;
      name: string;
      description?: string | null;
      emailCampaignStatus?: EmailCampaignStatus | null;
      emailCampaignType?: EmailCampaignType | null;
      emailCampaignSendType?: EmailCampaignSendType | null;
      emailCampaignSendDate?: string | null;
      emailCampaignSendTime?: string | null;
      emailCampaignSendFrom?: string | null;
      emailCampaignTemplate?: string | null;
      emailCampaignSendAttachments?: string | null;
      emailCampaignSendNotes?: string | null;
      websiteID: string;
      website?: {
        __typename: "Website";
        id: string;
        name: string;
        url?: string | null;
        email?: string | null;
        urlRegister?: string | null;
        websiteStatus?: WebsiteStatus | null;
        dateLive?: string | null;
        frontEndConfig?: string | null;
        blogConfig?: string | null;
        themeConfig?: string | null;
        customerInputConfig?: string | null;
        workFlow?: string | null;
        siteData?: string | null;
        reportConfig?: string | null;
        clientID: string;
        client?: {
          __typename: "Client";
          id: string;
          name: string;
          contact?: string | null;
          phone?: string | null;
          email?: string | null;
          clientStatus?: ClientStatus | null;
          websites?: {
            __typename: "ModelWebsiteConnection";
            nextToken?: string | null;
          } | null;
          users?: {
            __typename: "ModelUserConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        products?: {
          __typename: "ModelProductConnection";
          items: Array<{
            __typename: "Product";
            id: string;
            sku: string;
            name: string;
            title: string;
            shortDescription?: string | null;
            description?: string | null;
            delivery?: number | null;
            taxCode?: TaxCode | null;
            tax?: number | null;
            price?: number | null;
            size?: string | null;
            colour?: string | null;
            weight?: string | null;
            productServiceName?: string | null;
            productNotes?: string | null;
            websiteID: string;
            offerID: string;
            supplierID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        customers?: {
          __typename: "ModelCustomerConnection";
          items: Array<{
            __typename: "Customer";
            id: string;
            email: string;
            userName: string;
            companyName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            phoneOther?: string | null;
            accountNumber?: string | null;
            customerStatus?: CustomerStatus | null;
            customerType?: CustomerType | null;
            madeToMeasureDiscount?: number | null;
            standardItemsDiscount?: number | null;
            deliveryDiscount?: number | null;
            orderDiscount?: number | null;
            customerTermsID?: string | null;
            emailSubscribed?: boolean | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        emailCampaigns?: {
          __typename: "ModelCampaignConnection";
          items: Array<{
            __typename: "Campaign";
            id: string;
            name: string;
            description?: string | null;
            emailCampaignStatus?: EmailCampaignStatus | null;
            emailCampaignType?: EmailCampaignType | null;
            emailCampaignSendType?: EmailCampaignSendType | null;
            emailCampaignSendDate?: string | null;
            emailCampaignSendTime?: string | null;
            emailCampaignSendFrom?: string | null;
            emailCampaignTemplate?: string | null;
            emailCampaignSendAttachments?: string | null;
            emailCampaignSendNotes?: string | null;
            websiteID: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateCWS(
    input: CreateCWSInput,
    condition?: ModelCWSConditionInput
  ): Promise<CreateCWSMutation> {
    const statement = `mutation CreateCWS($input: CreateCWSInput!, $condition: ModelCWSConditionInput) {
        createCWS(input: $input, condition: $condition) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCWSMutation>response.data.createCWS;
  }
  async UpdateCWS(
    input: UpdateCWSInput,
    condition?: ModelCWSConditionInput
  ): Promise<UpdateCWSMutation> {
    const statement = `mutation UpdateCWS($input: UpdateCWSInput!, $condition: ModelCWSConditionInput) {
        updateCWS(input: $input, condition: $condition) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCWSMutation>response.data.updateCWS;
  }
  async DeleteCWS(
    input: DeleteCWSInput,
    condition?: ModelCWSConditionInput
  ): Promise<DeleteCWSMutation> {
    const statement = `mutation DeleteCWS($input: DeleteCWSInput!, $condition: ModelCWSConditionInput) {
        deleteCWS(input: $input, condition: $condition) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCWSMutation>response.data.deleteCWS;
  }
  async CreateClient(
    input: CreateClientInput,
    condition?: ModelClientConditionInput
  ): Promise<CreateClientMutation> {
    const statement = `mutation CreateClient($input: CreateClientInput!, $condition: ModelClientConditionInput) {
        createClient(input: $input, condition: $condition) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateClientMutation>response.data.createClient;
  }
  async UpdateClient(
    input: UpdateClientInput,
    condition?: ModelClientConditionInput
  ): Promise<UpdateClientMutation> {
    const statement = `mutation UpdateClient($input: UpdateClientInput!, $condition: ModelClientConditionInput) {
        updateClient(input: $input, condition: $condition) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateClientMutation>response.data.updateClient;
  }
  async DeleteClient(
    input: DeleteClientInput,
    condition?: ModelClientConditionInput
  ): Promise<DeleteClientMutation> {
    const statement = `mutation DeleteClient($input: DeleteClientInput!, $condition: ModelClientConditionInput) {
        deleteClient(input: $input, condition: $condition) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteClientMutation>response.data.deleteClient;
  }
  async CreateWebsite(
    input: CreateWebsiteInput,
    condition?: ModelWebsiteConditionInput
  ): Promise<CreateWebsiteMutation> {
    const statement = `mutation CreateWebsite($input: CreateWebsiteInput!, $condition: ModelWebsiteConditionInput) {
        createWebsite(input: $input, condition: $condition) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWebsiteMutation>response.data.createWebsite;
  }
  async UpdateWebsite(
    input: UpdateWebsiteInput,
    condition?: ModelWebsiteConditionInput
  ): Promise<UpdateWebsiteMutation> {
    const statement = `mutation UpdateWebsite($input: UpdateWebsiteInput!, $condition: ModelWebsiteConditionInput) {
        updateWebsite(input: $input, condition: $condition) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWebsiteMutation>response.data.updateWebsite;
  }
  async DeleteWebsite(
    input: DeleteWebsiteInput,
    condition?: ModelWebsiteConditionInput
  ): Promise<DeleteWebsiteMutation> {
    const statement = `mutation DeleteWebsite($input: DeleteWebsiteInput!, $condition: ModelWebsiteConditionInput) {
        deleteWebsite(input: $input, condition: $condition) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWebsiteMutation>response.data.deleteWebsite;
  }
  async CreateUserGroup(
    input: CreateUserGroupInput,
    condition?: ModelUserGroupConditionInput
  ): Promise<CreateUserGroupMutation> {
    const statement = `mutation CreateUserGroup($input: CreateUserGroupInput!, $condition: ModelUserGroupConditionInput) {
        createUserGroup(input: $input, condition: $condition) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserGroupMutation>response.data.createUserGroup;
  }
  async UpdateUserGroup(
    input: UpdateUserGroupInput,
    condition?: ModelUserGroupConditionInput
  ): Promise<UpdateUserGroupMutation> {
    const statement = `mutation UpdateUserGroup($input: UpdateUserGroupInput!, $condition: ModelUserGroupConditionInput) {
        updateUserGroup(input: $input, condition: $condition) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserGroupMutation>response.data.updateUserGroup;
  }
  async DeleteUserGroup(
    input: DeleteUserGroupInput,
    condition?: ModelUserGroupConditionInput
  ): Promise<DeleteUserGroupMutation> {
    const statement = `mutation DeleteUserGroup($input: DeleteUserGroupInput!, $condition: ModelUserGroupConditionInput) {
        deleteUserGroup(input: $input, condition: $condition) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserGroupMutation>response.data.deleteUserGroup;
  }
  async CreateGroupPermission(
    input: CreateGroupPermissionInput,
    condition?: ModelGroupPermissionConditionInput
  ): Promise<CreateGroupPermissionMutation> {
    const statement = `mutation CreateGroupPermission($input: CreateGroupPermissionInput!, $condition: ModelGroupPermissionConditionInput) {
        createGroupPermission(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateGroupPermissionMutation>response.data.createGroupPermission;
  }
  async UpdateGroupPermission(
    input: UpdateGroupPermissionInput,
    condition?: ModelGroupPermissionConditionInput
  ): Promise<UpdateGroupPermissionMutation> {
    const statement = `mutation UpdateGroupPermission($input: UpdateGroupPermissionInput!, $condition: ModelGroupPermissionConditionInput) {
        updateGroupPermission(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateGroupPermissionMutation>response.data.updateGroupPermission;
  }
  async DeleteGroupPermission(
    input: DeleteGroupPermissionInput,
    condition?: ModelGroupPermissionConditionInput
  ): Promise<DeleteGroupPermissionMutation> {
    const statement = `mutation DeleteGroupPermission($input: DeleteGroupPermissionInput!, $condition: ModelGroupPermissionConditionInput) {
        deleteGroupPermission(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteGroupPermissionMutation>response.data.deleteGroupPermission;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateCustomer(
    input: CreateCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<CreateCustomerMutation> {
    const statement = `mutation CreateCustomer($input: CreateCustomerInput!, $condition: ModelCustomerConditionInput) {
        createCustomer(input: $input, condition: $condition) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerMutation>response.data.createCustomer;
  }
  async UpdateCustomer(
    input: UpdateCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<UpdateCustomerMutation> {
    const statement = `mutation UpdateCustomer($input: UpdateCustomerInput!, $condition: ModelCustomerConditionInput) {
        updateCustomer(input: $input, condition: $condition) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerMutation>response.data.updateCustomer;
  }
  async DeleteCustomer(
    input: DeleteCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<DeleteCustomerMutation> {
    const statement = `mutation DeleteCustomer($input: DeleteCustomerInput!, $condition: ModelCustomerConditionInput) {
        deleteCustomer(input: $input, condition: $condition) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerMutation>response.data.deleteCustomer;
  }
  async CreateCustomerTerms(
    input: CreateCustomerTermsInput,
    condition?: ModelCustomerTermsConditionInput
  ): Promise<CreateCustomerTermsMutation> {
    const statement = `mutation CreateCustomerTerms($input: CreateCustomerTermsInput!, $condition: ModelCustomerTermsConditionInput) {
        createCustomerTerms(input: $input, condition: $condition) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerTermsMutation>response.data.createCustomerTerms;
  }
  async UpdateCustomerTerms(
    input: UpdateCustomerTermsInput,
    condition?: ModelCustomerTermsConditionInput
  ): Promise<UpdateCustomerTermsMutation> {
    const statement = `mutation UpdateCustomerTerms($input: UpdateCustomerTermsInput!, $condition: ModelCustomerTermsConditionInput) {
        updateCustomerTerms(input: $input, condition: $condition) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerTermsMutation>response.data.updateCustomerTerms;
  }
  async DeleteCustomerTerms(
    input: DeleteCustomerTermsInput,
    condition?: ModelCustomerTermsConditionInput
  ): Promise<DeleteCustomerTermsMutation> {
    const statement = `mutation DeleteCustomerTerms($input: DeleteCustomerTermsInput!, $condition: ModelCustomerTermsConditionInput) {
        deleteCustomerTerms(input: $input, condition: $condition) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerTermsMutation>response.data.deleteCustomerTerms;
  }
  async CreateCustomerGroup(
    input: CreateCustomerGroupInput,
    condition?: ModelCustomerGroupConditionInput
  ): Promise<CreateCustomerGroupMutation> {
    const statement = `mutation CreateCustomerGroup($input: CreateCustomerGroupInput!, $condition: ModelCustomerGroupConditionInput) {
        createCustomerGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerGroupMutation>response.data.createCustomerGroup;
  }
  async UpdateCustomerGroup(
    input: UpdateCustomerGroupInput,
    condition?: ModelCustomerGroupConditionInput
  ): Promise<UpdateCustomerGroupMutation> {
    const statement = `mutation UpdateCustomerGroup($input: UpdateCustomerGroupInput!, $condition: ModelCustomerGroupConditionInput) {
        updateCustomerGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerGroupMutation>response.data.updateCustomerGroup;
  }
  async DeleteCustomerGroup(
    input: DeleteCustomerGroupInput,
    condition?: ModelCustomerGroupConditionInput
  ): Promise<DeleteCustomerGroupMutation> {
    const statement = `mutation DeleteCustomerGroup($input: DeleteCustomerGroupInput!, $condition: ModelCustomerGroupConditionInput) {
        deleteCustomerGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerGroupMutation>response.data.deleteCustomerGroup;
  }
  async CreateAddress(
    input: CreateAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<CreateAddressMutation> {
    const statement = `mutation CreateAddress($input: CreateAddressInput!, $condition: ModelAddressConditionInput) {
        createAddress(input: $input, condition: $condition) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAddressMutation>response.data.createAddress;
  }
  async UpdateAddress(
    input: UpdateAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<UpdateAddressMutation> {
    const statement = `mutation UpdateAddress($input: UpdateAddressInput!, $condition: ModelAddressConditionInput) {
        updateAddress(input: $input, condition: $condition) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAddressMutation>response.data.updateAddress;
  }
  async DeleteAddress(
    input: DeleteAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<DeleteAddressMutation> {
    const statement = `mutation DeleteAddress($input: DeleteAddressInput!, $condition: ModelAddressConditionInput) {
        deleteAddress(input: $input, condition: $condition) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAddressMutation>response.data.deleteAddress;
  }
  async CreateCampaign(
    input: CreateCampaignInput,
    condition?: ModelCampaignConditionInput
  ): Promise<CreateCampaignMutation> {
    const statement = `mutation CreateCampaign($input: CreateCampaignInput!, $condition: ModelCampaignConditionInput) {
        createCampaign(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCampaignMutation>response.data.createCampaign;
  }
  async UpdateCampaign(
    input: UpdateCampaignInput,
    condition?: ModelCampaignConditionInput
  ): Promise<UpdateCampaignMutation> {
    const statement = `mutation UpdateCampaign($input: UpdateCampaignInput!, $condition: ModelCampaignConditionInput) {
        updateCampaign(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCampaignMutation>response.data.updateCampaign;
  }
  async DeleteCampaign(
    input: DeleteCampaignInput,
    condition?: ModelCampaignConditionInput
  ): Promise<DeleteCampaignMutation> {
    const statement = `mutation DeleteCampaign($input: DeleteCampaignInput!, $condition: ModelCampaignConditionInput) {
        deleteCampaign(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCampaignMutation>response.data.deleteCampaign;
  }
  async CreateCampaignEmails(
    input: CreateCampaignEmailsInput,
    condition?: ModelCampaignEmailsConditionInput
  ): Promise<CreateCampaignEmailsMutation> {
    const statement = `mutation CreateCampaignEmails($input: CreateCampaignEmailsInput!, $condition: ModelCampaignEmailsConditionInput) {
        createCampaignEmails(input: $input, condition: $condition) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCampaignEmailsMutation>response.data.createCampaignEmails;
  }
  async UpdateCampaignEmails(
    input: UpdateCampaignEmailsInput,
    condition?: ModelCampaignEmailsConditionInput
  ): Promise<UpdateCampaignEmailsMutation> {
    const statement = `mutation UpdateCampaignEmails($input: UpdateCampaignEmailsInput!, $condition: ModelCampaignEmailsConditionInput) {
        updateCampaignEmails(input: $input, condition: $condition) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCampaignEmailsMutation>response.data.updateCampaignEmails;
  }
  async DeleteCampaignEmails(
    input: DeleteCampaignEmailsInput,
    condition?: ModelCampaignEmailsConditionInput
  ): Promise<DeleteCampaignEmailsMutation> {
    const statement = `mutation DeleteCampaignEmails($input: DeleteCampaignEmailsInput!, $condition: ModelCampaignEmailsConditionInput) {
        deleteCampaignEmails(input: $input, condition: $condition) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCampaignEmailsMutation>response.data.deleteCampaignEmails;
  }
  async CreateOrder(
    input: CreateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<CreateOrderMutation> {
    const statement = `mutation CreateOrder($input: CreateOrderInput!, $condition: ModelOrderConditionInput) {
        createOrder(input: $input, condition: $condition) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderMutation>response.data.createOrder;
  }
  async UpdateOrder(
    input: UpdateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<UpdateOrderMutation> {
    const statement = `mutation UpdateOrder($input: UpdateOrderInput!, $condition: ModelOrderConditionInput) {
        updateOrder(input: $input, condition: $condition) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderMutation>response.data.updateOrder;
  }
  async DeleteOrder(
    input: DeleteOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<DeleteOrderMutation> {
    const statement = `mutation DeleteOrder($input: DeleteOrderInput!, $condition: ModelOrderConditionInput) {
        deleteOrder(input: $input, condition: $condition) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderMutation>response.data.deleteOrder;
  }
  async CreatePayment(
    input: CreatePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<CreatePaymentMutation> {
    const statement = `mutation CreatePayment($input: CreatePaymentInput!, $condition: ModelPaymentConditionInput) {
        createPayment(input: $input, condition: $condition) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePaymentMutation>response.data.createPayment;
  }
  async UpdatePayment(
    input: UpdatePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<UpdatePaymentMutation> {
    const statement = `mutation UpdatePayment($input: UpdatePaymentInput!, $condition: ModelPaymentConditionInput) {
        updatePayment(input: $input, condition: $condition) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePaymentMutation>response.data.updatePayment;
  }
  async DeletePayment(
    input: DeletePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<DeletePaymentMutation> {
    const statement = `mutation DeletePayment($input: DeletePaymentInput!, $condition: ModelPaymentConditionInput) {
        deletePayment(input: $input, condition: $condition) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePaymentMutation>response.data.deletePayment;
  }
  async CreatePaymentType(
    input: CreatePaymentTypeInput,
    condition?: ModelPaymentTypeConditionInput
  ): Promise<CreatePaymentTypeMutation> {
    const statement = `mutation CreatePaymentType($input: CreatePaymentTypeInput!, $condition: ModelPaymentTypeConditionInput) {
        createPaymentType(input: $input, condition: $condition) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePaymentTypeMutation>response.data.createPaymentType;
  }
  async UpdatePaymentType(
    input: UpdatePaymentTypeInput,
    condition?: ModelPaymentTypeConditionInput
  ): Promise<UpdatePaymentTypeMutation> {
    const statement = `mutation UpdatePaymentType($input: UpdatePaymentTypeInput!, $condition: ModelPaymentTypeConditionInput) {
        updatePaymentType(input: $input, condition: $condition) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePaymentTypeMutation>response.data.updatePaymentType;
  }
  async DeletePaymentType(
    input: DeletePaymentTypeInput,
    condition?: ModelPaymentTypeConditionInput
  ): Promise<DeletePaymentTypeMutation> {
    const statement = `mutation DeletePaymentType($input: DeletePaymentTypeInput!, $condition: ModelPaymentTypeConditionInput) {
        deletePaymentType(input: $input, condition: $condition) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePaymentTypeMutation>response.data.deletePaymentType;
  }
  async CreateOrderItem(
    input: CreateOrderItemInput,
    condition?: ModelOrderItemConditionInput
  ): Promise<CreateOrderItemMutation> {
    const statement = `mutation CreateOrderItem($input: CreateOrderItemInput!, $condition: ModelOrderItemConditionInput) {
        createOrderItem(input: $input, condition: $condition) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderItemMutation>response.data.createOrderItem;
  }
  async UpdateOrderItem(
    input: UpdateOrderItemInput,
    condition?: ModelOrderItemConditionInput
  ): Promise<UpdateOrderItemMutation> {
    const statement = `mutation UpdateOrderItem($input: UpdateOrderItemInput!, $condition: ModelOrderItemConditionInput) {
        updateOrderItem(input: $input, condition: $condition) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderItemMutation>response.data.updateOrderItem;
  }
  async DeleteOrderItem(
    input: DeleteOrderItemInput,
    condition?: ModelOrderItemConditionInput
  ): Promise<DeleteOrderItemMutation> {
    const statement = `mutation DeleteOrderItem($input: DeleteOrderItemInput!, $condition: ModelOrderItemConditionInput) {
        deleteOrderItem(input: $input, condition: $condition) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderItemMutation>response.data.deleteOrderItem;
  }
  async CreateProduct(
    input: CreateProductInput,
    condition?: ModelProductConditionInput
  ): Promise<CreateProductMutation> {
    const statement = `mutation CreateProduct($input: CreateProductInput!, $condition: ModelProductConditionInput) {
        createProduct(input: $input, condition: $condition) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductMutation>response.data.createProduct;
  }
  async UpdateProduct(
    input: UpdateProductInput,
    condition?: ModelProductConditionInput
  ): Promise<UpdateProductMutation> {
    const statement = `mutation UpdateProduct($input: UpdateProductInput!, $condition: ModelProductConditionInput) {
        updateProduct(input: $input, condition: $condition) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductMutation>response.data.updateProduct;
  }
  async DeleteProduct(
    input: DeleteProductInput,
    condition?: ModelProductConditionInput
  ): Promise<DeleteProductMutation> {
    const statement = `mutation DeleteProduct($input: DeleteProductInput!, $condition: ModelProductConditionInput) {
        deleteProduct(input: $input, condition: $condition) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductMutation>response.data.deleteProduct;
  }
  async CreateProductGroup(
    input: CreateProductGroupInput,
    condition?: ModelProductGroupConditionInput
  ): Promise<CreateProductGroupMutation> {
    const statement = `mutation CreateProductGroup($input: CreateProductGroupInput!, $condition: ModelProductGroupConditionInput) {
        createProductGroup(input: $input, condition: $condition) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductGroupMutation>response.data.createProductGroup;
  }
  async UpdateProductGroup(
    input: UpdateProductGroupInput,
    condition?: ModelProductGroupConditionInput
  ): Promise<UpdateProductGroupMutation> {
    const statement = `mutation UpdateProductGroup($input: UpdateProductGroupInput!, $condition: ModelProductGroupConditionInput) {
        updateProductGroup(input: $input, condition: $condition) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductGroupMutation>response.data.updateProductGroup;
  }
  async DeleteProductGroup(
    input: DeleteProductGroupInput,
    condition?: ModelProductGroupConditionInput
  ): Promise<DeleteProductGroupMutation> {
    const statement = `mutation DeleteProductGroup($input: DeleteProductGroupInput!, $condition: ModelProductGroupConditionInput) {
        deleteProductGroup(input: $input, condition: $condition) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductGroupMutation>response.data.deleteProductGroup;
  }
  async CreateAttributes(
    input: CreateAttributesInput,
    condition?: ModelAttributesConditionInput
  ): Promise<CreateAttributesMutation> {
    const statement = `mutation CreateAttributes($input: CreateAttributesInput!, $condition: ModelAttributesConditionInput) {
        createAttributes(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAttributesMutation>response.data.createAttributes;
  }
  async UpdateAttributes(
    input: UpdateAttributesInput,
    condition?: ModelAttributesConditionInput
  ): Promise<UpdateAttributesMutation> {
    const statement = `mutation UpdateAttributes($input: UpdateAttributesInput!, $condition: ModelAttributesConditionInput) {
        updateAttributes(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAttributesMutation>response.data.updateAttributes;
  }
  async DeleteAttributes(
    input: DeleteAttributesInput,
    condition?: ModelAttributesConditionInput
  ): Promise<DeleteAttributesMutation> {
    const statement = `mutation DeleteAttributes($input: DeleteAttributesInput!, $condition: ModelAttributesConditionInput) {
        deleteAttributes(input: $input, condition: $condition) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAttributesMutation>response.data.deleteAttributes;
  }
  async CreateInputAttribute(
    input: CreateInputAttributeInput,
    condition?: ModelInputAttributeConditionInput
  ): Promise<CreateInputAttributeMutation> {
    const statement = `mutation CreateInputAttribute($input: CreateInputAttributeInput!, $condition: ModelInputAttributeConditionInput) {
        createInputAttribute(input: $input, condition: $condition) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInputAttributeMutation>response.data.createInputAttribute;
  }
  async UpdateInputAttribute(
    input: UpdateInputAttributeInput,
    condition?: ModelInputAttributeConditionInput
  ): Promise<UpdateInputAttributeMutation> {
    const statement = `mutation UpdateInputAttribute($input: UpdateInputAttributeInput!, $condition: ModelInputAttributeConditionInput) {
        updateInputAttribute(input: $input, condition: $condition) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInputAttributeMutation>response.data.updateInputAttribute;
  }
  async DeleteInputAttribute(
    input: DeleteInputAttributeInput,
    condition?: ModelInputAttributeConditionInput
  ): Promise<DeleteInputAttributeMutation> {
    const statement = `mutation DeleteInputAttribute($input: DeleteInputAttributeInput!, $condition: ModelInputAttributeConditionInput) {
        deleteInputAttribute(input: $input, condition: $condition) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInputAttributeMutation>response.data.deleteInputAttribute;
  }
  async CreatePriceAttribute(
    input: CreatePriceAttributeInput,
    condition?: ModelPriceAttributeConditionInput
  ): Promise<CreatePriceAttributeMutation> {
    const statement = `mutation CreatePriceAttribute($input: CreatePriceAttributeInput!, $condition: ModelPriceAttributeConditionInput) {
        createPriceAttribute(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePriceAttributeMutation>response.data.createPriceAttribute;
  }
  async UpdatePriceAttribute(
    input: UpdatePriceAttributeInput,
    condition?: ModelPriceAttributeConditionInput
  ): Promise<UpdatePriceAttributeMutation> {
    const statement = `mutation UpdatePriceAttribute($input: UpdatePriceAttributeInput!, $condition: ModelPriceAttributeConditionInput) {
        updatePriceAttribute(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePriceAttributeMutation>response.data.updatePriceAttribute;
  }
  async DeletePriceAttribute(
    input: DeletePriceAttributeInput,
    condition?: ModelPriceAttributeConditionInput
  ): Promise<DeletePriceAttributeMutation> {
    const statement = `mutation DeletePriceAttribute($input: DeletePriceAttributeInput!, $condition: ModelPriceAttributeConditionInput) {
        deletePriceAttribute(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePriceAttributeMutation>response.data.deletePriceAttribute;
  }
  async CreateTag(
    input: CreateTagInput,
    condition?: ModelTagConditionInput
  ): Promise<CreateTagMutation> {
    const statement = `mutation CreateTag($input: CreateTagInput!, $condition: ModelTagConditionInput) {
        createTag(input: $input, condition: $condition) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTagMutation>response.data.createTag;
  }
  async UpdateTag(
    input: UpdateTagInput,
    condition?: ModelTagConditionInput
  ): Promise<UpdateTagMutation> {
    const statement = `mutation UpdateTag($input: UpdateTagInput!, $condition: ModelTagConditionInput) {
        updateTag(input: $input, condition: $condition) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTagMutation>response.data.updateTag;
  }
  async DeleteTag(
    input: DeleteTagInput,
    condition?: ModelTagConditionInput
  ): Promise<DeleteTagMutation> {
    const statement = `mutation DeleteTag($input: DeleteTagInput!, $condition: ModelTagConditionInput) {
        deleteTag(input: $input, condition: $condition) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTagMutation>response.data.deleteTag;
  }
  async CreateOffer(
    input: CreateOfferInput,
    condition?: ModelOfferConditionInput
  ): Promise<CreateOfferMutation> {
    const statement = `mutation CreateOffer($input: CreateOfferInput!, $condition: ModelOfferConditionInput) {
        createOffer(input: $input, condition: $condition) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOfferMutation>response.data.createOffer;
  }
  async UpdateOffer(
    input: UpdateOfferInput,
    condition?: ModelOfferConditionInput
  ): Promise<UpdateOfferMutation> {
    const statement = `mutation UpdateOffer($input: UpdateOfferInput!, $condition: ModelOfferConditionInput) {
        updateOffer(input: $input, condition: $condition) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOfferMutation>response.data.updateOffer;
  }
  async DeleteOffer(
    input: DeleteOfferInput,
    condition?: ModelOfferConditionInput
  ): Promise<DeleteOfferMutation> {
    const statement = `mutation DeleteOffer($input: DeleteOfferInput!, $condition: ModelOfferConditionInput) {
        deleteOffer(input: $input, condition: $condition) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOfferMutation>response.data.deleteOffer;
  }
  async CreateReview(
    input: CreateReviewInput,
    condition?: ModelReviewConditionInput
  ): Promise<CreateReviewMutation> {
    const statement = `mutation CreateReview($input: CreateReviewInput!, $condition: ModelReviewConditionInput) {
        createReview(input: $input, condition: $condition) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateReviewMutation>response.data.createReview;
  }
  async UpdateReview(
    input: UpdateReviewInput,
    condition?: ModelReviewConditionInput
  ): Promise<UpdateReviewMutation> {
    const statement = `mutation UpdateReview($input: UpdateReviewInput!, $condition: ModelReviewConditionInput) {
        updateReview(input: $input, condition: $condition) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateReviewMutation>response.data.updateReview;
  }
  async DeleteReview(
    input: DeleteReviewInput,
    condition?: ModelReviewConditionInput
  ): Promise<DeleteReviewMutation> {
    const statement = `mutation DeleteReview($input: DeleteReviewInput!, $condition: ModelReviewConditionInput) {
        deleteReview(input: $input, condition: $condition) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteReviewMutation>response.data.deleteReview;
  }
  async CreateDocument(
    input: CreateDocumentInput,
    condition?: ModelDocumentConditionInput
  ): Promise<CreateDocumentMutation> {
    const statement = `mutation CreateDocument($input: CreateDocumentInput!, $condition: ModelDocumentConditionInput) {
        createDocument(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDocumentMutation>response.data.createDocument;
  }
  async UpdateDocument(
    input: UpdateDocumentInput,
    condition?: ModelDocumentConditionInput
  ): Promise<UpdateDocumentMutation> {
    const statement = `mutation UpdateDocument($input: UpdateDocumentInput!, $condition: ModelDocumentConditionInput) {
        updateDocument(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDocumentMutation>response.data.updateDocument;
  }
  async DeleteDocument(
    input: DeleteDocumentInput,
    condition?: ModelDocumentConditionInput
  ): Promise<DeleteDocumentMutation> {
    const statement = `mutation DeleteDocument($input: DeleteDocumentInput!, $condition: ModelDocumentConditionInput) {
        deleteDocument(input: $input, condition: $condition) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDocumentMutation>response.data.deleteDocument;
  }
  async CreateSupplier(
    input: CreateSupplierInput,
    condition?: ModelSupplierConditionInput
  ): Promise<CreateSupplierMutation> {
    const statement = `mutation CreateSupplier($input: CreateSupplierInput!, $condition: ModelSupplierConditionInput) {
        createSupplier(input: $input, condition: $condition) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSupplierMutation>response.data.createSupplier;
  }
  async UpdateSupplier(
    input: UpdateSupplierInput,
    condition?: ModelSupplierConditionInput
  ): Promise<UpdateSupplierMutation> {
    const statement = `mutation UpdateSupplier($input: UpdateSupplierInput!, $condition: ModelSupplierConditionInput) {
        updateSupplier(input: $input, condition: $condition) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSupplierMutation>response.data.updateSupplier;
  }
  async DeleteSupplier(
    input: DeleteSupplierInput,
    condition?: ModelSupplierConditionInput
  ): Promise<DeleteSupplierMutation> {
    const statement = `mutation DeleteSupplier($input: DeleteSupplierInput!, $condition: ModelSupplierConditionInput) {
        deleteSupplier(input: $input, condition: $condition) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSupplierMutation>response.data.deleteSupplier;
  }
  async CreateProductTags(
    input: CreateProductTagsInput,
    condition?: ModelProductTagsConditionInput
  ): Promise<CreateProductTagsMutation> {
    const statement = `mutation CreateProductTags($input: CreateProductTagsInput!, $condition: ModelProductTagsConditionInput) {
        createProductTags(input: $input, condition: $condition) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductTagsMutation>response.data.createProductTags;
  }
  async UpdateProductTags(
    input: UpdateProductTagsInput,
    condition?: ModelProductTagsConditionInput
  ): Promise<UpdateProductTagsMutation> {
    const statement = `mutation UpdateProductTags($input: UpdateProductTagsInput!, $condition: ModelProductTagsConditionInput) {
        updateProductTags(input: $input, condition: $condition) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductTagsMutation>response.data.updateProductTags;
  }
  async DeleteProductTags(
    input: DeleteProductTagsInput,
    condition?: ModelProductTagsConditionInput
  ): Promise<DeleteProductTagsMutation> {
    const statement = `mutation DeleteProductTags($input: DeleteProductTagsInput!, $condition: ModelProductTagsConditionInput) {
        deleteProductTags(input: $input, condition: $condition) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductTagsMutation>response.data.deleteProductTags;
  }
  async CreateProductOffers(
    input: CreateProductOffersInput,
    condition?: ModelProductOffersConditionInput
  ): Promise<CreateProductOffersMutation> {
    const statement = `mutation CreateProductOffers($input: CreateProductOffersInput!, $condition: ModelProductOffersConditionInput) {
        createProductOffers(input: $input, condition: $condition) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductOffersMutation>response.data.createProductOffers;
  }
  async UpdateProductOffers(
    input: UpdateProductOffersInput,
    condition?: ModelProductOffersConditionInput
  ): Promise<UpdateProductOffersMutation> {
    const statement = `mutation UpdateProductOffers($input: UpdateProductOffersInput!, $condition: ModelProductOffersConditionInput) {
        updateProductOffers(input: $input, condition: $condition) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductOffersMutation>response.data.updateProductOffers;
  }
  async DeleteProductOffers(
    input: DeleteProductOffersInput,
    condition?: ModelProductOffersConditionInput
  ): Promise<DeleteProductOffersMutation> {
    const statement = `mutation DeleteProductOffers($input: DeleteProductOffersInput!, $condition: ModelProductOffersConditionInput) {
        deleteProductOffers(input: $input, condition: $condition) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductOffersMutation>response.data.deleteProductOffers;
  }
  async GetCWS(id: string): Promise<GetCWSQuery> {
    const statement = `query GetCWS($id: ID!) {
        getCWS(id: $id) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCWSQuery>response.data.getCWS;
  }
  async ListCWS(
    filter?: ModelCWSFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCWSQuery> {
    const statement = `query ListCWS($filter: ModelCWSFilterInput, $limit: Int, $nextToken: String) {
        listCWS(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            defaultFrontEndConfig
            schemaFrontEndConfig
            defaultBlogConfig
            schemaBlogConfig
            defaultThemeConfig
            schemaThemeConfig
            defaultCustomerInputConfig
            schemaCustomerInputConfig
            defaultWorkFlow
            schemaWorkFlow
            defaultSiteData
            schemaSiteData
            defaultReportConfig
            schemaReportConfig
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCWSQuery>response.data.listCWS;
  }
  async GetClient(id: string): Promise<GetClientQuery> {
    const statement = `query GetClient($id: ID!) {
        getClient(id: $id) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetClientQuery>response.data.getClient;
  }
  async ListClients(
    filter?: ModelClientFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListClientsQuery> {
    const statement = `query ListClients($filter: ModelClientFilterInput, $limit: Int, $nextToken: String) {
        listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListClientsQuery>response.data.listClients;
  }
  async GetUserGroup(id: string): Promise<GetUserGroupQuery> {
    const statement = `query GetUserGroup($id: ID!) {
        getUserGroup(id: $id) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserGroupQuery>response.data.getUserGroup;
  }
  async ListUserGroups(
    filter?: ModelUserGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserGroupsQuery> {
    const statement = `query ListUserGroups($filter: ModelUserGroupFilterInput, $limit: Int, $nextToken: String) {
        listUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            groupName
            description
            permissions {
              __typename
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserGroupsQuery>response.data.listUserGroups;
  }
  async GetGroupPermission(id: string): Promise<GetGroupPermissionQuery> {
    const statement = `query GetGroupPermission($id: ID!) {
        getGroupPermission(id: $id) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetGroupPermissionQuery>response.data.getGroupPermission;
  }
  async ListGroupPermissions(
    filter?: ModelGroupPermissionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListGroupPermissionsQuery> {
    const statement = `query ListGroupPermissions($filter: ModelGroupPermissionFilterInput, $limit: Int, $nextToken: String) {
        listGroupPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListGroupPermissionsQuery>response.data.listGroupPermissions;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            permissionStatus
            title
            userName
            firstName
            lastName
            userStatus
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetCustomer(id: string): Promise<GetCustomerQuery> {
    const statement = `query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerQuery>response.data.getCustomer;
  }
  async ListCustomers(
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomersQuery> {
    const statement = `query ListCustomers($filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersQuery>response.data.listCustomers;
  }
  async GetCustomerTerms(id: string): Promise<GetCustomerTermsQuery> {
    const statement = `query GetCustomerTerms($id: ID!) {
        getCustomerTerms(id: $id) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerTermsQuery>response.data.getCustomerTerms;
  }
  async ListCustomerTerms(
    filter?: ModelCustomerTermsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerTermsQuery> {
    const statement = `query ListCustomerTerms($filter: ModelCustomerTermsFilterInput, $limit: Int, $nextToken: String) {
        listCustomerTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerTermsQuery>response.data.listCustomerTerms;
  }
  async GetCustomerGroup(id: string): Promise<GetCustomerGroupQuery> {
    const statement = `query GetCustomerGroup($id: ID!) {
        getCustomerGroup(id: $id) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerGroupQuery>response.data.getCustomerGroup;
  }
  async ListCustomerGroups(
    filter?: ModelCustomerGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerGroupsQuery> {
    const statement = `query ListCustomerGroups($filter: ModelCustomerGroupFilterInput, $limit: Int, $nextToken: String) {
        listCustomerGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            description
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerGroupsQuery>response.data.listCustomerGroups;
  }
  async GetAddress(id: string): Promise<GetAddressQuery> {
    const statement = `query GetAddress($id: ID!) {
        getAddress(id: $id) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAddressQuery>response.data.getAddress;
  }
  async ListAddresses(
    filter?: ModelAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAddressesQuery> {
    const statement = `query ListAddresses($filter: ModelAddressFilterInput, $limit: Int, $nextToken: String) {
        listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAddressesQuery>response.data.listAddresses;
  }
  async GetCampaign(id: string): Promise<GetCampaignQuery> {
    const statement = `query GetCampaign($id: ID!) {
        getCampaign(id: $id) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCampaignQuery>response.data.getCampaign;
  }
  async ListCampaigns(
    filter?: ModelCampaignFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCampaignsQuery> {
    const statement = `query ListCampaigns($filter: ModelCampaignFilterInput, $limit: Int, $nextToken: String) {
        listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            description
            emailCampaignStatus
            emailCampaignType
            emailCampaignSendType
            emailCampaignSendDate
            emailCampaignSendTime
            emailCampaignSendFrom
            emailCampaignTemplate
            emailCampaignSendAttachments
            emailCampaignSendNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCampaignsQuery>response.data.listCampaigns;
  }
  async GetCampaignEmails(id: string): Promise<GetCampaignEmailsQuery> {
    const statement = `query GetCampaignEmails($id: ID!) {
        getCampaignEmails(id: $id) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCampaignEmailsQuery>response.data.getCampaignEmails;
  }
  async ListCampaignEmails(
    filter?: ModelCampaignEmailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCampaignEmailsQuery> {
    const statement = `query ListCampaignEmails($filter: ModelCampaignEmailsFilterInput, $limit: Int, $nextToken: String) {
        listCampaignEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            campaignId
            customerID
            emailTo
            template
            templateData
            emailStatus
            emailSentDate
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCampaignEmailsQuery>response.data.listCampaignEmails;
  }
  async GetOrder(id: string): Promise<GetOrderQuery> {
    const statement = `query GetOrder($id: ID!) {
        getOrder(id: $id) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderQuery>response.data.getOrder;
  }
  async ListOrders(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrdersQuery> {
    const statement = `query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersQuery>response.data.listOrders;
  }
  async GetPayment(id: string): Promise<GetPaymentQuery> {
    const statement = `query GetPayment($id: ID!) {
        getPayment(id: $id) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPaymentQuery>response.data.getPayment;
  }
  async ListPayments(
    filter?: ModelPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPaymentsQuery> {
    const statement = `query ListPayments($filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String) {
        listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orderID
            order {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            amount
            notes
            paymentTypeID
            paymentType {
              __typename
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPaymentsQuery>response.data.listPayments;
  }
  async GetPaymentType(id: string): Promise<GetPaymentTypeQuery> {
    const statement = `query GetPaymentType($id: ID!) {
        getPaymentType(id: $id) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPaymentTypeQuery>response.data.getPaymentType;
  }
  async ListPaymentTypes(
    filter?: ModelPaymentTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPaymentTypesQuery> {
    const statement = `query ListPaymentTypes($filter: ModelPaymentTypeFilterInput, $limit: Int, $nextToken: String) {
        listPaymentTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPaymentTypesQuery>response.data.listPaymentTypes;
  }
  async GetOrderItem(id: string): Promise<GetOrderItemQuery> {
    const statement = `query GetOrderItem($id: ID!) {
        getOrderItem(id: $id) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderItemQuery>response.data.getOrderItem;
  }
  async ListOrderItems(
    filter?: ModelOrderItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrderItemsQuery> {
    const statement = `query ListOrderItems($filter: ModelOrderItemFilterInput, $limit: Int, $nextToken: String) {
        listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            fullPrice
            discount
            tax
            price
            quantity
            customerInputs
            orderItemNotes
            group
            orderID
            order {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderItemsQuery>response.data.listOrderItems;
  }
  async GetProduct(id: string): Promise<GetProductQuery> {
    const statement = `query GetProduct($id: ID!) {
        getProduct(id: $id) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductQuery>response.data.getProduct;
  }
  async ListProducts(
    filter?: ModelProductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductsQuery> {
    const statement = `query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductsQuery>response.data.listProducts;
  }
  async GetProductGroup(id: string): Promise<GetProductGroupQuery> {
    const statement = `query GetProductGroup($id: ID!) {
        getProductGroup(id: $id) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductGroupQuery>response.data.getProductGroup;
  }
  async ListProductGroups(
    filter?: ModelProductGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductGroupsQuery> {
    const statement = `query ListProductGroups($filter: ModelProductGroupFilterInput, $limit: Int, $nextToken: String) {
        listProductGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            products
            tags
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            tagID
            tag {
              __typename
              id
              name
              area
              product {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductGroupsQuery>response.data.listProductGroups;
  }
  async GetAttributes(id: string): Promise<GetAttributesQuery> {
    const statement = `query GetAttributes($id: ID!) {
        getAttributes(id: $id) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAttributesQuery>response.data.getAttributes;
  }
  async ListAttributes(
    filter?: ModelAttributesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAttributesQuery> {
    const statement = `query ListAttributes($filter: ModelAttributesFilterInput, $limit: Int, $nextToken: String) {
        listAttributes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            description
            type
            inputType
            validationTypes
            priceType
            associatedAttribute
            secondaryAssociatedAttribute
            basePrice
            notes
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAttributesQuery>response.data.listAttributes;
  }
  async GetInputAttribute(id: string): Promise<GetInputAttributeQuery> {
    const statement = `query GetInputAttribute($id: ID!) {
        getInputAttribute(id: $id) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInputAttributeQuery>response.data.getInputAttribute;
  }
  async ListInputAttributes(
    filter?: ModelInputAttributeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInputAttributesQuery> {
    const statement = `query ListInputAttributes($filter: ModelInputAttributeFilterInput, $limit: Int, $nextToken: String) {
        listInputAttributes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            inputType
            validationTypes
            id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInputAttributesQuery>response.data.listInputAttributes;
  }
  async GetPriceAttribute(id: string): Promise<GetPriceAttributeQuery> {
    const statement = `query GetPriceAttribute($id: ID!) {
        getPriceAttribute(id: $id) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPriceAttributeQuery>response.data.getPriceAttribute;
  }
  async ListPriceAttributes(
    filter?: ModelPriceAttributeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPriceAttributesQuery> {
    const statement = `query ListPriceAttributes($filter: ModelPriceAttributeFilterInput, $limit: Int, $nextToken: String) {
        listPriceAttributes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPriceAttributesQuery>response.data.listPriceAttributes;
  }
  async GetTag(id: string): Promise<GetTagQuery> {
    const statement = `query GetTag($id: ID!) {
        getTag(id: $id) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTagQuery>response.data.getTag;
  }
  async ListTags(
    filter?: ModelTagFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTagsQuery> {
    const statement = `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
        listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTagsQuery>response.data.listTags;
  }
  async GetOffer(id: string): Promise<GetOfferQuery> {
    const statement = `query GetOffer($id: ID!) {
        getOffer(id: $id) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOfferQuery>response.data.getOffer;
  }
  async ListOffers(
    filter?: ModelOfferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOffersQuery> {
    const statement = `query ListOffers($filter: ModelOfferFilterInput, $limit: Int, $nextToken: String) {
        listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOffersQuery>response.data.listOffers;
  }
  async GetReview(id: string): Promise<GetReviewQuery> {
    const statement = `query GetReview($id: ID!) {
        getReview(id: $id) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReviewQuery>response.data.getReview;
  }
  async ListReviews(
    filter?: ModelReviewFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListReviewsQuery> {
    const statement = `query ListReviews($filter: ModelReviewFilterInput, $limit: Int, $nextToken: String) {
        listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            rating
            reviewComment
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReviewsQuery>response.data.listReviews;
  }
  async GetDocument(id: string): Promise<GetDocumentQuery> {
    const statement = `query GetDocument($id: ID!) {
        getDocument(id: $id) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDocumentQuery>response.data.getDocument;
  }
  async ListDocuments(
    filter?: ModelDocumentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDocumentsQuery> {
    const statement = `query ListDocuments($filter: ModelDocumentFilterInput, $limit: Int, $nextToken: String) {
        listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            description
            documentType
            mimeType
            location
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDocumentsQuery>response.data.listDocuments;
  }
  async GetSupplier(id: string): Promise<GetSupplierQuery> {
    const statement = `query GetSupplier($id: ID!) {
        getSupplier(id: $id) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSupplierQuery>response.data.getSupplier;
  }
  async ListSuppliers(
    filter?: ModelSupplierFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSuppliersQuery> {
    const statement = `query ListSuppliers($filter: ModelSupplierFilterInput, $limit: Int, $nextToken: String) {
        listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSuppliersQuery>response.data.listSuppliers;
  }
  async GetProductTags(id: string): Promise<GetProductTagsQuery> {
    const statement = `query GetProductTags($id: ID!) {
        getProductTags(id: $id) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductTagsQuery>response.data.getProductTags;
  }
  async ListProductTags(
    filter?: ModelProductTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductTagsQuery> {
    const statement = `query ListProductTags($filter: ModelProductTagsFilterInput, $limit: Int, $nextToken: String) {
        listProductTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            productID
            tagID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            tag {
              __typename
              id
              name
              area
              product {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductTagsQuery>response.data.listProductTags;
  }
  async GetProductOffers(id: string): Promise<GetProductOffersQuery> {
    const statement = `query GetProductOffers($id: ID!) {
        getProductOffers(id: $id) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductOffersQuery>response.data.getProductOffers;
  }
  async ListProductOffers(
    filter?: ModelProductOffersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductOffersQuery> {
    const statement = `query ListProductOffers($filter: ModelProductOffersFilterInput, $limit: Int, $nextToken: String) {
        listProductOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            productID
            offerID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductOffersQuery>response.data.listProductOffers;
  }
  async UsersByClient(
    clientID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByClientQuery> {
    const statement = `query UsersByClient($clientID: ID!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        usersByClient(
          clientID: $clientID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            email
            permissionStatus
            title
            userName
            firstName
            lastName
            userStatus
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UsersByClientQuery>response.data.usersByClient;
  }
  async CustomersByWebsite(
    websiteID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomersByWebsiteQuery> {
    const statement = `query CustomersByWebsite($websiteID: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customersByWebsite(
          websiteID: $websiteID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomersByWebsiteQuery>response.data.customersByWebsite;
  }
  async CustomerGroupsByCustomer(
    customerID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerGroupsByCustomerQuery> {
    const statement = `query CustomerGroupsByCustomer($customerID: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomerGroupFilterInput, $limit: Int, $nextToken: String) {
        customerGroupsByCustomer(
          customerID: $customerID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            description
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerGroupsByCustomerQuery>(
      response.data.customerGroupsByCustomer
    );
  }
  async AddressesByCustomer(
    customerID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AddressesByCustomerQuery> {
    const statement = `query AddressesByCustomer($customerID: ID!, $sortDirection: ModelSortDirection, $filter: ModelAddressFilterInput, $limit: Int, $nextToken: String) {
        addressesByCustomer(
          customerID: $customerID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddressesByCustomerQuery>response.data.addressesByCustomer;
  }
  async EmailCampaignsByWebsite(
    websiteID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCampaignFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EmailCampaignsByWebsiteQuery> {
    const statement = `query EmailCampaignsByWebsite($websiteID: ID!, $sortDirection: ModelSortDirection, $filter: ModelCampaignFilterInput, $limit: Int, $nextToken: String) {
        emailCampaignsByWebsite(
          websiteID: $websiteID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            description
            emailCampaignStatus
            emailCampaignType
            emailCampaignSendType
            emailCampaignSendDate
            emailCampaignSendTime
            emailCampaignSendFrom
            emailCampaignTemplate
            emailCampaignSendAttachments
            emailCampaignSendNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EmailCampaignsByWebsiteQuery>response.data.emailCampaignsByWebsite;
  }
  async CampaignEmailsByCampaign(
    campaignId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCampaignEmailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CampaignEmailsByCampaignQuery> {
    const statement = `query CampaignEmailsByCampaign($campaignId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCampaignEmailsFilterInput, $limit: Int, $nextToken: String) {
        campaignEmailsByCampaign(
          campaignId: $campaignId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            campaignId
            customerID
            emailTo
            template
            templateData
            emailStatus
            emailSentDate
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      campaignId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CampaignEmailsByCampaignQuery>(
      response.data.campaignEmailsByCampaign
    );
  }
  async CampaignEmailsByCustomer(
    customerID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCampaignEmailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CampaignEmailsByCustomerQuery> {
    const statement = `query CampaignEmailsByCustomer($customerID: ID!, $sortDirection: ModelSortDirection, $filter: ModelCampaignEmailsFilterInput, $limit: Int, $nextToken: String) {
        campaignEmailsByCustomer(
          customerID: $customerID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            campaignId
            customerID
            emailTo
            template
            templateData
            emailStatus
            emailSentDate
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CampaignEmailsByCustomerQuery>(
      response.data.campaignEmailsByCustomer
    );
  }
  async OrdersByCustomerByStatus(
    customerID: string,
    orderStatus?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByCustomerByStatusQuery> {
    const statement = `query OrdersByCustomerByStatus($customerID: ID!, $orderStatus: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        ordersByCustomerByStatus(
          customerID: $customerID
          orderStatus: $orderStatus
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (orderStatus) {
      gqlAPIServiceArguments.orderStatus = orderStatus;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByCustomerByStatusQuery>(
      response.data.ordersByCustomerByStatus
    );
  }
  async OrdersByCustomer(
    customerID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByCustomerQuery> {
    const statement = `query OrdersByCustomer($customerID: ID!, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        OrdersByCustomer(
          customerID: $customerID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByCustomerQuery>response.data.OrdersByCustomer;
  }
  async OrdersByCustomerByStatusByDate(
    customerID: string,
    orderStatusDateOrdered?: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByCustomerByStatusByDateQuery> {
    const statement = `query OrdersByCustomerByStatusByDate($customerID: ID!, $orderStatusDateOrdered: ModelOrderOrdersByCustomerByStatusByDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        ordersByCustomerByStatusByDate(
          customerID: $customerID
          orderStatusDateOrdered: $orderStatusDateOrdered
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (orderStatusDateOrdered) {
      gqlAPIServiceArguments.orderStatusDateOrdered = orderStatusDateOrdered;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByCustomerByStatusByDateQuery>(
      response.data.ordersByCustomerByStatusByDate
    );
  }
  async OrdersByCustomerByDate(
    customerID: string,
    dateOrdered?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByCustomerByDateQuery> {
    const statement = `query OrdersByCustomerByDate($customerID: ID!, $dateOrdered: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        ordersByCustomerByDate(
          customerID: $customerID
          dateOrdered: $dateOrdered
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (dateOrdered) {
      gqlAPIServiceArguments.dateOrdered = dateOrdered;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByCustomerByDateQuery>response.data.ordersByCustomerByDate;
  }
  async PaymentsByOrder(
    orderID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PaymentsByOrderQuery> {
    const statement = `query PaymentsByOrder($orderID: ID!, $sortDirection: ModelSortDirection, $filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String) {
        paymentsByOrder(
          orderID: $orderID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            orderID
            order {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            amount
            notes
            paymentTypeID
            paymentType {
              __typename
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      orderID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentsByOrderQuery>response.data.paymentsByOrder;
  }
  async PaymentsByOrderByType(
    orderID: string,
    paymentTypeID?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PaymentsByOrderByTypeQuery> {
    const statement = `query PaymentsByOrderByType($orderID: ID!, $paymentTypeID: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String) {
        paymentsByOrderByType(
          orderID: $orderID
          paymentTypeID: $paymentTypeID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            orderID
            order {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            amount
            notes
            paymentTypeID
            paymentType {
              __typename
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      orderID
    };
    if (paymentTypeID) {
      gqlAPIServiceArguments.paymentTypeID = paymentTypeID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentsByOrderByTypeQuery>response.data.paymentsByOrderByType;
  }
  async OrderItemsByOrder(
    orderID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrderItemsByOrderQuery> {
    const statement = `query OrderItemsByOrder($orderID: ID!, $sortDirection: ModelSortDirection, $filter: ModelOrderItemFilterInput, $limit: Int, $nextToken: String) {
        orderItemsByOrder(
          orderID: $orderID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            fullPrice
            discount
            tax
            price
            quantity
            customerInputs
            orderItemNotes
            group
            orderID
            order {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      orderID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrderItemsByOrderQuery>response.data.orderItemsByOrder;
  }
  async OrderItemsByOffer(
    offerID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrderItemsByOfferQuery> {
    const statement = `query OrderItemsByOffer($offerID: ID!, $sortDirection: ModelSortDirection, $filter: ModelOrderItemFilterInput, $limit: Int, $nextToken: String) {
        orderItemsByOffer(
          offerID: $offerID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            fullPrice
            discount
            tax
            price
            quantity
            customerInputs
            orderItemNotes
            group
            orderID
            order {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      offerID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrderItemsByOfferQuery>response.data.orderItemsByOffer;
  }
  async ProductsByWebsite(
    websiteID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductsByWebsiteQuery> {
    const statement = `query ProductsByWebsite($websiteID: ID!, $sortDirection: ModelSortDirection, $filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        productsByWebsite(
          websiteID: $websiteID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductsByWebsiteQuery>response.data.productsByWebsite;
  }
  async ProductsByOffer(
    websiteID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductsByOfferQuery> {
    const statement = `query ProductsByOffer($websiteID: ID!, $sortDirection: ModelSortDirection, $filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        productsByOffer(
          websiteID: $websiteID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductsByOfferQuery>response.data.productsByOffer;
  }
  async ProductsBySupplier(
    supplierID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductsBySupplierQuery> {
    const statement = `query ProductsBySupplier($supplierID: ID!, $sortDirection: ModelSortDirection, $filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        productsBySupplier(
          supplierID: $supplierID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      supplierID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductsBySupplierQuery>response.data.productsBySupplier;
  }
  async ProductGroupsByProduct(
    productID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductGroupsByProductQuery> {
    const statement = `query ProductGroupsByProduct($productID: ID!, $sortDirection: ModelSortDirection, $filter: ModelProductGroupFilterInput, $limit: Int, $nextToken: String) {
        productGroupsByProduct(
          productID: $productID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            products
            tags
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            tagID
            tag {
              __typename
              id
              name
              area
              product {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductGroupsByProductQuery>response.data.productGroupsByProduct;
  }
  async AttributesByProduct(
    productID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAttributesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AttributesByProductQuery> {
    const statement = `query AttributesByProduct($productID: ID!, $sortDirection: ModelSortDirection, $filter: ModelAttributesFilterInput, $limit: Int, $nextToken: String) {
        attributesByProduct(
          productID: $productID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            description
            type
            inputType
            validationTypes
            priceType
            associatedAttribute
            secondaryAssociatedAttribute
            basePrice
            notes
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AttributesByProductQuery>response.data.attributesByProduct;
  }
  async AttributesBbyProductByType(
    productID: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAttributesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AttributesBbyProductByTypeQuery> {
    const statement = `query AttributesBbyProductByType($productID: ID!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAttributesFilterInput, $limit: Int, $nextToken: String) {
        attributesBbyProductByType(
          productID: $productID
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            description
            type
            inputType
            validationTypes
            priceType
            associatedAttribute
            secondaryAssociatedAttribute
            basePrice
            notes
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productID
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AttributesBbyProductByTypeQuery>(
      response.data.attributesBbyProductByType
    );
  }
  async OffersByWebsite(
    websiteID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelOfferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OffersByWebsiteQuery> {
    const statement = `query OffersByWebsite($websiteID: ID!, $sortDirection: ModelSortDirection, $filter: ModelOfferFilterInput, $limit: Int, $nextToken: String) {
        offersByWebsite(
          websiteID: $websiteID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OffersByWebsiteQuery>response.data.offersByWebsite;
  }
  async OffersByWebsiteByStatus(
    websiteID: string,
    offerStatus?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOfferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OffersByWebsiteByStatusQuery> {
    const statement = `query OffersByWebsiteByStatus($websiteID: ID!, $offerStatus: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOfferFilterInput, $limit: Int, $nextToken: String) {
        offersByWebsiteByStatus(
          websiteID: $websiteID
          offerStatus: $offerStatus
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (offerStatus) {
      gqlAPIServiceArguments.offerStatus = offerStatus;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OffersByWebsiteByStatusQuery>response.data.offersByWebsiteByStatus;
  }
  async OffersByWebsiteByType(
    websiteID: string,
    offerType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOfferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OffersByWebsiteByTypeQuery> {
    const statement = `query OffersByWebsiteByType($websiteID: ID!, $offerType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOfferFilterInput, $limit: Int, $nextToken: String) {
        offersByWebsiteByType(
          websiteID: $websiteID
          offerType: $offerType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (offerType) {
      gqlAPIServiceArguments.offerType = offerType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OffersByWebsiteByTypeQuery>response.data.offersByWebsiteByType;
  }
  async OffersByWebsiteByStatusByType(
    websiteID: string,
    offerStatusOfferType?: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOfferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OffersByWebsiteByStatusByTypeQuery> {
    const statement = `query OffersByWebsiteByStatusByType($websiteID: ID!, $offerStatusOfferType: ModelOfferOffersByWebsiteByStatusByTypeCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOfferFilterInput, $limit: Int, $nextToken: String) {
        offersByWebsiteByStatusByType(
          websiteID: $websiteID
          offerStatusOfferType: $offerStatusOfferType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (offerStatusOfferType) {
      gqlAPIServiceArguments.offerStatusOfferType = offerStatusOfferType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OffersByWebsiteByStatusByTypeQuery>(
      response.data.offersByWebsiteByStatusByType
    );
  }
  async OffersByWebsiteByStatusByValidDate(
    websiteID: string,
    offerStatusDateValidFromDateValidTo?: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOfferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OffersByWebsiteByStatusByValidDateQuery> {
    const statement = `query OffersByWebsiteByStatusByValidDate($websiteID: ID!, $offerStatusDateValidFromDateValidTo: ModelOfferOffersByWebsiteByStatusByValidDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOfferFilterInput, $limit: Int, $nextToken: String) {
        offersByWebsiteByStatusByValidDate(
          websiteID: $websiteID
          offerStatusDateValidFromDateValidTo: $offerStatusDateValidFromDateValidTo
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (offerStatusDateValidFromDateValidTo) {
      gqlAPIServiceArguments.offerStatusDateValidFromDateValidTo = offerStatusDateValidFromDateValidTo;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OffersByWebsiteByStatusByValidDateQuery>(
      response.data.offersByWebsiteByStatusByValidDate
    );
  }
  async ReviewsByProduct(
    productID: string,
    customerID?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelReviewFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReviewsByProductQuery> {
    const statement = `query ReviewsByProduct($productID: ID!, $customerID: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelReviewFilterInput, $limit: Int, $nextToken: String) {
        reviewsByProduct(
          productID: $productID
          customerID: $customerID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            rating
            reviewComment
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productID
    };
    if (customerID) {
      gqlAPIServiceArguments.customerID = customerID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReviewsByProductQuery>response.data.reviewsByProduct;
  }
  async DocumentsByProduct(
    productID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDocumentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DocumentsByProductQuery> {
    const statement = `query DocumentsByProduct($productID: ID!, $sortDirection: ModelSortDirection, $filter: ModelDocumentFilterInput, $limit: Int, $nextToken: String) {
        documentsByProduct(
          productID: $productID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            title
            description
            documentType
            mimeType
            location
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DocumentsByProductQuery>response.data.documentsByProduct;
  }
  async DocumentsByProductByDocType(
    productID: string,
    documentType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDocumentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DocumentsByProductByDocTypeQuery> {
    const statement = `query DocumentsByProductByDocType($productID: ID!, $documentType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDocumentFilterInput, $limit: Int, $nextToken: String) {
        documentsByProductByDocType(
          productID: $productID
          documentType: $documentType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            title
            description
            documentType
            mimeType
            location
            productID
            product {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productID
    };
    if (documentType) {
      gqlAPIServiceArguments.documentType = documentType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DocumentsByProductByDocTypeQuery>(
      response.data.documentsByProductByDocType
    );
  }
  async GetWebsite(id: string): Promise<GetWebsiteQuery> {
    const statement = `query GetWebsite($id: ID!) {
        getWebsite(id: $id) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWebsiteQuery>response.data.getWebsite;
  }
  async ListWebsites(
    filter?: ModelWebsiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWebsitesQuery> {
    const statement = `query ListWebsites($filter: ModelWebsiteFilterInput, $limit: Int, $nextToken: String) {
        listWebsites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWebsitesQuery>response.data.listWebsites;
  }
  async WebsitesByClient(
    clientID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelWebsiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WebsitesByClientQuery> {
    const statement = `query WebsitesByClient($clientID: ID!, $sortDirection: ModelSortDirection, $filter: ModelWebsiteFilterInput, $limit: Int, $nextToken: String) {
        websitesByClient(
          clientID: $clientID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WebsitesByClientQuery>response.data.websitesByClient;
  }
  OnCreateCWSListener(
    filter?: ModelSubscriptionCWSFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCWS">>
  > {
    const statement = `subscription OnCreateCWS($filter: ModelSubscriptionCWSFilterInput) {
        onCreateCWS(filter: $filter) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCWS">>
    >;
  }

  OnUpdateCWSListener(
    filter?: ModelSubscriptionCWSFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCWS">>
  > {
    const statement = `subscription OnUpdateCWS($filter: ModelSubscriptionCWSFilterInput) {
        onUpdateCWS(filter: $filter) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCWS">>
    >;
  }

  OnDeleteCWSListener(
    filter?: ModelSubscriptionCWSFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCWS">>
  > {
    const statement = `subscription OnDeleteCWS($filter: ModelSubscriptionCWSFilterInput) {
        onDeleteCWS(filter: $filter) {
          __typename
          id
          name
          defaultFrontEndConfig
          schemaFrontEndConfig
          defaultBlogConfig
          schemaBlogConfig
          defaultThemeConfig
          schemaThemeConfig
          defaultCustomerInputConfig
          schemaCustomerInputConfig
          defaultWorkFlow
          schemaWorkFlow
          defaultSiteData
          schemaSiteData
          defaultReportConfig
          schemaReportConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCWS">>
    >;
  }

  OnCreateClientListener(
    filter?: ModelSubscriptionClientFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateClient">>
  > {
    const statement = `subscription OnCreateClient($filter: ModelSubscriptionClientFilterInput) {
        onCreateClient(filter: $filter) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateClient">>
    >;
  }

  OnUpdateClientListener(
    filter?: ModelSubscriptionClientFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateClient">>
  > {
    const statement = `subscription OnUpdateClient($filter: ModelSubscriptionClientFilterInput) {
        onUpdateClient(filter: $filter) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateClient">>
    >;
  }

  OnDeleteClientListener(
    filter?: ModelSubscriptionClientFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteClient">>
  > {
    const statement = `subscription OnDeleteClient($filter: ModelSubscriptionClientFilterInput) {
        onDeleteClient(filter: $filter) {
          __typename
          id
          name
          contact
          phone
          email
          clientStatus
          websites {
            __typename
            items {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            __typename
            items {
              __typename
              id
              email
              permissionStatus
              title
              userName
              firstName
              lastName
              userStatus
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteClient">>
    >;
  }

  OnCreateUserGroupListener(
    filter?: ModelSubscriptionUserGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserGroup">>
  > {
    const statement = `subscription OnCreateUserGroup($filter: ModelSubscriptionUserGroupFilterInput) {
        onCreateUserGroup(filter: $filter) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserGroup">>
    >;
  }

  OnUpdateUserGroupListener(
    filter?: ModelSubscriptionUserGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserGroup">>
  > {
    const statement = `subscription OnUpdateUserGroup($filter: ModelSubscriptionUserGroupFilterInput) {
        onUpdateUserGroup(filter: $filter) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserGroup">>
    >;
  }

  OnDeleteUserGroupListener(
    filter?: ModelSubscriptionUserGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserGroup">>
  > {
    const statement = `subscription OnDeleteUserGroup($filter: ModelSubscriptionUserGroupFilterInput) {
        onDeleteUserGroup(filter: $filter) {
          __typename
          id
          groupName
          description
          permissions {
            __typename
            id
            name
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserGroup">>
    >;
  }

  OnCreateGroupPermissionListener(
    filter?: ModelSubscriptionGroupPermissionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateGroupPermission">
    >
  > {
    const statement = `subscription OnCreateGroupPermission($filter: ModelSubscriptionGroupPermissionFilterInput) {
        onCreateGroupPermission(filter: $filter) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateGroupPermission">
      >
    >;
  }

  OnUpdateGroupPermissionListener(
    filter?: ModelSubscriptionGroupPermissionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateGroupPermission">
    >
  > {
    const statement = `subscription OnUpdateGroupPermission($filter: ModelSubscriptionGroupPermissionFilterInput) {
        onUpdateGroupPermission(filter: $filter) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateGroupPermission">
      >
    >;
  }

  OnDeleteGroupPermissionListener(
    filter?: ModelSubscriptionGroupPermissionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteGroupPermission">
    >
  > {
    const statement = `subscription OnDeleteGroupPermission($filter: ModelSubscriptionGroupPermissionFilterInput) {
        onDeleteGroupPermission(filter: $filter) {
          __typename
          id
          name
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteGroupPermission">
      >
    >;
  }

  OnCreateUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > {
    const statement = `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
        onCreateUser(filter: $filter) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
    >;
  }

  OnUpdateUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > {
    const statement = `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
        onUpdateUser(filter: $filter) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
    >;
  }

  OnDeleteUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > {
    const statement = `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
        onDeleteUser(filter: $filter) {
          __typename
          id
          email
          permissionStatus
          title
          userName
          firstName
          lastName
          userStatus
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
    >;
  }

  OnCreateCustomerListener(
    filter?: ModelSubscriptionCustomerFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomer">>
  > {
    const statement = `subscription OnCreateCustomer($filter: ModelSubscriptionCustomerFilterInput) {
        onCreateCustomer(filter: $filter) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomer">>
    >;
  }

  OnUpdateCustomerListener(
    filter?: ModelSubscriptionCustomerFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomer">>
  > {
    const statement = `subscription OnUpdateCustomer($filter: ModelSubscriptionCustomerFilterInput) {
        onUpdateCustomer(filter: $filter) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomer">>
    >;
  }

  OnDeleteCustomerListener(
    filter?: ModelSubscriptionCustomerFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomer">>
  > {
    const statement = `subscription OnDeleteCustomer($filter: ModelSubscriptionCustomerFilterInput) {
        onDeleteCustomer(filter: $filter) {
          __typename
          id
          email
          userName
          companyName
          firstName
          lastName
          phone
          phoneOther
          accountNumber
          customerStatus
          customerType
          madeToMeasureDiscount
          standardItemsDiscount
          deliveryDiscount
          orderDiscount
          customerTermsID
          customerTerms {
            __typename
            id
            terms
            days
            waitForPayment
            createdAt
            updatedAt
          }
          emailSubscribed
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addresses {
            __typename
            items {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          orders {
            __typename
            items {
              __typename
              id
              orderNo
              customerReference
              itemsTotal
              offerDiscount
              price
              discount
              delivery
              tax
              orderTotal
              orderNotes
              dateOrdered
              datePaid
              dateDelivered
              orderStatus
              group
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addressID
              address {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              orderItems {
                __typename
                items {
                  __typename
                  id
                  name
                  fullPrice
                  discount
                  tax
                  price
                  quantity
                  customerInputs
                  orderItemNotes
                  group
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              invoiceNumber
              payments {
                __typename
                items {
                  __typename
                  id
                  orderID
                  order {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  amount
                  notes
                  paymentTypeID
                  paymentType {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customerGroups {
            __typename
            items {
              __typename
              id
              name
              description
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomer">>
    >;
  }

  OnCreateCustomerTermsListener(
    filter?: ModelSubscriptionCustomerTermsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomerTerms">>
  > {
    const statement = `subscription OnCreateCustomerTerms($filter: ModelSubscriptionCustomerTermsFilterInput) {
        onCreateCustomerTerms(filter: $filter) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCustomerTerms">
      >
    >;
  }

  OnUpdateCustomerTermsListener(
    filter?: ModelSubscriptionCustomerTermsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomerTerms">>
  > {
    const statement = `subscription OnUpdateCustomerTerms($filter: ModelSubscriptionCustomerTermsFilterInput) {
        onUpdateCustomerTerms(filter: $filter) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCustomerTerms">
      >
    >;
  }

  OnDeleteCustomerTermsListener(
    filter?: ModelSubscriptionCustomerTermsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomerTerms">>
  > {
    const statement = `subscription OnDeleteCustomerTerms($filter: ModelSubscriptionCustomerTermsFilterInput) {
        onDeleteCustomerTerms(filter: $filter) {
          __typename
          id
          terms
          days
          waitForPayment
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCustomerTerms">
      >
    >;
  }

  OnCreateCustomerGroupListener(
    filter?: ModelSubscriptionCustomerGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomerGroup">>
  > {
    const statement = `subscription OnCreateCustomerGroup($filter: ModelSubscriptionCustomerGroupFilterInput) {
        onCreateCustomerGroup(filter: $filter) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCustomerGroup">
      >
    >;
  }

  OnUpdateCustomerGroupListener(
    filter?: ModelSubscriptionCustomerGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomerGroup">>
  > {
    const statement = `subscription OnUpdateCustomerGroup($filter: ModelSubscriptionCustomerGroupFilterInput) {
        onUpdateCustomerGroup(filter: $filter) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCustomerGroup">
      >
    >;
  }

  OnDeleteCustomerGroupListener(
    filter?: ModelSubscriptionCustomerGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomerGroup">>
  > {
    const statement = `subscription OnDeleteCustomerGroup($filter: ModelSubscriptionCustomerGroupFilterInput) {
        onDeleteCustomerGroup(filter: $filter) {
          __typename
          id
          name
          description
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCustomerGroup">
      >
    >;
  }

  OnCreateAddressListener(
    filter?: ModelSubscriptionAddressFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAddress">>
  > {
    const statement = `subscription OnCreateAddress($filter: ModelSubscriptionAddressFilterInput) {
        onCreateAddress(filter: $filter) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAddress">>
    >;
  }

  OnUpdateAddressListener(
    filter?: ModelSubscriptionAddressFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAddress">>
  > {
    const statement = `subscription OnUpdateAddress($filter: ModelSubscriptionAddressFilterInput) {
        onUpdateAddress(filter: $filter) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAddress">>
    >;
  }

  OnDeleteAddressListener(
    filter?: ModelSubscriptionAddressFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAddress">>
  > {
    const statement = `subscription OnDeleteAddress($filter: ModelSubscriptionAddressFilterInput) {
        onDeleteAddress(filter: $filter) {
          __typename
          id
          addressType
          addr1
          addr2
          addr3
          county
          postCode
          Country
          driverInstructions
          notes
          group
          enabled
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAddress">>
    >;
  }

  OnCreateCampaignListener(
    filter?: ModelSubscriptionCampaignFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCampaign">>
  > {
    const statement = `subscription OnCreateCampaign($filter: ModelSubscriptionCampaignFilterInput) {
        onCreateCampaign(filter: $filter) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCampaign">>
    >;
  }

  OnUpdateCampaignListener(
    filter?: ModelSubscriptionCampaignFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCampaign">>
  > {
    const statement = `subscription OnUpdateCampaign($filter: ModelSubscriptionCampaignFilterInput) {
        onUpdateCampaign(filter: $filter) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCampaign">>
    >;
  }

  OnDeleteCampaignListener(
    filter?: ModelSubscriptionCampaignFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCampaign">>
  > {
    const statement = `subscription OnDeleteCampaign($filter: ModelSubscriptionCampaignFilterInput) {
        onDeleteCampaign(filter: $filter) {
          __typename
          id
          name
          description
          emailCampaignStatus
          emailCampaignType
          emailCampaignSendType
          emailCampaignSendDate
          emailCampaignSendTime
          emailCampaignSendFrom
          emailCampaignTemplate
          emailCampaignSendAttachments
          emailCampaignSendNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCampaign">>
    >;
  }

  OnCreateCampaignEmailsListener(
    filter?: ModelSubscriptionCampaignEmailsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCampaignEmails">
    >
  > {
    const statement = `subscription OnCreateCampaignEmails($filter: ModelSubscriptionCampaignEmailsFilterInput) {
        onCreateCampaignEmails(filter: $filter) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCampaignEmails">
      >
    >;
  }

  OnUpdateCampaignEmailsListener(
    filter?: ModelSubscriptionCampaignEmailsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCampaignEmails">
    >
  > {
    const statement = `subscription OnUpdateCampaignEmails($filter: ModelSubscriptionCampaignEmailsFilterInput) {
        onUpdateCampaignEmails(filter: $filter) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCampaignEmails">
      >
    >;
  }

  OnDeleteCampaignEmailsListener(
    filter?: ModelSubscriptionCampaignEmailsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCampaignEmails">
    >
  > {
    const statement = `subscription OnDeleteCampaignEmails($filter: ModelSubscriptionCampaignEmailsFilterInput) {
        onDeleteCampaignEmails(filter: $filter) {
          __typename
          id
          campaignId
          customerID
          emailTo
          template
          templateData
          emailStatus
          emailSentDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCampaignEmails">
      >
    >;
  }

  OnCreateOrderListener(
    filter?: ModelSubscriptionOrderFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrder">>
  > {
    const statement = `subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
        onCreateOrder(filter: $filter) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrder">>
    >;
  }

  OnUpdateOrderListener(
    filter?: ModelSubscriptionOrderFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrder">>
  > {
    const statement = `subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
        onUpdateOrder(filter: $filter) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrder">>
    >;
  }

  OnDeleteOrderListener(
    filter?: ModelSubscriptionOrderFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrder">>
  > {
    const statement = `subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
        onDeleteOrder(filter: $filter) {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          offerDiscount
          price
          discount
          delivery
          tax
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            enabled
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          invoiceNumber
          payments {
            __typename
            items {
              __typename
              id
              orderID
              order {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              amount
              notes
              paymentTypeID
              paymentType {
                __typename
                id
                name
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrder">>
    >;
  }

  OnCreatePaymentListener(
    filter?: ModelSubscriptionPaymentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePayment">>
  > {
    const statement = `subscription OnCreatePayment($filter: ModelSubscriptionPaymentFilterInput) {
        onCreatePayment(filter: $filter) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePayment">>
    >;
  }

  OnUpdatePaymentListener(
    filter?: ModelSubscriptionPaymentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePayment">>
  > {
    const statement = `subscription OnUpdatePayment($filter: ModelSubscriptionPaymentFilterInput) {
        onUpdatePayment(filter: $filter) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePayment">>
    >;
  }

  OnDeletePaymentListener(
    filter?: ModelSubscriptionPaymentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePayment">>
  > {
    const statement = `subscription OnDeletePayment($filter: ModelSubscriptionPaymentFilterInput) {
        onDeletePayment(filter: $filter) {
          __typename
          id
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          amount
          notes
          paymentTypeID
          paymentType {
            __typename
            id
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePayment">>
    >;
  }

  OnCreatePaymentTypeListener(
    filter?: ModelSubscriptionPaymentTypeFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePaymentType">>
  > {
    const statement = `subscription OnCreatePaymentType($filter: ModelSubscriptionPaymentTypeFilterInput) {
        onCreatePaymentType(filter: $filter) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePaymentType">>
    >;
  }

  OnUpdatePaymentTypeListener(
    filter?: ModelSubscriptionPaymentTypeFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePaymentType">>
  > {
    const statement = `subscription OnUpdatePaymentType($filter: ModelSubscriptionPaymentTypeFilterInput) {
        onUpdatePaymentType(filter: $filter) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePaymentType">>
    >;
  }

  OnDeletePaymentTypeListener(
    filter?: ModelSubscriptionPaymentTypeFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePaymentType">>
  > {
    const statement = `subscription OnDeletePaymentType($filter: ModelSubscriptionPaymentTypeFilterInput) {
        onDeletePaymentType(filter: $filter) {
          __typename
          id
          name
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePaymentType">>
    >;
  }

  OnCreateOrderItemListener(
    filter?: ModelSubscriptionOrderItemFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrderItem">>
  > {
    const statement = `subscription OnCreateOrderItem($filter: ModelSubscriptionOrderItemFilterInput) {
        onCreateOrderItem(filter: $filter) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrderItem">>
    >;
  }

  OnUpdateOrderItemListener(
    filter?: ModelSubscriptionOrderItemFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrderItem">>
  > {
    const statement = `subscription OnUpdateOrderItem($filter: ModelSubscriptionOrderItemFilterInput) {
        onUpdateOrderItem(filter: $filter) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrderItem">>
    >;
  }

  OnDeleteOrderItemListener(
    filter?: ModelSubscriptionOrderItemFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrderItem">>
  > {
    const statement = `subscription OnDeleteOrderItem($filter: ModelSubscriptionOrderItemFilterInput) {
        onDeleteOrderItem(filter: $filter) {
          __typename
          id
          name
          fullPrice
          discount
          tax
          price
          quantity
          customerInputs
          orderItemNotes
          group
          orderID
          order {
            __typename
            id
            orderNo
            customerReference
            itemsTotal
            offerDiscount
            price
            discount
            delivery
            tax
            orderTotal
            orderNotes
            dateOrdered
            datePaid
            dateDelivered
            orderStatus
            group
            customerID
            customer {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addressID
            address {
              __typename
              id
              addressType
              addr1
              addr2
              addr3
              county
              postCode
              Country
              driverInstructions
              notes
              group
              enabled
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            orderItems {
              __typename
              items {
                __typename
                id
                name
                fullPrice
                discount
                tax
                price
                quantity
                customerInputs
                orderItemNotes
                group
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            invoiceNumber
            payments {
              __typename
              items {
                __typename
                id
                orderID
                order {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                amount
                notes
                paymentTypeID
                paymentType {
                  __typename
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrderItem">>
    >;
  }

  OnCreateProductListener(
    filter?: ModelSubscriptionProductFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProduct">>
  > {
    const statement = `subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
        onCreateProduct(filter: $filter) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProduct">>
    >;
  }

  OnUpdateProductListener(
    filter?: ModelSubscriptionProductFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProduct">>
  > {
    const statement = `subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
        onUpdateProduct(filter: $filter) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProduct">>
    >;
  }

  OnDeleteProductListener(
    filter?: ModelSubscriptionProductFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProduct">>
  > {
    const statement = `subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
        onDeleteProduct(filter: $filter) {
          __typename
          id
          sku
          name
          title
          shortDescription
          description
          delivery
          taxCode
          tax
          price
          size
          colour
          weight
          productServiceName
          productNotes
          websiteID
          website {
            __typename
            id
            name
            url
            email
            urlRegister
            websiteStatus
            dateLive
            frontEndConfig
            blogConfig
            themeConfig
            customerInputConfig
            workFlow
            siteData
            reportConfig
            clientID
            client {
              __typename
              id
              name
              contact
              phone
              email
              clientStatus
              websites {
                __typename
                items {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              users {
                __typename
                items {
                  __typename
                  id
                  email
                  permissionStatus
                  title
                  userName
                  firstName
                  lastName
                  userStatus
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customers {
              __typename
              items {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            emailCampaigns {
              __typename
              items {
                __typename
                id
                name
                description
                emailCampaignStatus
                emailCampaignType
                emailCampaignSendType
                emailCampaignSendDate
                emailCampaignSendTime
                emailCampaignSendFrom
                emailCampaignTemplate
                emailCampaignSendAttachments
                emailCampaignSendNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          supplierID
          supplier {
            __typename
            id
            name
            products {
              __typename
              items {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          productGroups {
            __typename
            items {
              __typename
              id
              products
              tags
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tagID
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            __typename
            items {
              __typename
              id
              rating
              reviewComment
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              customerID
              customer {
                __typename
                id
                email
                userName
                companyName
                firstName
                lastName
                phone
                phoneOther
                accountNumber
                customerStatus
                customerType
                madeToMeasureDiscount
                standardItemsDiscount
                deliveryDiscount
                orderDiscount
                customerTermsID
                customerTerms {
                  __typename
                  id
                  terms
                  days
                  waitForPayment
                  createdAt
                  updatedAt
                }
                emailSubscribed
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addresses {
                  __typename
                  items {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                orders {
                  __typename
                  items {
                    __typename
                    id
                    orderNo
                    customerReference
                    itemsTotal
                    offerDiscount
                    price
                    discount
                    delivery
                    tax
                    orderTotal
                    orderNotes
                    dateOrdered
                    datePaid
                    dateDelivered
                    orderStatus
                    group
                    customerID
                    addressID
                    offerID
                    invoiceNumber
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customerGroups {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          attributes {
            __typename
            items {
              __typename
              id
              name
              description
              type
              inputType
              validationTypes
              priceType
              associatedAttribute
              secondaryAssociatedAttribute
              basePrice
              notes
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          documents {
            __typename
            items {
              __typename
              id
              title
              description
              documentType
              mimeType
              location
              productID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          offers {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProduct">>
    >;
  }

  OnCreateProductGroupListener(
    filter?: ModelSubscriptionProductGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProductGroup">>
  > {
    const statement = `subscription OnCreateProductGroup($filter: ModelSubscriptionProductGroupFilterInput) {
        onCreateProductGroup(filter: $filter) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateProductGroup">
      >
    >;
  }

  OnUpdateProductGroupListener(
    filter?: ModelSubscriptionProductGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProductGroup">>
  > {
    const statement = `subscription OnUpdateProductGroup($filter: ModelSubscriptionProductGroupFilterInput) {
        onUpdateProductGroup(filter: $filter) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateProductGroup">
      >
    >;
  }

  OnDeleteProductGroupListener(
    filter?: ModelSubscriptionProductGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProductGroup">>
  > {
    const statement = `subscription OnDeleteProductGroup($filter: ModelSubscriptionProductGroupFilterInput) {
        onDeleteProductGroup(filter: $filter) {
          __typename
          id
          products
          tags
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tagID
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteProductGroup">
      >
    >;
  }

  OnCreateAttributesListener(
    filter?: ModelSubscriptionAttributesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAttributes">>
  > {
    const statement = `subscription OnCreateAttributes($filter: ModelSubscriptionAttributesFilterInput) {
        onCreateAttributes(filter: $filter) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAttributes">>
    >;
  }

  OnUpdateAttributesListener(
    filter?: ModelSubscriptionAttributesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAttributes">>
  > {
    const statement = `subscription OnUpdateAttributes($filter: ModelSubscriptionAttributesFilterInput) {
        onUpdateAttributes(filter: $filter) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAttributes">>
    >;
  }

  OnDeleteAttributesListener(
    filter?: ModelSubscriptionAttributesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAttributes">>
  > {
    const statement = `subscription OnDeleteAttributes($filter: ModelSubscriptionAttributesFilterInput) {
        onDeleteAttributes(filter: $filter) {
          __typename
          id
          name
          description
          type
          inputType
          validationTypes
          priceType
          associatedAttribute
          secondaryAssociatedAttribute
          basePrice
          notes
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAttributes">>
    >;
  }

  OnCreateInputAttributeListener(
    filter?: ModelSubscriptionInputAttributeFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInputAttribute">
    >
  > {
    const statement = `subscription OnCreateInputAttribute($filter: ModelSubscriptionInputAttributeFilterInput) {
        onCreateInputAttribute(filter: $filter) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateInputAttribute">
      >
    >;
  }

  OnUpdateInputAttributeListener(
    filter?: ModelSubscriptionInputAttributeFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInputAttribute">
    >
  > {
    const statement = `subscription OnUpdateInputAttribute($filter: ModelSubscriptionInputAttributeFilterInput) {
        onUpdateInputAttribute(filter: $filter) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateInputAttribute">
      >
    >;
  }

  OnDeleteInputAttributeListener(
    filter?: ModelSubscriptionInputAttributeFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInputAttribute">
    >
  > {
    const statement = `subscription OnDeleteInputAttribute($filter: ModelSubscriptionInputAttributeFilterInput) {
        onDeleteInputAttribute(filter: $filter) {
          __typename
          inputType
          validationTypes
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteInputAttribute">
      >
    >;
  }

  OnCreatePriceAttributeListener(
    filter?: ModelSubscriptionPriceAttributeFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreatePriceAttribute">
    >
  > {
    const statement = `subscription OnCreatePriceAttribute($filter: ModelSubscriptionPriceAttributeFilterInput) {
        onCreatePriceAttribute(filter: $filter) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreatePriceAttribute">
      >
    >;
  }

  OnUpdatePriceAttributeListener(
    filter?: ModelSubscriptionPriceAttributeFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdatePriceAttribute">
    >
  > {
    const statement = `subscription OnUpdatePriceAttribute($filter: ModelSubscriptionPriceAttributeFilterInput) {
        onUpdatePriceAttribute(filter: $filter) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdatePriceAttribute">
      >
    >;
  }

  OnDeletePriceAttributeListener(
    filter?: ModelSubscriptionPriceAttributeFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeletePriceAttribute">
    >
  > {
    const statement = `subscription OnDeletePriceAttribute($filter: ModelSubscriptionPriceAttributeFilterInput) {
        onDeletePriceAttribute(filter: $filter) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeletePriceAttribute">
      >
    >;
  }

  OnCreateTagListener(
    filter?: ModelSubscriptionTagFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTag">>
  > {
    const statement = `subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput) {
        onCreateTag(filter: $filter) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTag">>
    >;
  }

  OnUpdateTagListener(
    filter?: ModelSubscriptionTagFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTag">>
  > {
    const statement = `subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput) {
        onUpdateTag(filter: $filter) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTag">>
    >;
  }

  OnDeleteTagListener(
    filter?: ModelSubscriptionTagFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTag">>
  > {
    const statement = `subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput) {
        onDeleteTag(filter: $filter) {
          __typename
          id
          name
          area
          product {
            __typename
            items {
              __typename
              id
              productID
              tagID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              tag {
                __typename
                id
                name
                area
                product {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTag">>
    >;
  }

  OnCreateOfferListener(
    filter?: ModelSubscriptionOfferFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOffer">>
  > {
    const statement = `subscription OnCreateOffer($filter: ModelSubscriptionOfferFilterInput) {
        onCreateOffer(filter: $filter) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOffer">>
    >;
  }

  OnUpdateOfferListener(
    filter?: ModelSubscriptionOfferFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOffer">>
  > {
    const statement = `subscription OnUpdateOffer($filter: ModelSubscriptionOfferFilterInput) {
        onUpdateOffer(filter: $filter) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOffer">>
    >;
  }

  OnDeleteOfferListener(
    filter?: ModelSubscriptionOfferFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOffer">>
  > {
    const statement = `subscription OnDeleteOffer($filter: ModelSubscriptionOfferFilterInput) {
        onDeleteOffer(filter: $filter) {
          __typename
          id
          websiteID
          dateValidFrom
          dateValidTo
          offerType
          offerStatus
          discountPercentage
          discountAmount
          products {
            __typename
            items {
              __typename
              id
              productID
              offerID
              product {
                __typename
                id
                sku
                name
                title
                shortDescription
                description
                delivery
                taxCode
                tax
                price
                size
                colour
                weight
                productServiceName
                productNotes
                websiteID
                website {
                  __typename
                  id
                  name
                  url
                  email
                  urlRegister
                  websiteStatus
                  dateLive
                  frontEndConfig
                  blogConfig
                  themeConfig
                  customerInputConfig
                  workFlow
                  siteData
                  reportConfig
                  clientID
                  client {
                    __typename
                    id
                    name
                    contact
                    phone
                    email
                    clientStatus
                    createdAt
                    updatedAt
                  }
                  products {
                    __typename
                    nextToken
                  }
                  customers {
                    __typename
                    nextToken
                  }
                  emailCampaigns {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                supplierID
                supplier {
                  __typename
                  id
                  name
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                productGroups {
                  __typename
                  items {
                    __typename
                    id
                    products
                    tags
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  __typename
                  items {
                    __typename
                    id
                    rating
                    reviewComment
                    productID
                    customerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                attributes {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    type
                    inputType
                    validationTypes
                    priceType
                    associatedAttribute
                    secondaryAssociatedAttribute
                    basePrice
                    notes
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                documents {
                  __typename
                  items {
                    __typename
                    id
                    title
                    description
                    documentType
                    mimeType
                    location
                    productID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                tags {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    tagID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                offers {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOffer">>
    >;
  }

  OnCreateReviewListener(
    filter?: ModelSubscriptionReviewFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateReview">>
  > {
    const statement = `subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
        onCreateReview(filter: $filter) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateReview">>
    >;
  }

  OnUpdateReviewListener(
    filter?: ModelSubscriptionReviewFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateReview">>
  > {
    const statement = `subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
        onUpdateReview(filter: $filter) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateReview">>
    >;
  }

  OnDeleteReviewListener(
    filter?: ModelSubscriptionReviewFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteReview">>
  > {
    const statement = `subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
        onDeleteReview(filter: $filter) {
          __typename
          id
          rating
          reviewComment
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          customerID
          customer {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addresses {
              __typename
              items {
                __typename
                id
                addressType
                addr1
                addr2
                addr3
                county
                postCode
                Country
                driverInstructions
                notes
                group
                enabled
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            orders {
              __typename
              items {
                __typename
                id
                orderNo
                customerReference
                itemsTotal
                offerDiscount
                price
                discount
                delivery
                tax
                orderTotal
                orderNotes
                dateOrdered
                datePaid
                dateDelivered
                orderStatus
                group
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                addressID
                address {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                offerID
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                orderItems {
                  __typename
                  items {
                    __typename
                    id
                    name
                    fullPrice
                    discount
                    tax
                    price
                    quantity
                    customerInputs
                    orderItemNotes
                    group
                    orderID
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                invoiceNumber
                payments {
                  __typename
                  items {
                    __typename
                    id
                    orderID
                    amount
                    notes
                    paymentTypeID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            customerGroups {
              __typename
              items {
                __typename
                id
                name
                description
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteReview">>
    >;
  }

  OnCreateDocumentListener(
    filter?: ModelSubscriptionDocumentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDocument">>
  > {
    const statement = `subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
        onCreateDocument(filter: $filter) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDocument">>
    >;
  }

  OnUpdateDocumentListener(
    filter?: ModelSubscriptionDocumentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDocument">>
  > {
    const statement = `subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
        onUpdateDocument(filter: $filter) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDocument">>
    >;
  }

  OnDeleteDocumentListener(
    filter?: ModelSubscriptionDocumentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDocument">>
  > {
    const statement = `subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
        onDeleteDocument(filter: $filter) {
          __typename
          id
          title
          description
          documentType
          mimeType
          location
          productID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDocument">>
    >;
  }

  OnCreateSupplierListener(
    filter?: ModelSubscriptionSupplierFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSupplier">>
  > {
    const statement = `subscription OnCreateSupplier($filter: ModelSubscriptionSupplierFilterInput) {
        onCreateSupplier(filter: $filter) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSupplier">>
    >;
  }

  OnUpdateSupplierListener(
    filter?: ModelSubscriptionSupplierFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSupplier">>
  > {
    const statement = `subscription OnUpdateSupplier($filter: ModelSubscriptionSupplierFilterInput) {
        onUpdateSupplier(filter: $filter) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSupplier">>
    >;
  }

  OnDeleteSupplierListener(
    filter?: ModelSubscriptionSupplierFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSupplier">>
  > {
    const statement = `subscription OnDeleteSupplier($filter: ModelSubscriptionSupplierFilterInput) {
        onDeleteSupplier(filter: $filter) {
          __typename
          id
          name
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSupplier">>
    >;
  }

  OnCreateProductTagsListener(
    filter?: ModelSubscriptionProductTagsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProductTags">>
  > {
    const statement = `subscription OnCreateProductTags($filter: ModelSubscriptionProductTagsFilterInput) {
        onCreateProductTags(filter: $filter) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProductTags">>
    >;
  }

  OnUpdateProductTagsListener(
    filter?: ModelSubscriptionProductTagsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProductTags">>
  > {
    const statement = `subscription OnUpdateProductTags($filter: ModelSubscriptionProductTagsFilterInput) {
        onUpdateProductTags(filter: $filter) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProductTags">>
    >;
  }

  OnDeleteProductTagsListener(
    filter?: ModelSubscriptionProductTagsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProductTags">>
  > {
    const statement = `subscription OnDeleteProductTags($filter: ModelSubscriptionProductTagsFilterInput) {
        onDeleteProductTags(filter: $filter) {
          __typename
          id
          productID
          tagID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            __typename
            id
            name
            area
            product {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProductTags">>
    >;
  }

  OnCreateProductOffersListener(
    filter?: ModelSubscriptionProductOffersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProductOffers">>
  > {
    const statement = `subscription OnCreateProductOffers($filter: ModelSubscriptionProductOffersFilterInput) {
        onCreateProductOffers(filter: $filter) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateProductOffers">
      >
    >;
  }

  OnUpdateProductOffersListener(
    filter?: ModelSubscriptionProductOffersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProductOffers">>
  > {
    const statement = `subscription OnUpdateProductOffers($filter: ModelSubscriptionProductOffersFilterInput) {
        onUpdateProductOffers(filter: $filter) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateProductOffers">
      >
    >;
  }

  OnDeleteProductOffersListener(
    filter?: ModelSubscriptionProductOffersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProductOffers">>
  > {
    const statement = `subscription OnDeleteProductOffers($filter: ModelSubscriptionProductOffersFilterInput) {
        onDeleteProductOffers(filter: $filter) {
          __typename
          id
          productID
          offerID
          product {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            websiteID
            website {
              __typename
              id
              name
              url
              email
              urlRegister
              websiteStatus
              dateLive
              frontEndConfig
              blogConfig
              themeConfig
              customerInputConfig
              workFlow
              siteData
              reportConfig
              clientID
              client {
                __typename
                id
                name
                contact
                phone
                email
                clientStatus
                websites {
                  __typename
                  items {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                users {
                  __typename
                  items {
                    __typename
                    id
                    email
                    permissionStatus
                    title
                    userName
                    firstName
                    lastName
                    userStatus
                    clientID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customers {
                __typename
                items {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailCampaigns {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  emailCampaignStatus
                  emailCampaignType
                  emailCampaignSendType
                  emailCampaignSendDate
                  emailCampaignSendTime
                  emailCampaignSendFrom
                  emailCampaignTemplate
                  emailCampaignSendAttachments
                  emailCampaignSendNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            offerID
            offer {
              __typename
              id
              websiteID
              dateValidFrom
              dateValidTo
              offerType
              offerStatus
              discountPercentage
              discountAmount
              products {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            supplierID
            supplier {
              __typename
              id
              name
              products {
                __typename
                items {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            productGroups {
              __typename
              items {
                __typename
                id
                products
                tags
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              __typename
              items {
                __typename
                id
                rating
                reviewComment
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                customerID
                customer {
                  __typename
                  id
                  email
                  userName
                  companyName
                  firstName
                  lastName
                  phone
                  phoneOther
                  accountNumber
                  customerStatus
                  customerType
                  madeToMeasureDiscount
                  standardItemsDiscount
                  deliveryDiscount
                  orderDiscount
                  customerTermsID
                  customerTerms {
                    __typename
                    id
                    terms
                    days
                    waitForPayment
                    createdAt
                    updatedAt
                  }
                  emailSubscribed
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  addresses {
                    __typename
                    nextToken
                  }
                  orders {
                    __typename
                    nextToken
                  }
                  customerGroups {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            attributes {
              __typename
              items {
                __typename
                id
                name
                description
                type
                inputType
                validationTypes
                priceType
                associatedAttribute
                secondaryAssociatedAttribute
                basePrice
                notes
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                tag {
                  __typename
                  id
                  name
                  area
                  product {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            offers {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            products {
              __typename
              items {
                __typename
                id
                productID
                offerID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  website {
                    __typename
                    id
                    name
                    url
                    email
                    urlRegister
                    websiteStatus
                    dateLive
                    frontEndConfig
                    blogConfig
                    themeConfig
                    customerInputConfig
                    workFlow
                    siteData
                    reportConfig
                    clientID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  supplierID
                  supplier {
                    __typename
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  productGroups {
                    __typename
                    nextToken
                  }
                  reviews {
                    __typename
                    nextToken
                  }
                  attributes {
                    __typename
                    nextToken
                  }
                  documents {
                    __typename
                    nextToken
                  }
                  tags {
                    __typename
                    nextToken
                  }
                  offers {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                offer {
                  __typename
                  id
                  websiteID
                  dateValidFrom
                  dateValidTo
                  offerType
                  offerStatus
                  discountPercentage
                  discountAmount
                  products {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteProductOffers">
      >
    >;
  }

  OnCreateWebsiteListener(
    filter?: ModelSubscriptionWebsiteFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWebsite">>
  > {
    const statement = `subscription OnCreateWebsite($filter: ModelSubscriptionWebsiteFilterInput) {
        onCreateWebsite(filter: $filter) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWebsite">>
    >;
  }

  OnUpdateWebsiteListener(
    filter?: ModelSubscriptionWebsiteFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWebsite">>
  > {
    const statement = `subscription OnUpdateWebsite($filter: ModelSubscriptionWebsiteFilterInput) {
        onUpdateWebsite(filter: $filter) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWebsite">>
    >;
  }

  OnDeleteWebsiteListener(
    filter?: ModelSubscriptionWebsiteFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWebsite">>
  > {
    const statement = `subscription OnDeleteWebsite($filter: ModelSubscriptionWebsiteFilterInput) {
        onDeleteWebsite(filter: $filter) {
          __typename
          id
          name
          url
          email
          urlRegister
          websiteStatus
          dateLive
          frontEndConfig
          blogConfig
          themeConfig
          customerInputConfig
          workFlow
          siteData
          reportConfig
          clientID
          client {
            __typename
            id
            name
            contact
            phone
            email
            clientStatus
            websites {
              __typename
              items {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            users {
              __typename
              items {
                __typename
                id
                email
                permissionStatus
                title
                userName
                firstName
                lastName
                userStatus
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          products {
            __typename
            items {
              __typename
              id
              sku
              name
              title
              shortDescription
              description
              delivery
              taxCode
              tax
              price
              size
              colour
              weight
              productServiceName
              productNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              offerID
              offer {
                __typename
                id
                websiteID
                dateValidFrom
                dateValidTo
                offerType
                offerStatus
                discountPercentage
                discountAmount
                products {
                  __typename
                  items {
                    __typename
                    id
                    productID
                    offerID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              supplierID
              supplier {
                __typename
                id
                name
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              productGroups {
                __typename
                items {
                  __typename
                  id
                  products
                  tags
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tagID
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                __typename
                items {
                  __typename
                  id
                  rating
                  reviewComment
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              attributes {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  type
                  inputType
                  validationTypes
                  priceType
                  associatedAttribute
                  secondaryAssociatedAttribute
                  basePrice
                  notes
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              documents {
                __typename
                items {
                  __typename
                  id
                  title
                  description
                  documentType
                  mimeType
                  location
                  productID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              tags {
                __typename
                items {
                  __typename
                  id
                  productID
                  tagID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  tag {
                    __typename
                    id
                    name
                    area
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              offers {
                __typename
                items {
                  __typename
                  id
                  productID
                  offerID
                  product {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          customers {
            __typename
            items {
              __typename
              id
              email
              userName
              companyName
              firstName
              lastName
              phone
              phoneOther
              accountNumber
              customerStatus
              customerType
              madeToMeasureDiscount
              standardItemsDiscount
              deliveryDiscount
              orderDiscount
              customerTermsID
              customerTerms {
                __typename
                id
                terms
                days
                waitForPayment
                createdAt
                updatedAt
              }
              emailSubscribed
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              addresses {
                __typename
                items {
                  __typename
                  id
                  addressType
                  addr1
                  addr2
                  addr3
                  county
                  postCode
                  Country
                  driverInstructions
                  notes
                  group
                  enabled
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              orders {
                __typename
                items {
                  __typename
                  id
                  orderNo
                  customerReference
                  itemsTotal
                  offerDiscount
                  price
                  discount
                  delivery
                  tax
                  orderTotal
                  orderNotes
                  dateOrdered
                  datePaid
                  dateDelivered
                  orderStatus
                  group
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  addressID
                  address {
                    __typename
                    id
                    addressType
                    addr1
                    addr2
                    addr3
                    county
                    postCode
                    Country
                    driverInstructions
                    notes
                    group
                    enabled
                    customerID
                    createdAt
                    updatedAt
                  }
                  offerID
                  offer {
                    __typename
                    id
                    websiteID
                    dateValidFrom
                    dateValidTo
                    offerType
                    offerStatus
                    discountPercentage
                    discountAmount
                    createdAt
                    updatedAt
                  }
                  orderItems {
                    __typename
                    nextToken
                  }
                  invoiceNumber
                  payments {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              customerGroups {
                __typename
                items {
                  __typename
                  id
                  name
                  description
                  customerID
                  customer {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          emailCampaigns {
            __typename
            items {
              __typename
              id
              name
              description
              emailCampaignStatus
              emailCampaignType
              emailCampaignSendType
              emailCampaignSendDate
              emailCampaignSendTime
              emailCampaignSendFrom
              emailCampaignTemplate
              emailCampaignSendAttachments
              emailCampaignSendNotes
              websiteID
              website {
                __typename
                id
                name
                url
                email
                urlRegister
                websiteStatus
                dateLive
                frontEndConfig
                blogConfig
                themeConfig
                customerInputConfig
                workFlow
                siteData
                reportConfig
                clientID
                client {
                  __typename
                  id
                  name
                  contact
                  phone
                  email
                  clientStatus
                  websites {
                    __typename
                    nextToken
                  }
                  users {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                products {
                  __typename
                  items {
                    __typename
                    id
                    sku
                    name
                    title
                    shortDescription
                    description
                    delivery
                    taxCode
                    tax
                    price
                    size
                    colour
                    weight
                    productServiceName
                    productNotes
                    websiteID
                    offerID
                    supplierID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                customers {
                  __typename
                  items {
                    __typename
                    id
                    email
                    userName
                    companyName
                    firstName
                    lastName
                    phone
                    phoneOther
                    accountNumber
                    customerStatus
                    customerType
                    madeToMeasureDiscount
                    standardItemsDiscount
                    deliveryDiscount
                    orderDiscount
                    customerTermsID
                    emailSubscribed
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                emailCampaigns {
                  __typename
                  items {
                    __typename
                    id
                    name
                    description
                    emailCampaignStatus
                    emailCampaignType
                    emailCampaignSendType
                    emailCampaignSendDate
                    emailCampaignSendTime
                    emailCampaignSendFrom
                    emailCampaignTemplate
                    emailCampaignSendAttachments
                    emailCampaignSendNotes
                    websiteID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWebsite">>
    >;
  }
}
